/* Avatar.css */

/* Base class for circular avatars */
.avatar-image {
  border-radius: 50%;
  object-fit: cover;
  object-position: center;  
  overflow: hidden;
}

/* Sizes for avatars */
.avatar-tiny {
  width: 25px;
  height: 25px;
}

.avatar-small {
  width: 40px;
  height: 40px;
}

.avatar-medium {
  width: 60px;
  height: 60px;
}

.avatar-large {
  width: 80px;
  height: 80px;
}

.avatar-XL {
  width: 100px;
  height: 100px;
}

.avatar-XXL {
  width: 120px;
  height: 120px;
}

/* Flexbox layout for left and right sections */
.avatar-left, .avatar-right {
  padding: 16px;
}

.avatar-left {
  display: flex;
  flex-direction: column;
  align-items: center;
  border-right: 1px solid #ddd;
  width: 40%; /* Adjust width as needed */
}

.avatar-right {
  flex: 1;
  padding-left: 20px;
}

.avatar-grid {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  justify-content: center;
  margin-top: 20px;
}

.avatar-grid img {
  cursor: pointer;
  transition: transform 0.2s ease-in-out;
}

.avatar-grid img:hover {
  transform: scale(1.1);
  border: 2px solid #007bff;
}

/* Buttons and links */
.blue-link-underlined {
  color: #007bff;
  text-decoration: underline;
  cursor: pointer;
  border: none;
  background: none;
  font-size: 16px;
  padding: 0;
}

.avatar-header button {
  margin: 0 4px;
  padding: 8px 16px;
  border: 1px solid #007bff;
  background-color: #fff;
  color: #007bff;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
}

.avatar-header button:hover {
  background-color: #007bff;
  color: #fff;
}

/* Divider */
.divider-primary {
  width: 100%;
  height: 2px;
  background-color: #ddd;
  margin: 16px 0;
}
