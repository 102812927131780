/* Acccounts.css
**
** Strictly used for our accounting sections
*/

/* Override the min-height to reduce the gap */
.user_account .container {
  min-height: 30vh !important; /* Adjust this value as needed */
}

/* Ensure no extra margins and padding on the ProfileHeader */
.user_account_main {
  margin: 0 !important;
  padding: 0 !important;
  width: 100%;
  height: auto !important; /* Ensure height is set to auto */
}

/* Ensure no extra margins and padding on the section */
.accounts-section {
  padding: 0 !important;
  margin: 0 !important;
}

/* Ensure no extra margins and padding on the container */
.accounts-section .container {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

/* Accounts.tsx - Start */
.accounting-tabs {
  display: flex;
  justify-content: center;
  margin-top: 20px;
  margin-bottom: 20px;
  gap: 20px;
}

.accounting-tabs .tab {
  flex: 1;
  display: flex;
  justify-content: center;
}

.accounting-tabs a {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 250px;
  padding: 10px 20px;
  border: 2px solid #3A36DB;
  border-radius: 8px;
  background: ivory;
  text-align: center;
  text-decoration: none;
  color: #3A36DB;
  font-size: 1.25rem;
  font-weight: bold;
  transition: background-color 0.3s, color 0.3s;
  white-space: nowrap;
  height: 50px;
}

.accounting-tabs a:hover {
  background-color: #7371bc;
  color: white !important;
}

.accounting-tabs a.active {
  background: linear-gradient(270deg, #0b0a32 0%, #4d3acd 100%) !important;
  color: white !important;
}
/* Accounts.tsx - End */

/* Deposit.tsx - Start */
.deposit-container {
  padding: 20px;
  background-color: #f8f9fa;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.deposit-container h2 {
  margin-bottom: 20px;
  color: #333;
}

.deposit-selection {
  margin-bottom: 20px;
}

.deposit-selection label {
  margin-right: 10px;
}

.deposit-selection select {
  padding: 8px;
  border-radius: 4px;
  border: 1px solid #ccc;
}

.deposit-form {
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.deposit-form h3 {
  margin-bottom: 20px;
  color: #333;
}
/* Deposit.tsx - End */

/* CryptoDeposit.tsx - Start */

.request-button {
  border-radius: 30px;
  min-width: 250px; /* Minimum width of 250px */
  max-width: 100%; /* Allow it to expand to the width of its container */
  padding: 10px 20px; /* Add padding to ensure it looks good with varying text lengths */
  text-align: center; /* Center the text */
  margin-top: 12px; /* Add horizontal margin to space the buttons apart */
}

.crypto-deposit {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.deposit-info {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  padding: 20px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  margin-top: 20px;
}

.address-section {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.currency-name {
  font-size: 1.5em;
  font-weight: bold;
  margin-bottom: 10px;
}

.address {
  font-family: monospace;
  word-break: break-all;
  background-color: #f8f9fa;
  padding: 10px;
  border-radius: 4px;
}

.copy-address {
  cursor: pointer;
  display: flex;
  align-items: center;
  margin-top: 10px;
}

.copy-address img {
  width: 20px;
  height: 20px;
  margin-left: 5px;
}

/* CryptoDeposit.tsx - End */

/* Withdraw.tsx - Start */
.withdraw-container {
  padding: 20px;
  background-color: #f8f9fa;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.withdraw-container h2 {
  margin-bottom: 20px;
  color: #333;
}

.withdraw-selection {
  margin-bottom: 20px;
}

.withdraw-selection label {
  margin-right: 10px;
}

.withdraw-selection select {
  padding: 8px;
  border-radius: 4px;
  border: 1px solid #ccc;
}

.withdraw-form {
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.withdraw-form h3 {
  margin-bottom: 20px;
  color: #333;
}
/* Withdraw.tsx - End */

/* Transaction.tsx - Start */
.pending-input {
  margin-top: 0px;
  border-color: #000;  
  background-color: #fff
}

.pending-input:checked {
  background-color: rgba(255, 240, 0, 1);
}

.pending-input:focus {
  outline: none;
  box-shadow: none;
}

/* Table Header Styling */
.transaction-container .deposit-container .withdraw-container {
  border: 2px solid var(--mainBlue);
  border-radius: 8px;
  background: transparent;
  margin: 12px 5px; /* Fix margin settings */
  overflow: hidden;
  width: 100%; /* Ensure the container takes up the full width */
}

.transaction-table {
  top: -2px;
  border-collapse: collapse; /* Ensure borders collapse into a single line */
  width: 100%;
}

.transaction-table tr {
  height: 35px
}

.transaction-table th {
  background: linear-gradient(to top, #0b0a32 0%, #7568c9 100%) !important;
  /* Avatars.css */
  
  .avatar-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px;
  }
  
  .avatar-header h3 {
    margin: 0;
  }
  
  .avatar-header .labeled-radio {
    margin-left: 8px;
  }
  
  .avatar-header .btn-group {
    display: flex;
    gap: 8px;
  }
  
  .avatar-modal-content {
    display: flex;
    justify-content: space-between;
    padding: 16px;
  }
  
  .avatar-left {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 16px;
    border-right: 1px solid #ddd;
    width: 40%; /* Adjust width as needed */
  }
  
  /* Base class for circular avatars */
  .avatar-image {
    border-radius: 50%;
    object-fit: cover;
    object-position: center;  
    overflow: hidden;
  }
  
  .avatar-image:hover {
    border: 2px solid black;
  }
  
  .avatar-current, .avatar-selected {
    border-radius: 50%;
    object-fit: cover;
    object-position: center;
    overflow: hidden;
  }
  
  .avatar-current {
    width: 50px; /* Size of the current avatar image */
    height: 50px;
    margin-bottom: 10px;
  }
  
  .avatar-selected {
    width: 100px; /* Size of the selected avatar image */
    height: 100px;
    margin-bottom: 10px;
  }
  
  /* Sizes for avatars */
  .avatar-tiny {
    width: 25px;
    height: 25px;
  }
  
  .avatar-small {
    width: 40px;
    height: 40px;
  }
  
  .avatar-medium {
    width: 60px;
    height: 60px;
  
  }
  .avatar-large {
    width: 110px;
    height: 110px;
  }
  
  
  .avatar-xl {
    width: 110px;
    height: 110px;
  }
  
  /* Flexbox layout for left and right sections */
  .avatar-left, .avatar-right {
    padding: 16px;
  }
  
  .avatar-right {
    flex: 1;
    padding-left: 20px;
  }
  
  .avatar-grid {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    justify-content: center;
    margin-top: 20px;
  }
  
  .avatar-grid img {
    cursor: pointer;
    transition: transform 0.2s ease-in-out;
  }
  
  .avatar-grid img:hover {
    transform: scale(1.1);
    border: 2px solid #007bff;
  }
  
  /* Buttons and links */
  .blue-link-underlined {
    color: #007bff;
    text-decoration: underline;
    cursor: pointer;
    border: none;
    background: none;
    font-size: 16px;
    padding: 0;
  }
  
  .avatar-header button {
    margin: 0 4px;
    padding: 8px 16px;
    border: 1px solid #007bff;
    background-color: #fff;
    color: #007bff;
    cursor: pointer;
    transition: background-color 0.3s, color 0.3s;
  }
  
  .avatar-header button:hover {
    background-color: #007bff;
    color: #fff;
  }
  
  /* Divider */
  .divider-primary {
    width: 100%;
    height: 2px;
    background-color: #ddd;
    margin: 16px 0;
  }
    
  color: #ffffff;
  font-size: 1.15rem;
  text-transform: none; /* Override uppercase transformation */
}

.transaction-table th,
.transaction-table td {
  border: 1px solid #ddd; /* Set a consistent border style */
  padding: 8px; /* Ensure padding is consistent */
  vertical-align: middle; /* Ensure vertical alignment is centered */
  text-transform: none; /* Override uppercase transformation */
}

.transaction-table th:nth-child(1),
.transaction-table td:nth-child(1) { 
  width: 15%; 
  text-align: center;
}

.transaction-table th:nth-child(2),
.transaction-table td:nth-child(2) { 
  width: 10%;  
  text-align: center;
}

.transaction-table th:nth-child(3),
.transaction-table td:nth-child(3) {
  width: 15%; 
  text-align: center;
}

.transaction-table th:nth-child(4),
.transaction-table td:nth-child(4) {
  width: 30%; 
  text-align: left;  
}

.transaction-table th:nth-child(5),
.transaction-table td:nth-child(5) { 
  width: 18%; 
  text-align: right;
}

.transaction-table th:nth-child(6),
.transaction-table td:nth-child(6) { 
  width: 12%; 
  text-align: center;
}

/* Table Row Styling */
.transaction-table td {
  padding: 10px;
  border-bottom: 1px solid #ccc;
  text-align: center;
}

.transaction-table .currency-icon {
  margin-right: 5px;
  width: 25px !important;  
}

/* Hover Effect for Rows */
.transaction-table tr:hover {
  background: transparent;
}

.dot-status-wrapper {
  display: flex;
  align-items: center;
  justify-content: flex-end; /* Align to the right */
}

.dot-status {
  text-align: center;
  display: flex;
  align-items: center;
}

.dot-status .status {
  width: 15px;
  height: 15px;
  display: inline-block;
  border-radius: 100px;
  margin-right: 8px; /* Adjust spacing as needed */
}

.dot-status[data-status="ERROR"] .status {
  background: rgba(255, 0, 0, 1);
}

.dot-status[data-status="COMPLETED"] .status {
  background: rgba(76, 255, 47, 1);
}

.dot-status[data-status="PENDING"] .status {
  background: rgba(255, 240, 0, 1);
}

.dot-status[data-status="CONFIRMING"] .status,
.dot-status[data-status="PROCESSING"] .status {
  background: rgba(77, 58, 205, 1);
}

.status-label {
  font-weight: 400;
  font-size: 14px;
  color: rgba(24, 26, 35, 1);
  line-height: 16.41px;
}

/* Transaction.tsx - End */