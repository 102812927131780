/* Selector.css 
** 
** This CSS is used in selectorCurrency and seletorCryptoAddress
*/

.selector-container {
  border-radius: 10px;
  overflow: hidden;
  background: #fff;
  border: 2px solid rgb(200, 200, 200, 1);
  font-size: 20px;
  font-weight: 700;
}

.selector-expander {
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 16px 36px 16px 36px;
}

.selector-expander .icon {
  margin-left: auto;
}

.selector {
  border: 1px solid rgba(212, 212, 212, 1) !important;
  margin: 0px 36px 8px 36px;
  border-radius: 10px;
  cursor: default;
}

.selector.disabled {
  cursor: not-allowed;
  pointer-events: none;
}

.selector.selected {
  background-color: rgb(215, 215, 180);
  cursor: default; /* Optionally change cursor to default to indicate it's selected */
}

.selector:hover:not(.selected) {
  border: 1px solid black !important;
  background-color: rgb(212, 212, 212, 1) !important;
  cursor: pointer;
}

.selector-item {
  display: flex;
  align-items: center;
  border-radius: 10px;
  margin-right: 8px;
  font-weight: 500;
  font-size: 20px;
  margin-left: auto;
  padding: 5px;
  line-height: 24px;
  background: transparent;
}

.selector-item .icon {
  width: 36px;
  margin-right: 5px;
}

.selector-item-currency {
  text-transform: uppercase;
}

.selector-item-right {
  margin-left: auto;
  white-space: nowrap;
}

.selector-item-address {
  line-height: 12px;
  padding-left: 20px;
}

.selector-address-text {
  font-weight: 700;
  font-size: 18px;
  line-height: 20px;
  justify-content: space-between;
  width: 100%; /* Ensures the span elements span the full width */
}
