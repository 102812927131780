.aggregate-value {
  color: #3a4585;
  font-size: 40px;
  white-space: nowrap; /* Prevent text from wrapping */
}

.confirm-button {
  background-color: #7667d7; /* Purple background for purchase */
  color: white; /* White text */
}

.confirm-button:hover:not(:disabled) {
  background-color: #26237e; /* White background on hover */
}


/* Container Styling */
.presale-list-container {
  border: 1px solid #ccc;
  border-radius: 8px;
  background: transparent;
  margin-top: 2px;
  overflow: hidden;  
}

/* Table Styling */
.presale-list-table {
  width: 100%;
  border-collapse: collapse;
}

/* Table Header Styling */
.presale-list-table th {
  background-color: #333;
  color: #fff;
  padding: 10px;
  border-bottom: 2px solid #ccc;
  text-align: center;
}

.presale-list-table th:nth-child(1) { width: 30%; }
.presale-list-table th:nth-child(2) { width: 30%; }
.presale-list-table th:nth-child(3) { width: 10%; }
.presale-list-table th:nth-child(4) { width: 30%; }

/* Left Align Player Name */
.presale-list-table th.text-start,
.presale-list-table td.text-start {
  text-align: left;
}

/* Table Row Styling */
.presale-list-table td {
  padding: 10px;
  border-bottom: 1px solid #ccc;
  text-align: center;
}

/* Specific Styling for Player Name Column */
.presale-list-table .player-info {
  text-align: left;
  display: flex;
  align-items: center;
}

.presale-list-table .participant-avatar {
  margin-right: 5px;
  width: 25px !important;  
}

/* Hover Effect for Rows */
.presale-list-table tr:hover {
  background: transparent;
}

/* Pagination Styling */
.pagination {
  margin-top: 2px;
  display: flex;
  justify-content: center;
  padding: 10px 0;
  list-style: none; /* Ensure no default list styling */
}

.page-item {
  margin: 0 2px; /* Adjusted margin */
  padding: 0; /* Ensure no extra padding */
}

.page-link {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px 10px; /* Adjust padding for better alignment */
  border: 1px solid #ddd; /* Border for the page link */
  border-radius: 4px; /* Rounded corners */
  background-color: #fff; /* Background color */
  color: #000; /* Text color */
  text-decoration: none; /* Remove underline */
  cursor: pointer; /* Pointer cursor on hover */
}

.page-link img {
  margin: 0 2px; /* Adjust margin for images within buttons */
}

.page-link:hover,
.page-link.active {
  background-color: #007bff; /* Background color on hover and active state */
  color: #fff; /* Text color on hover and active state */
}

.page-link.circle {
  border-radius: 50%;
  width: 12px; /* Adjust size as needed */
  height: 12px; /* Adjust size as needed */
  padding: 0;
  background-color: transparent; /* Remove background for non-active dots */
  border: none; /* Remove border */
  color: transparent; /* Hide the default dot */
  position: relative; /* For positioning the ::before element */
  cursor: pointer; /* Indicate clickable area */
}

.page-link.circle::before {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 8px; /* Adjust size as needed */
  height: 8px; /* Adjust size as needed */
  background-color: #007bff; /* Non-active dot color */
  border-radius: 50%;
  transition: background-color 0.3s;
}

.page-link.circle.active::before {
  background-color: #007bff;
}

.page-link.circle:hover::before {
  background-color: #0056b3;
}

.pagination-header {
  text-align: center;
  margin-bottom: 10px;
}


/* Presale Event Styling */
.presale .presale-event {
  color: #fff;
  text-align: center;
}

.presale .presale-event .hl {
  border: 1px solid rgba(81, 79, 147, 1);
  margin: 32px 0px;
}

.presale .presale-event h1 {
  font-weight: 700;
  font-size: 48px;
  line-height: 56.25px;
  margin: 32px 0px;
}

.presale .presale-event h2 {
  font-weight: 500;
  font-size: 32px;
  line-height: 46px;
  margin: 32px 0px;
}

.presale .qtable {
  margin: 0px;
}

.presale .qtable th,
.presale .qtable td {
  text-transform: initial;
  text-align: center;
}

.presale .qtable thead tr th {
  background: linear-gradient(180deg, #4d3acd 0%, #292734 100%);
}

.presale .table > :not(caption) > * > * {
  background: transparent;
  color: #fff;
  font-size: 18px;
  font-weight: 400;
  line-height: 21.09px;
}

/* Responsive styles */
@media (max-width: 768px) {
  .presale .presale-event h1 {
    font-size: 32px;
    line-height: 40.25px;
    margin: 16px 0px;
  }

  .presale-event .text-start {
    text-align: justify !important;
    margin: 0px 10px !important;
  }

/* Provably Fair Document- Start */

.footnote {
  font-size: 0.60rem;
  vertical-align: super;
  text-decoration: none;
}

.verbatim {
  font-family: monospace;
}

/* Provably Fair Document- End */
}
