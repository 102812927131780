/* Profile.tsx - Start */

.profile-tabs {
  display: flex;
  justify-content: center;
  margin-top: 20px;
  margin-bottom: 20px;
  gap: 10px;
}

.profile-tabs .tab {
  flex: 1;
  display: flex;
  justify-content: center;
}

.profile-tabs a {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 250px;
  padding: 10px 20px;
  border: 2px solid #3A36DB;
  border-radius: 8px;
  background: ivory;
  text-align: center;
  text-decoration: none;
  color: #3A36DB;
  font-size: 1.25rem;
  font-weight: bold;
  transition: background-color 0.3s, color 0.3s;
  white-space: nowrap;
  height: 50px;
}

.profile-tabs a:hover {
  background-color: #7371bc;
  color: white !important;
}

.profile-tabs a.active {
  background: linear-gradient(270deg, #0b0a32 0%, #4d3acd 100%) !important;
  color: white !important;
}
/* Accounts.tsx - End */
