@media (max-width: 1200px) {
  .user-account-tabs ul li,
  .accounting-tabs ul li {
    width: 50% !important;
  }
}

@media (max-width: 760px) {
  .user-account-tabs ul li,
  .accounting-tabs ul li {
    width: 100% !important;
  }
}

/* games css */
@media (max-width: 550px) {
  .trimorra .jackpot-payout-border > div > h1 {
    font-size: 28px;
  }

  .trimorra .presale-counter li {
    font-size: 40px;
    line-height: 20px;
  }

  .trimorra .container > p {
    font-weight: 400;
    font-size: 14px;
  }

  .trimorra .new-trimorra > h3 {
    font-weight: 500;
    font-size: 36px;
  }

  .trimorra .new-trimorra-tier > h3 {
    font-weight: 600;
    font-size: 36px;
    text-align: center;
  }

  .trimorra .new-trimorra-tier .tier-button {
    padding: 10px 20px;
  }

  .trimorra .new-trimorra-buy > h3 {
    font-weight: 600;
    font-size: 36px;
    text-align: center;
  }

  .trimorra .tier-jion > button {
    padding: 8px 15px;
    font-weight: 400;
    font-size: 18px;
  }

  .trimorra .new-trimorra > div > button {
    font-weight: 500;
    font-size: 18px;
  }

  .windfall .lottery_windfall .windfall_image > img {
    width: 70%;
  }

  .windfall .lottery_Remaining {
    font-weight: 600;
    font-size: 24px;
    color: #f1efff;
  }

  .windfall .lottery-offers > div > h5 {
    color: #f1efff;
    font-weight: 500;
    font-size: 28px;
    line-height: 46px;
  }

  .windfall .lottery-offers > div > p {
    font-size: 15px;
  }

  .windfall .pools .pool-h1 {
    font-size: 36px;
    font-weight: 600;
  }

  .windfall .pools .pools-box {
    padding: 24px 32px;
  }
  .windfall .pools-container .pool-members {
    padding: 5px;
  }
  .windfall .pools-column {
    flex-direction: column;
  }
  .windfall .ticket-qty input {
    width: 30%;
  }

  .windfall .create-lottery-pool {
    background: var(--mainBlue);
    border-radius: 30px;
    padding: 0px;
  }

  .windfall .pools-container-column {
    flex-direction: column;
  }

  .windfall .pool-members-box .pool-members-profile > div > label {
    font-weight: 400;
    font-size: 26px;
  }
  .windfall .pool-members-box .pool-members-profile > div > p {
    font-weight: 400;
    font-size: 16px;
  }
}

@media (max-width: 800px) {
  .new-trimorra-tier > div {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .trimorra > div:nth-last-child(1) {
    display: none;
  }

  .trimorra .jackpot-number {
    border: none;
  }

  .windfall .lottery_windfall .windfall_image > img {
    width: 75%;
  }

  .windfall .lottery_windfall {
    background-size: cover;
    object-fit: cover;
    background-repeat: no-repeat;
    width: 100%;
    min-height: 425px;
    position: relative;
  }

  .windfall .presale-counter li {
    font-weight: 600;
    font-size: 40px;
    line-height: 20px;
  }
  .windfall .lottery_Remaining {
    font-weight: 600;
    font-size: 30px;
    color: #f1efff;
  }
  .windfall .create-lottery-section .nexusbox-pool > button {
    width: 80%;
  }
  .windfall .pool-members-box > div {
    padding: 0px;
  }
  .windfall .pool-members-box .pool-members-profile {
    padding: 24px;
  }
  .windfall .purchase-ticket .confirm-purchase > div > div > button {
    width: 65%;
  }
  .windfall .confirm-purchase .purchase-game {
    flex-direction: column;
  }
}

@media (max-width: 1000px) {
  .winner-box {
    display: block;
  }

  .winner-box > div:nth-child(2) {
    margin-left: 0px;
  }

  .winner-tag-button {
    flex-direction: column;
  }

  .modal-profile > div > p {
    font-size: 18px;
    font-weight: 600;
    padding-top: 10px;
  }
  .windfall .lottery_windfall .windfall_image > img {
    width: 100%;
  }

  .windfall .purchase-ticket-column {
    flex-direction: column;
  }
  .windfall .min-purchase-card {
    margin-top: 15px;
    padding: 18px;
  }

  .windfall .pools-container-column > .border {
    display: none !important;
  }
  .windfall .pools-container .pools-container-box {
    padding: 16px 32px;
  }
}

@media (min-width: 1000px) {
  .windfall .min-purchase-card {
    align-items: center;
  }

  .windfall .min-purchase-card > .border {
    height: 50px;
  }
}

/**  RESPONSIVE */

@media only screen and (max-width: 768px) {
  /* For mobile phones: */

  .info_bar {
    display: none;
  }

  .navbar {
    padding: 0px 20px !important;
  }

  .navbar .navbar-toggler,
  .navbar .navbar-toggler:focus,
  .navbar .navbar-toggler:active {
    box-shadow: none;
    border: 0px;
    color: #fff;
  }

  .navbar .navbar-brand img,
  .navbar-close-section-mobile img {
    width: 120px !important;
  }

  .navbar-collapse {
    position: absolute;
    top: 0;
    left: 0;
    background: #4d3acd;
    width: 100%;
    box-shadow: 2px 1px 16px #5a19cb;
  }

  .navbar .navbar-nav {
    max-height: none;
    padding: 20px;
    width: 100%;
    align-items: end;
  }

  .navbar-close-section-mobile {
    display: block;
    display: flex;
    justify-content: space-between;
    padding: 20px 24px;
    border-bottom: 2px solid #f1efff;
  }

  .navbar-close-section-mobile .navbar-toggler-icon {
    background-image: url("../images/navbar-close-circle.svg");
  }

  .navbar .nav-link {
    font-weight: 700;
    font-size: 22px !important;
    padding: 20px 10px;
    text-align: right;
  }

  .navbar .nav-item {
    display: block;
    width: 100%;
  }

  .navbar .nav-link::before {
    height: 0px;
  }

  .navbar .nav-item.show .dropdown-toggle::after {
    display: inline-block;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: "";
    border-bottom: 0.3em solid;
    border-top: 0px;
    border-right: 0.3em solid transparent;
    border-left: 0.3em solid transparent;
  }

  .navbar .dropdown-menu::after {
    content: "";
    display: none;
  }

  .navbar .nav-item .dropdown-menu {
    display: none !important;
  }

  .navbar .nav-item.show .dropdown-menu {
    display: block !important;
    box-shadow: none;
  }

  .navbar .nav-item.dropdown .dropdown-menu a {
    text-align: end;
    padding: 0.8rem 2rem;
  }

  .navbar .login-button {
    display: none !important;
  }

  .navbar-info-section-mobile {
    display: flex;
    justify-content: center;
    padding: 20px 20px 0px 20px;
  }

  .navbar-info-section-mobile .h_text p {
    color: #fff;
  }

  .navbar-info-section-mobile .h_support {
    border-color: #fff;
  }

  .navbar-info-section-mobile .h_language .dropdown-menu {
    top: 120% !important;
    left: -100% !important;
  }

  .navbar-buttons-section-mobile {
    display: flex !important;
    justify-content: center;
    margin: 40px 20px 20px 20px;
    padding: 0px 0px 20px 00px;
    border-bottom: 2px solid #f1efff;
  }

  .navbar-buttons-section-mobile .login-button {
    display: block !important;
    width: 50%;
  }

  .login_main > div,
  .login_main_register > div {
    margin: 20px;
  }

  .main-loader svg {
    width: 100% !important;
  }

  .card.p-50 {
    padding: 10px !important;
  }

  .navbar-profile-section-mobile {
    display: block;
    padding: 20px;
  }

  .navbar-profile-section-mobile .profile-top-card .avtr {
    margin-right: 10px;
  }

  .navbar-profile-section-mobile .profile-top-card .dropdown {
    display: flex;
    justify-content: center;
  }

  .navbar-profile-section-mobile .profile-top-card .qpoints {
    margin-left: 10px;
  }

  .navbar-profile-section-mobile .profile-top-card .dropdown-menu.show {
    top: 120% !important;
  }

  .h-divider {
    border-bottom: 2px solid #f1efff;
    padding: 15px;
  }

  .card-profile .card-body {
    padding: 20px !important;
  }

  .user_account_main .user_account .card-profile img {
    width: 70px;
  }

  .card-profile a {
    font-size: 14px;
  }

  .card-profile {
    min-width: 100%;
  }

  .card-profile .profile-info {
    margin-left: 10px;
    min-width: auto;
  }

  .user-account-tabs,
  .accounting-tabs {
    margin: 0px;
  }

  .user-account-tabs ul li,
  .accounting-tabs ul li {
    width: 50% !important;
  }

  .user-account-tabs ul li a,
  .accounting-tabs ul li a {
    font-size: 16px;
    padding: 10px;
  }

  .user-account-tabs-card,
  .accounting-tabs-card {
    margin: 10px;
  }

  .user-account-tabs-card .card-body .card-header-bar {
    flex-direction: column;
  }

  .user-account-tabs-card .card-body,
  .accounting-tabs-card .card-body {
    padding: 20px !important;
  }

  .user-account-tabs-card .card-body .card-header-bar-desc {
    margin-top: 15px;
  }

  .user-account-tabs-card .card-body .card-header-bar.balance_contant span {
    flex-direction: column;
  }

  .user-account-tabs-card .card-content {
    margin-top: 20px;
    padding: 16px;
  }

  .user-account-tabs-card .card-content .card-white-normal {
    margin-bottom: 20px;
  }

  .otp-field input {
    width: 35px !important;
  }

  .qab.mini > div {
    width: 100%;
    text-align: right;
  }

  .equal-width-three {
    display: block !important;
  }

  .equal-width-three a {
    width: 100%;
    margin-bottom: 10px;
  }

  .error-page {
    padding: 100px 30px;
  }

  .img-responsive-mobile {
    width: 100%;
  }

  /* home page */

  .home-section {
    background: url("../images/home-bg-mobile.svg");
    min-height: 900px;
    padding-bottom: 20px;
    height: auto;
  }

  .home-section .intro-section {
    justify-content: center;
  }

  .home-section .intro-section .home-q-logo {
    width: 80%;
  }

  .home-bgf {
    width: 80%;
  }

  .home-section .intro-section h1 {
    font-size: 48px;
    margin-top: 10px;
  }

  .home-section-sticky {
    display: none;
  }

  .home-section-sticky-mobile {
    display: block;
  }

  .home-section-sticky-mobile .counter_box_right {
    position: relative;
    bottom: 0;
    margin-top: 10px;
  }

  .right_ticket_box {
    padding: 20px 10px;
  }

  .home-section-sticky-mobile .home-winners-section {
    background: var(--lightPurpal);
    box-shadow: 4.02972px 8.05944px 20.1486px rgba(0, 0, 0, 0.1);
    border-radius: 20.1486px;
    margin-top: 20px;
    text-align: center;
  }

  .home-section-sticky-mobile .home-winners-section h3 {
    font-size: 16px;
  }

  .home-section-sticky-mobile .home-winners-section img {
    width: 80%;
    margin-top: 10px;
  }

  .spacerT {
    padding-top: 30px;
  }

  .main_title {
    font-size: 30px;
  }

  .game-goxes {
    margin-top: 10px;
    width: 100%;
    display: block;
  }

  .game-goxes .game-item {
    width: 100%;
    max-width: 300px;
    margin-bottom: 50px !important;
    margin: 0 auto;
  }

  .newsletter h1 {
    font-size: 38px;
  }

  .newsletter .container {
    padding-left: 10px;
    padding-right: 10px;
  }

  main {
    margin-bottom: 50px;
  }

  .presale .page-link {
    padding: 8px 12px;
  }

  .presale .qtable tbody td:nth-child(1) {
    min-width: 200px;
  }

  .presale .qtable tbody td:nth-child(2) {
    min-width: 200px;
  }

  .presale .qtable tbody td:nth-child(3) {
    min-width: 100px;
  }

  .presale .qtable tbody td:nth-child(4) {
    min-width: 300px;
  }

  .presale-counter li {
    padding: 0px;
    font-size: 26px;
    min-width: 30px;
    line-height: 44px;
  }

  .tr-left .inp-wapper label {
    font-size: 16px;
    width: 40%;
  }

  .tr-left .inp-wapper .qstepview {
    width: 60%;
  }

  .presale .ticket-remaining-section .remaining h3 {
    font-size: 23.3px;
    margin: 10px 0px 0px 0px;
  }

  .ticket-info-section br {
    display: none;
  }

  .ticket-info-section label {
    margin-left: 10px !important;
    display: inline-block !important;
  }

  .ticket-info-section .white-link-underlined {
    font-size: 16px;
  }

  .top-prices br:nth-child(1),
  .top-prices br:nth-child(2) {
    display: none;
  }

  .mini-table th {
    line-height: 18.09px;
    padding: 6px 7px 6px 7px;
    font-size: 12px;
  }

  .mini-table td {
    font-weight: 400;
    line-height: 18.09px;
    font-size: 12px;
  }

  .wwl-aligned td {
    padding: 4px 2px !important;
  }

  .presale .white-card h1 {
    font-size: 26px;
  }

  .presale .windfall-logo img {
    width: 80%;
    padding: 20px 0px 10px 0px;
  }

  .presale .card-blue {
    padding: 8px 16px;
  }

  .presale .card-blue h2 {
    font-size: 28px;
  }

  .presale .ticket-remaining-section {
    flex-direction: column;
  }

  .tr-left,
  .tr-right {
    width: 100%;
  }

  .tr-vline {
    border-bottom: 2px solid rgba(241, 239, 255, 1);
    margin: 4% 0%;
  }

  .ticket-info-section {
    flex-direction: column;
    padding: 10px;
  }

  .ticket-info-section div {
    width: 100%;
  }

  .ticket-info-section .divider {
    border-right: 0px;
    border-bottom: 2px solid rgba(241, 239, 255, 1);
    padding: 8px;
    margin-bottom: 8px;
  }

  .ticket-info-section .last-item {
    width: 100%;
  }

  .presale-modal .modal-lg {
    max-width: 100% !important;
  }

  .presale-modal .modal-body {
    padding: 32px;
  }

  .sign_title h2 {
    font-size: 30px;
    margin-top: 10px;
  }

  .sign_title p {
    font-size: 18px;
  }

  .pages .small_heading {
    font-size: 24px;
  }

  .page-bg-white .container .heading {
    font-size: 32px;
    line-height: 40px;
  }

  .pages .pages-header .container {
    min-height: 16vh;
  }

  .pages .pages-header .container h1 {
    font-size: 36px;
  }

  .flex-reverse {
    flex-direction: column-reverse;
  }

  .flex-reverse .col-md-4 {
    margin-bottom: 40px;
  }

  .page-bg-white {
    padding: 32px 0px;
  }

  .form-control {
    padding: 12px;
    font-size: 17px;
    line-height: 18px;
  }

  .pwd-field .pwd-hand {
    padding: 15px;
  }

  .mwr-200 {
    min-width: 200px;
  }

  .accounting-tabs-card .card-content {
    padding: 10px;
  }

  .crypto-tabs {
    margin: 0px;
  }

  .crypto-tabs ul li a,
  .transactions-tabs ul li a {
    margin: 5px;
  }

  .crypto-tabs ul li a img,
  .transactions-tabs ul li a img {
    margin: 0 auto;
    display: block;
  }

  .crypto-tabs ul li a label,
  .transactions-tabs ul li a label {
    font-size: 14px;
    text-transform: capitalize;
  }

  .login_button {
    padding: 8px 18px;
  }

  .qselect-item-unused {
    display: none !important;
  }

  .faqs-section .text {
    font-size: 20px;
    line-height: 24.47px;
  }

  .navbar .notification-bell {
    display: block;
  }
}
