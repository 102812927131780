/* Footer styles */
.footer {
  padding: 20px 0;
  background: #f0f0f0;
}

.footer .container {
  max-width: 1200px;
  margin: 0 auto;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px;
}

.footer-grid {
  display: contents;
}

.footer-column {
  text-align: left;
}

.footer-column h3 {
  font-size: 2.0rem; /* Adjusted font size */
  font-weight: bold;
  margin-bottom: 10px;
}

.footer .links {
  list-style: none;
  padding: 0;
  margin: 0;
}

.footer .links li {
  margin-bottom: 10px;
  display: block; /* Ensure list items stack vertically */
}

.footer .links a {
  font-size: 1.2rem;
  text-decoration: none;
  color: #333;
  display: flex;
  align-items: center;
  background: transparent;
  padding: 10px 15px;
  border-radius: 20px;
  width: 100%; /* Ensure links take full width */
}

.footer .links a img {
  margin-right: 8px;
  width: 30px;  /* Set width for all SVGs */
  height: 30px; /* Set height for all SVGs */
}

/* Additional CSS for Footer middle section */
.footer-sub {
  padding: 10px 0px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.eplus {
  display: flex;
  align-items: center;
  background: rgba(77, 58, 205, 1);
  padding: 8px 16px;
  border-radius: 10px;
  color: white;
  flex: 0 0 65%;
  margin-left: 100px; /* Maintain the left margin */
  margin-right: 20px;
}

.eplus img {
  margin-right: 10px; /* Further reduce margin to move text closer */
  width: 75px; /* Ensure the correct width */
}

.eplus p {
  margin: 0;
  padding-left: 0; /* Ensure no left padding */
  font-weight: 600;
  font-size: 1.35rem;
}

.footer-sub ul {
  display: flex;
  align-items: center;
  padding-left: 0;
  margin: 0;
  list-style: none;
}

.footer-sub li {
  margin: 0 10px;
}

.footer-sub a img {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  transition: transform 0.3s ease-in-out;
}

.footer-sub a img:hover {
  transform: scale(1.1); /* Slightly enlarge the icon on hover */
}

.footer-sub a:not(:last-child) img {
  margin-right: -10px; /* Overlap the icons slightly */
}

/* Additional CSS for Footer bottom section */
.copyright_bar {
  background-color: rgba(77, 58, 205, 1); /* Adjust as needed */
  color: white;
  padding: 10px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.copyright_bar .container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.list-inline {
  padding-left: 0;
  list-style: none;
  margin: 0;
  display: flex;
  align-items: center;
}

.list-inline-item {
  display: inline-block;
  padding: 0 20px; /* Increased padding for more spacing */
  color: white; /* Ensure the font color is white */
}

.list-inline-item a {
  color: white; /* Ensure the link color is white */
  text-decoration: none; /* Optional: remove underline from links */
}

.list-inline-item::before,
.list-inline-item::after {
  content: none; /* Ensure no separators are added */
}

.no_border a {
  border-right: none;
}
