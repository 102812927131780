/* Define CSS variables for color themes */
:root {
  --pagination-bg-color: #fff; /* Default light theme background color */
  --pagination-text-color: #000; /* Default light theme text color */
  --pagination-hover-bg-color: #a286c6; /* Default light theme hover background color */
  --pagination-hover-text-color: #000; /* Default light theme hover text color */
  --pagination-active-bg-color: transparent; /* Default light theme active background color */  
  --pagination-active-text-color: #000; /* Default light theme active text color */
  --pagination-active-border-color: #290b4f; /* Default light theme active border color */
}

body.dark-theme {
  --pagination-bg-color: #000; /* Dark theme background color */
  --pagination-text-color: #fff; /* Dark theme text color */
  --pagination-hover-bg-color: #333; /* Dark theme hover background color */
  --pagination-hover-text-color: #fff; /* Dark theme hover text color */
  --pagination-active-bg-color: #007bff; /* Dark theme active background color */
  --pagination-active-text-color: #fff; /* Dark theme active text color */
}

/* Pagination Container */
.pagination {
  display: flex;
  justify-content: center;
  padding: 0;
  margin: 0;
  list-style-type: none;
  white-space: nowrap; /* Prevent wrapping */
}

/* Line Item */
.line-item {
  margin: 0 5px;
  padding: 0;
}

/* Rounded Selection */
.rounded-selection {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px 12px;
  background-color: var(--pagination-bg-color); /* Use variable for background color */
  color: var(--pagination-text-color); /* Use variable for text color */
  text-decoration: none;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
  border: none; /* Remove border */
}

.rounded-selection:hover {
  background-color: var(--pagination-hover-bg-color); /* Use variable for hover background color */
  color: var(--pagination-hover-text-color); /* Use variable for hover text color */
  border-radius: 50%; /* Rounded corners on hover */
}

.rounded-selection:focus {
  outline: none; /* Remove default focus outline */
  background-color: var(--pagination-hover-bg-color); /* Use variable for focus background color */
}

.rounded-selection.active {
  border: 4px solid var(--pagination-active-border-color); /* Add border for active item */
  border-radius: 50%; /* Rounded corners for active item */
  background-color: transparent; /* Make background transparent */
  color: var(--pagination-text-color); /* Keep text color the same */
}

/* Page Number Pagination Container */
.page-number-pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
}

.page-number-pagination button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  font-size: 16px;
  margin: 0 5px;
  transition: all 0.3s ease;
}

.page-number-pagination button:hover {
  color: var(--pagination-hover-text-color);
}

.page-number-pagination .active {
  background-color: var(--pagination-active-bg-color);
  color: var(--pagination-active-text-color);
  border-radius: 50%;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.page-number-pagination .dots {
  cursor: default;
}

.page-number-pagination .arrow {
  font-size: 20px;
}
