.animator {
  background: #fff;
  border: 2.23px solid rgba(77, 58, 205, 1);
  border-radius: 15px;
  padding: 4px;
  margin: 0px;

  display: flex; /* Use flexbox layout */
  justify-content: center; /* Center items horizontally */
  align-items: center; /* Center items vertically */
  white-space: nowrap; /* Prevent wrapping */
  overflow: hidden; /* Prevent overflow */
}

.animator li{
  display: inline-block;
  background: linear-gradient(0deg, #F1EFFF, #F1EFFF), linear-gradient(0deg, rgba(255, 255, 255, 0.3), rgba(255, 255, 255, 0.3));
  padding: 10px 0px;
  border-radius: 2.23px;
  border-left: 1.11px solid rgba(255, 255, 255, 0.3);
  box-shadow: 4.458333492279053px 4.458333492279053px 4.458333492279053px -2.2291667461395264px rgba(255, 255, 255, 0.25) inset;
  box-shadow: -4.458333492279053px -4.458333492279053px 11.145833969116211px 0px rgba(0, 0, 0, 0.09) inset;
  font-weight: 500;
  font-size: 50px;
  text-align: center;
  line-height: 50px;
  color: rgba(77, 58, 205, 1);
  margin: 0 0.5px;
  min-width: 55px;
  white-space: nowrap; /* Prevent wrapping */  
}

.animator li:first-child{
  border-radius: 8.92px 2.23px 2.23px 8.92px;
}

.animator li:last-child{
  border-radius: 2.23px 8.92px 8.92px 2.23px;
}

.animator li:non-digit {
  min-width: 31px; /* Adjusted width for non-digit characters */
}