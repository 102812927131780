.pwd-field-container {
  display: flex;
  align-items: center;
  margin-bottom: 15px; /* Adjust as needed */
  position: relative;
}

.pwd-input {
  flex: 1; /* Allows the input to take all available space */
  padding-right: 10px; /* Adjust padding to avoid text touching the edge */
  height: 40px; /* Adjust height as needed */
  font-size: 1.2rem; /* Adjust font size as needed */
  font-weight: 500;
  border: 1px solid #ccc; /* Standard border for the input field */
  border-radius: 5px; /* Rounded corners for the input field */
}

.pwd-hand {
  margin-left: 10px; /* Space between input and icon */
  cursor: pointer;
  display: flex;
  align-items: center; /* Center the icon vertically */
}

.pwd-hand img {
  width: 20px; /* Adjust icon size as needed */
  height: 20px; /* Ensure the icon is square */
}
