/* Styles for the dropdown toggle */
.lang-dropdown-toggle {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.lang-dropdown-toggle .toggle-icon img {
  width: 14px;
  height: 8px;
  margin-right: 8px;
  image-rendering: crisp-edges; /* Ensures better image rendering */
}

.lang-dropdown-toggle span {
  font-size: 20px;
  color: #000;
  margin-left: 8px; /* Adjust spacing between text and icon */
}

.lang-dropdown-toggle img {
  width: 30px;
  height: 20px;
  margin-left: 8px; /* Adjust spacing between flag and text */
}

/* Styles for the dropdown menu */
.lang-dropdown-menu {
  background-color: white;
  border-radius: 4px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.lang-dropdown-item {
  display: flex;
  align-items: center;
  padding: 4px 16px; /* Adjust padding to reduce height */
  cursor: pointer;
  transition: background-color 0.3s ease;
  height: 35px; /* Set a fixed height for consistency */
}

.lang-dropdown-item:hover {
  background-color: #f0f0f0;
}

.lang-dropdown-item img {
  margin-right: 2px;
}

.lang-dropdown-item label {
  margin: 0;
  font-size: 1.0rem;
  color: #333;
}
