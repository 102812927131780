@import '../../assets/css/avatar.css';

/* Avatar Selection and Category Buttons */

.avatar-selector-wrapper {
  display: grid !important;
  grid-template-columns: 20% 1fr !important; /* Set first column to 20% width and second column to take remaining space */
  align-items: start;
  column-gap: 20px; /* Gap between the columns */
}

/* Avatar Selection and Save */

.avatar-save-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 120px; /* Minimum width to accommodate the avatar and button */
}

.avatar-image {
  margin-bottom: 10px;
}

.default-submit-button .save-button {
  height: 30px;
}

/* Category Buttons */
.category-buttons-container {
  display: flex;
  align-items: center;
  gap: 10px;
}

/* Avatar List in Card Content */
.card-content {
  display: flex;
  flex-wrap: wrap;
  gap: 10px; /* Space between avatars */
}

/* Avatar Image Styles */
.avatar-circle {
  transition: transform 0.2s ease-in-out, border 0.2s ease-in-out;
}

.avatar-circle:hover {
  border: 1px solid #000; /* Border on hover */
}

.avatar-circle.selected {
  transform: scale(1.1); /* Scale selected avatar */
  border: 2px solid #000; /* Border for selected avatar */
}