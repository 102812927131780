@import url(https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap);
@media (max-width: 1200px) {
  .user-account-tabs ul li,
  .accounting-tabs ul li {
    width: 50% !important;
  }
}

@media (max-width: 760px) {
  .user-account-tabs ul li,
  .accounting-tabs ul li {
    width: 100% !important;
  }
}

/* games css */
@media (max-width: 550px) {
  .trimorra .jackpot-payout-border > div > h1 {
    font-size: 28px;
  }

  .trimorra .presale-counter li {
    font-size: 40px;
    line-height: 20px;
  }

  .trimorra .container > p {
    font-weight: 400;
    font-size: 14px;
  }

  .trimorra .new-trimorra > h3 {
    font-weight: 500;
    font-size: 36px;
  }

  .trimorra .new-trimorra-tier > h3 {
    font-weight: 600;
    font-size: 36px;
    text-align: center;
  }

  .trimorra .new-trimorra-tier .tier-button {
    padding: 10px 20px;
  }

  .trimorra .new-trimorra-buy > h3 {
    font-weight: 600;
    font-size: 36px;
    text-align: center;
  }

  .trimorra .tier-jion > button {
    padding: 8px 15px;
    font-weight: 400;
    font-size: 18px;
  }

  .trimorra .new-trimorra > div > button {
    font-weight: 500;
    font-size: 18px;
  }

  .windfall .lottery_windfall .windfall_image > img {
    width: 70%;
  }

  .windfall .lottery_Remaining {
    font-weight: 600;
    font-size: 24px;
    color: #f1efff;
  }

  .windfall .lottery-offers > div > h5 {
    color: #f1efff;
    font-weight: 500;
    font-size: 28px;
    line-height: 46px;
  }

  .windfall .lottery-offers > div > p {
    font-size: 15px;
  }

  .windfall .pools .pool-h1 {
    font-size: 36px;
    font-weight: 600;
  }

  .windfall .pools .pools-box {
    padding: 24px 32px;
  }
  .windfall .pools-container .pool-members {
    padding: 5px;
  }
  .windfall .pools-column {
    flex-direction: column;
  }
  .windfall .ticket-qty input {
    width: 30%;
  }

  .windfall .create-lottery-pool {
    background: var(--mainBlue);
    border-radius: 30px;
    padding: 0px;
  }

  .windfall .pools-container-column {
    flex-direction: column;
  }

  .windfall .pool-members-box .pool-members-profile > div > label {
    font-weight: 400;
    font-size: 26px;
  }
  .windfall .pool-members-box .pool-members-profile > div > p {
    font-weight: 400;
    font-size: 16px;
  }
}

@media (max-width: 800px) {
  .new-trimorra-tier > div {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .trimorra > div:nth-last-child(1) {
    display: none;
  }

  .trimorra .jackpot-number {
    border: none;
  }

  .windfall .lottery_windfall .windfall_image > img {
    width: 75%;
  }

  .windfall .lottery_windfall {
    background-size: cover;
    object-fit: cover;
    background-repeat: no-repeat;
    width: 100%;
    min-height: 425px;
    position: relative;
  }

  .windfall .presale-counter li {
    font-weight: 600;
    font-size: 40px;
    line-height: 20px;
  }
  .windfall .lottery_Remaining {
    font-weight: 600;
    font-size: 30px;
    color: #f1efff;
  }
  .windfall .create-lottery-section .nexusbox-pool > button {
    width: 80%;
  }
  .windfall .pool-members-box > div {
    padding: 0px;
  }
  .windfall .pool-members-box .pool-members-profile {
    padding: 24px;
  }
  .windfall .purchase-ticket .confirm-purchase > div > div > button {
    width: 65%;
  }
  .windfall .confirm-purchase .purchase-game {
    flex-direction: column;
  }
}

@media (max-width: 1000px) {
  .winner-box {
    display: block;
  }

  .winner-box > div:nth-child(2) {
    margin-left: 0px;
  }

  .winner-tag-button {
    flex-direction: column;
  }

  .modal-profile > div > p {
    font-size: 18px;
    font-weight: 600;
    padding-top: 10px;
  }
  .windfall .lottery_windfall .windfall_image > img {
    width: 100%;
  }

  .windfall .purchase-ticket-column {
    flex-direction: column;
  }
  .windfall .min-purchase-card {
    margin-top: 15px;
    padding: 18px;
  }

  .windfall .pools-container-column > .border {
    display: none !important;
  }
  .windfall .pools-container .pools-container-box {
    padding: 16px 32px;
  }
}

@media (min-width: 1000px) {
  .windfall .min-purchase-card {
    align-items: center;
  }

  .windfall .min-purchase-card > .border {
    height: 50px;
  }
}

/**  RESPONSIVE */

@media only screen and (max-width: 768px) {
  /* For mobile phones: */

  .info_bar {
    display: none;
  }

  .navbar {
    padding: 0px 20px !important;
  }

  .navbar .navbar-toggler,
  .navbar .navbar-toggler:focus,
  .navbar .navbar-toggler:active {
    box-shadow: none;
    border: 0px;
    color: #fff;
  }

  .navbar .navbar-brand img,
  .navbar-close-section-mobile img {
    width: 120px !important;
  }

  .navbar-collapse {
    position: absolute;
    top: 0;
    left: 0;
    background: #4d3acd;
    width: 100%;
    box-shadow: 2px 1px 16px #5a19cb;
  }

  .navbar .navbar-nav {
    max-height: none;
    padding: 20px;
    width: 100%;
    align-items: end;
  }

  .navbar-close-section-mobile {
    display: block;
    display: flex;
    justify-content: space-between;
    padding: 20px 24px;
    border-bottom: 2px solid #f1efff;
  }

  .navbar-close-section-mobile .navbar-toggler-icon {
    background-image: url(/static/media/navbar-close-circle.f1d88923.svg);
  }

  .navbar .nav-link {
    font-weight: 700;
    font-size: 22px !important;
    padding: 20px 10px;
    text-align: right;
  }

  .navbar .nav-item {
    display: block;
    width: 100%;
  }

  .navbar .nav-link::before {
    height: 0px;
  }

  .navbar .nav-item.show .dropdown-toggle::after {
    display: inline-block;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: "";
    border-bottom: 0.3em solid;
    border-top: 0px;
    border-right: 0.3em solid transparent;
    border-left: 0.3em solid transparent;
  }

  .navbar .dropdown-menu::after {
    content: "";
    display: none;
  }

  .navbar .nav-item .dropdown-menu {
    display: none !important;
  }

  .navbar .nav-item.show .dropdown-menu {
    display: block !important;
    box-shadow: none;
  }

  .navbar .nav-item.dropdown .dropdown-menu a {
    text-align: end;
    padding: 0.8rem 2rem;
  }

  .navbar .login-button {
    display: none !important;
  }

  .navbar-info-section-mobile {
    display: flex;
    justify-content: center;
    padding: 20px 20px 0px 20px;
  }

  .navbar-info-section-mobile .h_text p {
    color: #fff;
  }

  .navbar-info-section-mobile .h_support {
    border-color: #fff;
  }

  .navbar-info-section-mobile .h_language .dropdown-menu {
    top: 120% !important;
    left: -100% !important;
  }

  .navbar-buttons-section-mobile {
    display: flex !important;
    justify-content: center;
    margin: 40px 20px 20px 20px;
    padding: 0px 0px 20px 00px;
    border-bottom: 2px solid #f1efff;
  }

  .navbar-buttons-section-mobile .login-button {
    display: block !important;
    width: 50%;
  }

  .login_main > div,
  .login_main_register > div {
    margin: 20px;
  }

  .main-loader svg {
    width: 100% !important;
  }

  .card.p-50 {
    padding: 10px !important;
  }

  .navbar-profile-section-mobile {
    display: block;
    padding: 20px;
  }

  .navbar-profile-section-mobile .profile-top-card .avtr {
    margin-right: 10px;
  }

  .navbar-profile-section-mobile .profile-top-card .dropdown {
    display: flex;
    justify-content: center;
  }

  .navbar-profile-section-mobile .profile-top-card .qpoints {
    margin-left: 10px;
  }

  .navbar-profile-section-mobile .profile-top-card .dropdown-menu.show {
    top: 120% !important;
  }

  .h-divider {
    border-bottom: 2px solid #f1efff;
    padding: 15px;
  }

  .card-profile .card-body {
    padding: 20px !important;
  }

  .user_account_main .user_account .card-profile img {
    width: 70px;
  }

  .card-profile a {
    font-size: 14px;
  }

  .card-profile {
    min-width: 100%;
  }

  .card-profile .profile-info {
    margin-left: 10px;
    min-width: auto;
  }

  .user-account-tabs,
  .accounting-tabs {
    margin: 0px;
  }

  .user-account-tabs ul li,
  .accounting-tabs ul li {
    width: 50% !important;
  }

  .user-account-tabs ul li a,
  .accounting-tabs ul li a {
    font-size: 16px;
    padding: 10px;
  }

  .user-account-tabs-card,
  .accounting-tabs-card {
    margin: 10px;
  }

  .user-account-tabs-card .card-body .card-header-bar {
    flex-direction: column;
  }

  .user-account-tabs-card .card-body,
  .accounting-tabs-card .card-body {
    padding: 20px !important;
  }

  .user-account-tabs-card .card-body .card-header-bar-desc {
    margin-top: 15px;
  }

  .user-account-tabs-card .card-body .card-header-bar.balance_contant span {
    flex-direction: column;
  }

  .user-account-tabs-card .card-content {
    margin-top: 20px;
    padding: 16px;
  }

  .user-account-tabs-card .card-content .card-white-normal {
    margin-bottom: 20px;
  }

  .otp-field input {
    width: 35px !important;
  }

  .qab.mini > div {
    width: 100%;
    text-align: right;
  }

  .equal-width-three {
    display: block !important;
  }

  .equal-width-three a {
    width: 100%;
    margin-bottom: 10px;
  }

  .error-page {
    padding: 100px 30px;
  }

  .img-responsive-mobile {
    width: 100%;
  }

  /* home page */

  .home-section {
    background: url(/static/media/home-bg-mobile.ecdd91c5.svg);
    min-height: 900px;
    padding-bottom: 20px;
    height: auto;
  }

  .home-section .intro-section {
    justify-content: center;
  }

  .home-section .intro-section .home-q-logo {
    width: 80%;
  }

  .home-bgf {
    width: 80%;
  }

  .home-section .intro-section h1 {
    font-size: 48px;
    margin-top: 10px;
  }

  .home-section-sticky {
    display: none;
  }

  .home-section-sticky-mobile {
    display: block;
  }

  .home-section-sticky-mobile .counter_box_right {
    position: relative;
    bottom: 0;
    margin-top: 10px;
  }

  .right_ticket_box {
    padding: 20px 10px;
  }

  .home-section-sticky-mobile .home-winners-section {
    background: var(--lightPurpal);
    box-shadow: 4.02972px 8.05944px 20.1486px rgba(0, 0, 0, 0.1);
    border-radius: 20.1486px;
    margin-top: 20px;
    text-align: center;
  }

  .home-section-sticky-mobile .home-winners-section h3 {
    font-size: 16px;
  }

  .home-section-sticky-mobile .home-winners-section img {
    width: 80%;
    margin-top: 10px;
  }

  .spacerT {
    padding-top: 30px;
  }

  .main_title {
    font-size: 30px;
  }

  .game-goxes {
    margin-top: 10px;
    width: 100%;
    display: block;
  }

  .game-goxes .game-item {
    width: 100%;
    max-width: 300px;
    margin-bottom: 50px !important;
    margin: 0 auto;
  }

  .newsletter h1 {
    font-size: 38px;
  }

  .newsletter .container {
    padding-left: 10px;
    padding-right: 10px;
  }

  main {
    margin-bottom: 50px;
  }

  .presale .page-link {
    padding: 8px 12px;
  }

  .presale .qtable tbody td:nth-child(1) {
    min-width: 200px;
  }

  .presale .qtable tbody td:nth-child(2) {
    min-width: 200px;
  }

  .presale .qtable tbody td:nth-child(3) {
    min-width: 100px;
  }

  .presale .qtable tbody td:nth-child(4) {
    min-width: 300px;
  }

  .presale-counter li {
    padding: 0px;
    font-size: 26px;
    min-width: 30px;
    line-height: 44px;
  }

  .tr-left .inp-wapper label {
    font-size: 16px;
    width: 40%;
  }

  .tr-left .inp-wapper .qstepview {
    width: 60%;
  }

  .presale .ticket-remaining-section .remaining h3 {
    font-size: 23.3px;
    margin: 10px 0px 0px 0px;
  }

  .ticket-info-section br {
    display: none;
  }

  .ticket-info-section label {
    margin-left: 10px !important;
    display: inline-block !important;
  }

  .ticket-info-section .white-link-underlined {
    font-size: 16px;
  }

  .top-prices br:nth-child(1),
  .top-prices br:nth-child(2) {
    display: none;
  }

  .mini-table th {
    line-height: 18.09px;
    padding: 6px 7px 6px 7px;
    font-size: 12px;
  }

  .mini-table td {
    font-weight: 400;
    line-height: 18.09px;
    font-size: 12px;
  }

  .wwl-aligned td {
    padding: 4px 2px !important;
  }

  .presale .white-card h1 {
    font-size: 26px;
  }

  .presale .windfall-logo img {
    width: 80%;
    padding: 20px 0px 10px 0px;
  }

  .presale .card-blue {
    padding: 8px 16px;
  }

  .presale .card-blue h2 {
    font-size: 28px;
  }

  .presale .ticket-remaining-section {
    flex-direction: column;
  }

  .tr-left,
  .tr-right {
    width: 100%;
  }

  .tr-vline {
    border-bottom: 2px solid rgba(241, 239, 255, 1);
    margin: 4% 0%;
  }

  .ticket-info-section {
    flex-direction: column;
    padding: 10px;
  }

  .ticket-info-section div {
    width: 100%;
  }

  .ticket-info-section .divider {
    border-right: 0px;
    border-bottom: 2px solid rgba(241, 239, 255, 1);
    padding: 8px;
    margin-bottom: 8px;
  }

  .ticket-info-section .last-item {
    width: 100%;
  }

  .presale-modal .modal-lg {
    max-width: 100% !important;
  }

  .presale-modal .modal-body {
    padding: 32px;
  }

  .sign_title h2 {
    font-size: 30px;
    margin-top: 10px;
  }

  .sign_title p {
    font-size: 18px;
  }

  .pages .small_heading {
    font-size: 24px;
  }

  .page-bg-white .container .heading {
    font-size: 32px;
    line-height: 40px;
  }

  .pages .pages-header .container {
    min-height: 16vh;
  }

  .pages .pages-header .container h1 {
    font-size: 36px;
  }

  .flex-reverse {
    flex-direction: column-reverse;
  }

  .flex-reverse .col-md-4 {
    margin-bottom: 40px;
  }

  .page-bg-white {
    padding: 32px 0px;
  }

  .form-control {
    padding: 12px;
    font-size: 17px;
    line-height: 18px;
  }

  .pwd-field .pwd-hand {
    padding: 15px;
  }

  .mwr-200 {
    min-width: 200px;
  }

  .accounting-tabs-card .card-content {
    padding: 10px;
  }

  .crypto-tabs {
    margin: 0px;
  }

  .crypto-tabs ul li a,
  .transactions-tabs ul li a {
    margin: 5px;
  }

  .crypto-tabs ul li a img,
  .transactions-tabs ul li a img {
    margin: 0 auto;
    display: block;
  }

  .crypto-tabs ul li a label,
  .transactions-tabs ul li a label {
    font-size: 14px;
    text-transform: capitalize;
  }

  .login_button {
    padding: 8px 18px;
  }

  .qselect-item-unused {
    display: none !important;
  }

  .faqs-section .text {
    font-size: 20px;
    line-height: 24.47px;
  }

  .navbar .notification-bell {
    display: block;
  }
}

/*font style link*/

/* ===================== BEGIN:: DEFAULT CSS================== */

.preloader {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.spinner {
  font-size: 24px;
  font-weight: bold;
}

@font-face {
  font-family: "QuintoSA";
  src:
    local("QuintoSA"),
    url(/static/media/QuintoSA.a0b4ac7a.woff) format("woff");
}

.q-symbol {
  font-family: QuintoSA;
}
.q-symbol::before {
  content: "\e1e0"; /* Unicode value of the Quinto Q */
}

:root {
  --mainBlue: #4d3acd;
  --lightPurpal: #f1efff;
  --black: #181a23;
  --white: #fbfbfb;
  --gradientBlue: linear-gradient(270deg, #4d3acd 0%, #514f93 100%);
  --gray: #d5d5d5;
  --pink: #dbb7fc;
  --bodyColor: #f1efff;
}
* {
  box-sizing: border-box;
}
body {
  font-family: "Roboto", sans-serif !important;
  line-height: 24px;
  overflow-x: hidden !important;
  background-color: var(--bodyColor);
}
h1,
h2,
h3,
h4,
h5,
h6,
p,
ul {
  margin: 0px;
  padding: 0px;
}
a {
  text-decoration: none !important;
}
p {
  margin: 0px !important;
}

p {
  font-size: 18px;
}

button {
  border: none;
  outline: none;
  background-color: transparent;
}
ul {
  list-style: none;
  margin: 0px !important;
}

/* CSS Create by Steven Swanson June 5, 2024 */
/* Added Outlet CSS June 21, 2024 */
/* Default Submit button */

.copy-icon {
  height: 25px;
  width: auto; /* Maintain aspect ratio */
  cursor: pointer;
  margin-left: 10px;
}

.default-submit-button {
  background-color: #6a5bcb; /* Purple background for purchase */
  color: white; /* White text */
  border: 2px solid black;
  border-radius: 30px;
  padding: 10px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 1.25rem;
  font-weight: 600;
  margin-top: 10px;
  transition-duration: 0.1s;
  cursor: pointer;
}

.default-submit-button:hover:not(:disabled) {
  background-color: #4d3acd; /* White background on hover */
  color: black !important; /* Black text on hover */
  border: 2px solid black; /* Green border on hover */
}

.default-submit-button:disabled {
  background-color: #979797; /* Gray background for disabled state */
  cursor: not-allowed; /* Not-allowed cursor for disabled state */
}

/* Specific Classes */
.purchase-button {
  background-color: #56b359; /* Green background for purchase */
  color: black !important;
}

.purchase-button:hover:not(:disabled) {
  background: linear-gradient(270deg, #135415 0%, #56b359 100%);
  color: white !important;  
}

.refund-button {
  background-color: #d42323cb; /* Red background for refund */
  color: black !important;
}

.refund-button:hover:not(:disabled) {
  background: linear-gradient(270deg, #510808cb 0%, #d42323cb 100%);
  color: white !important; 
}

/* Default Submit button */

.default-selection-button {
  background-color: #7667d7; /* Purple background for purchase */
  color: white; /* White text */
  border: 2px solid black;
  border-radius: 12px;
  padding: 10px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 1.25rem;
  font-weight: 600;
  margin-top: 10px;
  transition-duration: 0.3s;
  cursor: pointer;
}

.default-selection-button:hover:not(:disabled) {
  background-color: #26237e; /* White background on hover */
}

.default-selection-button:disabled {
  background-color: #979797; /* Gray background for disabled state */
  cursor: not-allowed; /* Not-allowed cursor for disabled state */
}

.default-selection-button.selected {
  background: linear-gradient(270deg, #0b0a32 0%, #4d3acd 100%) !important;  
}

/* Default Tab Button - Start*/
.default-selection-tab {
  background-color: ivory;  
  border: 2px solid #3A36DB;
  border-radius: 8px;
  padding: 5px 10px;
  cursor: pointer;

  text-align: center;
  text-decoration: none;
  color: #3A36DB;
  font-size: 1.25rem;
  font-weight: bold;
  transition: background-color 0.3s, color 0.3s;
}  

.default-selection-tab:hover:not(:disabled) {
  background-color: #7371bc;
}

.default-selection-tab:disabled {
  background-color: #979797; /* Gray background for disabled state */
  cursor: not-allowed; /* Not-allowed cursor for disabled state */
}

.default-selection-tab.selected {
  background: linear-gradient(270deg, #0b0a32 0%, #4d3acd 100%) !important;
  color: white !important;
}

/* Defult Tab Button - End */

/* Outlet Styling - Start */
.outlet {
  background: linear-gradient(180deg, #f1efff, #c5c2d0),
    linear-gradient(0deg, rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0.2));
  border: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: 10px;
  box-shadow: 0px 10px 15px 0px rgba(0, 0, 0, 0.2);    
  padding: 15px 20px;
}
.outlet-inner {
  margin-top: 5px;
  background: transparent;
  padding: 5px;
  border-radius: 20px;
}

.balance-edging {
  display: inline-block;
  color: rgba(77, 58, 205, 1);
  border: 1px solid rgba(77, 58, 205, 1);
  border-radius: 40px;
  padding: 2px 8px 4px 8px;
}

.balance-edging img {
  width: 18px;
  margin: 0px;
  margin-right: 0px !important;
}

.balance-edging label {
  font-size: 22px;
  line-height: 24px;
  font-weight: 500;
  position: relative;
  top: 3px;
}
/* Outlet Styling - End */

/* Change Password Styling - Start */
.password-card {
  width: 100%; /* Ensure the content takes the full width of the parent */
  padding: 20px; /* Add some padding for better spacing */
  box-sizing: border-box; /* Ensure padding is included in the total width/height */
}

.password-card .form-control {
  width: 60%; /* Ensure input fields take the full width of the content area */
  margin-bottom: 15px; /* Add some margin at the bottom of each input field */
}

.password-card .password {
  width: 100%; /* Ensure the password element takes the full width */
}

/* Password Styling - End */

/* End of CSS Create by Steven Swanson */

/* Orignal CSS Section */

.common_button {
  background: linear-gradient(270deg, #4d3acd 0%, #514f93 100%);
  border: 2px solid var(--white);
  border-radius: 50px;
  align-items: center;
  padding: 16px 35px;
  grid-gap: 16px;
  gap: 16px;
  color: var(--lightPurpal);
  transition: 0.3s;
}
.common_button:hover,
.banner_btn_play_now:hover,
.active_game_btn:hover,
.upcoming_single_active .upcoming_game_btn:hover,
.footer_social div:hover {
  background: linear-gradient(
    180deg,
    #6819a3 2.44%,
    #5d1394 10.33%,
    #4e0b80 23.48%,
    #430571 39.26%,
    #3d0269 58.54%,
    #3c0167 90.1%
  );
}

.spacerB {
  padding-bottom: 100px;
}
.spacerT {
  padding-top: 100px;
}
.main_title {
  font-weight: 700;
  font-size: 60px;
  color: #4d3acd;
}
.subTitle {
  text-align: center;
  font-size: 24px;
  font-weight: 700;
  color: var(--black);
}

.main_blue_color {
  color: var(--mainBlue);
}

/* ===================== END:: DEFAULT CSS================== */
/* ===================== BEGIN:: header css================== */
.h_support:hover,
.h_contact:hover {
  color: var(--mainBlue);
}
.info_bar {
  background: var(--lightPurpal);
  padding: 10px 0px;
  /* position: fixed; */
  z-index: 15;
}
.h_support {
  border-right: 2px solid var(--black);
  padding-right: 20px;
  margin-right: 14px;
}
.h_contact {
  padding-left: 10px;
}
.bothside_common div img {
  margin-right: 10px;
  align-items: center !important;
}
.bothside_common {
  cursor: pointer;
}
.h_text p {
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 21px;
  color: #000;
}

.navbar-link {
  padding: 0px;
}
.navbar-dropdown.is-boxed {
  top: calc(100% + (3px));
}
.h_language:hover .navbar-dropdown {
  display: block !important;
}

.h_language .dropdown-menu img {
  width: auto;        /* Ensure the width is set to auto to maintain aspect ratio */
  height: 20px;       /* Set a fixed height for the images */
  display: inline;    /* Ensure the image is displayed inline */
  margin-right: 8px;  /* Add some space between the image and the label */
  border-radius: 0;   /* Remove any border-radius to ensure the image is not oval */
}

.navbar_end {
  right: 0px;
  background: var(--white);
  box-shadow: 2px 1px 16px var(--gray);
  border-radius: 5px;
  visibility: hidden;
  opacity: 0;
  top: 50px;
  transition: 0.3s;
  z-index: 11;
  border-radius: 0px 0px 20px 20px;
  overflow: hidden;
}

.profile-dropdown .navbar_end_active {
  top: 120% !important;
}

.navbar_end_active {
  visibility: visible;
  opacity: 1;
  top: 33px;
}

.navbar_end ul li {
  border-bottom: 2px solid rgba(241, 239, 255, 1);
  transition: 0.3s;
  min-width: 200px;
}

.navbar_end ul li a {
  color: rgba(24, 26, 35, 1);
  padding: 10px;
  display: block;
  text-align: center;
}

.navbar_end ul li:hover a {
  color: rgba(var(--bs-link-color-rgb), var(--bs-link-opacity, 1));
}

.navbar_end ul li:hover {
  background: #f3f3f3;
  cursor: pointer;
}
.click_dropdown {
  cursor: pointer;
}
.navbar_end ul li p {
  margin-right: 10px !important;
}

nav {
  height: 5rem;
  width: 100vw;
  display: flex;
  z-index: 10;
}

.logoheader_main {
  background-color: var(--mainBlue) !important;
  display: flex;
  z-index: 10;
}

/*Styling logo*/
.logo {
  padding: 1vh 1vw;
  text-align: center;
}
.logo img {
  height: 5rem;
  width: 5rem;
}

/*Styling Links*/

.navbar-brand {
  margin-right: 50px;
}

.navbar-toggler-icon {
  background-image: url(/static/media/navbar-toggler.b56bc383.svg);
  width: 2em;
  height: 2em;
}

.nav-link {
  text-decoration: none !important;
  color: var(--white) !important;
  margin: 0 !important;
  font-size: 18px !important;
}
.nav-link:hover {
  color: var(--pink) !important;
}

.nav-item {
  position: relative;
}

.nav-link::before {
  content: "";
  display: block;
  height: 3px;
  width: 0%;
  background-color: var(--pink);
  position: absolute;
  transition: all ease-in-out 250ms;
}

.nav-link.text-gray::before {
  width: 0% !important;
}

.nav-link:hover::before {
  width: 80%;
}

/*Styling Buttons*/
.login-button {
  background-color: transparent;
  border: 2px solid #f2f5f7;
  border-radius: 2em;
  padding: 0.6rem 0.8rem;
  margin-left: 2vw;
  font-size: 1rem;
  cursor: pointer;
  transition: all ease-in-out 350ms;
  color: var(--white);
  text-align: center;
}
.login-button:hover {
  color: #131418;
  background-color: #f2f5f7;
}
.join-button {
  color: #131418;
  background-color: #61dafb;
  border: 1.5px solid #61dafb;
  border-radius: 2em;
  padding: 0.6rem 0.8rem;
  font-size: 1rem;
  cursor: pointer;
}
.join-button:hover {
  color: #f2f5f7;
  background-color: transparent;
  border: 1.5px solid #f2f5f7;
  transition: all ease-in-out 350ms;
}

/*Styling Hamburger Icon*/
.hamburger div {
  width: 30px;
  height: 3px;
  background: #f2f5f7;
  margin: 5px;
  transition: all 0.3s ease;
}
.hamburger {
  display: none;
}

/*Stying for small screens*/
@media screen and (max-width: 800px) {
  nav {
    position: absolute;
    z-index: 3;
  }
  .hamburger {
    display: block;
    position: absolute;
    cursor: pointer;
    right: 5%;
    top: 50%;
    transform: translate(-5%, -50%);
    z-index: 2;
    transition: all 0.5s ease;
  }
  .nav-links {
    /*position: fixed;*/
    position: absolute;
    right: 0;
    background: var(--mainBlue);
    height: 70vh;
    margin-top: 60px !important;
    width: 100%;
    flex-direction: column;
    clip-path: circle(50px at 90% -20%);
    -webkit-clip-path: circle(50px at 90% -10%);
    transition: all 0.6s ease-out;
    pointer-events: none;
    z-index: -1;
    width: 100%;
  }

  .nav-links.open {
    clip-path: circle(1000px at 90% -10%);
    -webkit-clip-path: circle(1000px at 90% -10%);
    pointer-events: all;
    right: 0;
    position: absolute;
    width: 100%;
  }
  .nav-links li {
    opacity: 0;
  }
  .nav-links li:nth-child(1) {
    transition: all 0.5s ease 0.2s;
    /* opacity: 1; */
  }
  .nav-links li:nth-child(2) {
    transition: all 0.5s ease 0.4s;
    /* opacity: 1; */
  }
  .nav-links li:nth-child(3) {
    transition: all 0.5s ease 0.6s;
    /* opacity: 1; */
  }
  .nav-links li:nth-child(4) {
    transition: all 0.5s ease 0.7s;
    /* opacity: 1; */
  }
  .nav-links li:nth-child(5) {
    transition: all 0.5s ease 0.8s;
    /* opacity: 1; */
  }
  .nav-links li:nth-child(6) {
    transition: all 0.5s ease 0.9s;
    margin: 0;
    /* opacity: 1; */
  }
  .nav-links li:nth-child(7) {
    transition: all 0.5s ease 1s;
    margin: 0;
    /* opacity: 1; */
  }
  .nav-links li.fade {
    opacity: 1;
  }
}

/*Animating Hamburger Icon on Click*/
.toggle .line1 {
  transform: rotate(-45deg) translate(-5px, 6px);
}
.toggle .line2 {
  transition: all 0.7s ease;
  width: 0;
}
.toggle .line3 {
  transform: rotate(45deg) translate(-5px, -6px);
}

/* STICKY STYLES
–––––––––––––––––––––––––––––––––––––––––––––––––– */
.page-header.is-sticky {
  /* position: fixed; */
  box-shadow: 0 5px 16px rgba(0, 0, 0, 0.1);
  -webkit-backdrop-filter: blur(10px);
          backdrop-filter: blur(10px);
  animation: slideDown 0.35s ease-out;
  z-index: 100;
}

@keyframes slideDown {
  from {
    transform: translateY(-100%);
  }
  to {
    transform: translateY(0);
  }
}
/* ===================== END:: header css================== */
/* ===================== BEGIN:: banner================== */
.banner_image {
  background-image: url(/static/media/comming-soon.1282ccb3.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  width: 100vw;
  min-height: 900px;
  position: relative;
  background-position: center;
}

.user_account .container {
  background-image: url(/static/media/user-account-banner.7e0e9b1b.svg);
  width: 100%;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: right;
  background-position-y: -6px;
  min-height: 28vh;
}

.user_account_main {
  width: 100%;
  height: 100%;
}

.user_account_main .user_account .card-profile img {
  width: 122px;
}

.user_account div h1 {
  color: var(--mainBlue);
}
.banner_bottum {
  background-image: url(/static/media/BannerbackImg.0f6d16eb.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  height: 321px;
  width: 100vw;
}
.banner_top_text {
  color: var(--lightPurpal);
  padding-top: 60px;
}
.banner_top_text p:first-child {
  font-style: normal;
  font-weight: 500;
  font-size: 26px;
  /* line-height: 30px; */
  margin-bottom: 20px !important;
}
.banner_top_text h1 {
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  /* line-height: 112px; */
  margin-bottom: 20px !important;
}
.banner_top_text p:nth-child(3) {
  border: 2px solid var(--lightPurpal);
  border-radius: 31px;
  width: auto;
  height: 0px;
  margin-bottom: 30px !important;
}

.banner_top_text p:last-child {
  font-style: normal;
  font-weight: 300;
  font-size: 18px;
  /* line-height: 21px; */
}

.banner_left {
  /* background-color: var(--pink); */
  height: 300px;
}
.windfall_logo {
  left: 0px;
  background-color: var(--lightPurpal);
  border-radius: 0px 32px 32px 0px;
}
.windfall_logo div {
  padding: 20px 50px;
}
.windfall_logo div p {
  font-weight: 500;
  font-size: 20px;
  color: var(--black);
}
.windfall_logo div div span {
  font-weight: 700;
  font-size: 26px;
  color: var(--black);
}
.windfall_logo div div {
  background: var(--white);
  width: 242px;
  height: 62px;
  padding: 16px 32px;
  grid-gap: 8px;
  gap: 8px;
  border-radius: 50px;
}

.banner_right {
  background-color: var(--lightPurpal);
  border-radius: 20.1486px;
  position: absolute;
  right: 0%;
  bottom: 27px;
  padding: 20px;
  width: 438px;
}
.banner_counter {
  background-image: url(/static/media/Countdownnumbers.0ceb62b4.svg);
  background-repeat: no-repeat;
  height: 100px;
  padding-top: 20px;
  /* align-items: center; */
  /* display: flex; */
}
.banner_counter span {
  color: var(--mainBlue);
  font-weight: 700;
  font-size: 64.4755px;
  letter-spacing: 14px;
  line-height: 55px;
  /* margin-left: 12px; */
}
.banner_right h2 {
  font-weight: 700;
  font-size: 40px;
  color: var(--black);
}
.playnow_button > button {
  font-weight: 600;
  font-size: 32.2378px;
  color: var(--white);
}
.sale_button {
  background-image: url(/static/media/presale-button.5c5ecabb.svg);
  width: 100%;
  height: 80px;
  bottom: 331px;
  right: -66px;
  color: var(--white);
  background-repeat: no-repeat;
}

.sale_button p {
  font-weight: 700;
  font-size: 32px;
  line-height: 96px;
  margin-left: -128px !important;
}
.banner_right_main {
  bottom: 20px;
  left: -300px;
}

.main_counter_box {
  max-width: 100% !important;
}

/* ===================== BEGIN:: banner counter ================== */
.back_img_banner {
  color: #fff;
  position: relative;
}
.counter_box_right {
  position: absolute;
  bottom: 30px;
  z-index: 15;
}
.back_img_banner > div {
  padding: 40px 0;
}
.wind_fall_main {
  background: var(--lightPurpal);
  box-shadow: 2px 4px 10px rgba(0, 0, 0, 0.1);
  border-radius: 32px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  display: flex;
  justify-content: space-between;
  color: var(--black);
  padding-top: 16vh;
}
.button_part_wellfall {
  padding: 20px;
  text-align: right;
}
.windfall_btn_left {
  background: #ffffff;
  border-radius: 346px;
  font-weight: 700;
  font-size: 26px;
  line-height: 130%;
  color: var(--black);
  padding: 10px 30px;
  border: 0;
  display: inline-block;
}
.wellfall_content {
  font-weight: 500;
  font-size: 20px;
  line-height: 23px;
  line-height: 170%;
}
.img_windfall {
  width: 100%;
}

.right_ticket_box {
  padding: 30px;
  text-align: center;
  background: var(--lightPurpal);
  box-shadow: 4.02972px 8.05944px 20.1486px rgba(0, 0, 0, 0.1);
  border-radius: 20.1486px;
  width: 100%;
}
.right_ticket_box h3 {
  font-weight: 700;
  font-size: 40px;
  line-height: 47px;
  color: var(--black);
  padding: 25px 0;
  text-transform: uppercase;
}
.banner_btn_play_now {
  background: linear-gradient(270deg, #4d3acd 0%, #514f93 100%);
  border-radius: 135.931px;
  padding: 10px 50px;
  font-weight: 600;
  font-size: 32.2378px;
  line-height: 48px;
  color: var(--white);
  text-transform: uppercase;
  border: none;
  display: inline-block;
}
.banner_counter_img {
  padding: 15px 8px;
}
.ticket_pre_sale {
  text-align: center;
  position: relative;
}
.ticket_pre_sale .ticket_text {
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  /*line-height: 130%;*/
  line-height: 70px;
  color: var(--white);
}

.ticket_pre_sale div {
  position: absolute;
  z-index: -1;
  height: auto;
  width: 100%;
  bottom: -34px;
}

.banner_description {
  width: 50%;
  margin: auto !important;
}

/* ===================== END:: banner counter ================== */

/* ===================== END:: banner ================== */
.counter_number {
  position: absolute;
  top: 33px;
  left: 14px;
  color: var(--mainBlue);
}
counter_number span {
  font-weight: 700;
}
.counters {
  display: flex;
  background: rgba(255, 255, 255, 0.75);
  padding: 20px;
  border-radius: 10px;
  box-shadow: 5px 5px 20px rgba(0, 0, 0, 0.2);
}

.counter {
  display: flex;
  overflow: hidden;
  align-items: center;
  height: 3.125rem;
  font-size: 3.125rem;
  margin: 0 1.25rem;
  font-weight: 400;
}
.counter > span {
  z-index: 1;
  display: flex;
  flex-direction: column;
  height: 100%;
  transition: transform 2s ease;
  transform: translateY(0);
  line-height: 1;
}
.counter > span span {
  flex: 0 0 100%;
  height: 100%;
}
.counter:nth-child(1) > span {
  transition-delay: 0s;
}
.counter:nth-child(2) > span {
  transition-delay: 0.375s;
}
.counter:nth-child(3) > span {
  transition-delay: 0.75s;
}
.counter:nth-child(4) > span {
  transition-delay: 1.125s;
}
.counter:nth-child(5) > span {
  transition-delay: 1.5s;
}
.counter:nth-child(6) > span {
  transition-delay: 1.875s;
}
.counter:nth-child(7) > span {
  transition-delay: 2.25s;
}
.counter:nth-child(8) > span {
  transition-delay: 2.625s;
}
.counter:nth-child(9) > span {
  transition-delay: 3s;
}
.counter:nth-child(10) > span {
  transition-delay: 3.375s;
}
.counter:nth-child(11) > span {
  transition-delay: 3.75s;
}
.counter:nth-child(12) > span {
  transition-delay: 4.125s;
}
.counter:nth-child(13) > span {
  transition-delay: 4.5s;
}
.counter:nth-child(14) > span {
  transition-delay: 4.875s;
}
.counter:nth-child(15) > span {
  transition-delay: 5.25s;
}
.counter:nth-child(16) > span {
  transition-delay: 5.625s;
}
.counter:nth-child(17) > span {
  transition-delay: 6s;
}
.counter:nth-child(18) > span {
  transition-delay: 6.375s;
}
.counter:nth-child(19) > span {
  transition-delay: 6.75s;
}
.counter:nth-child(20) > span {
  transition-delay: 7.125s;
}

.counter:nth-child(1) {
  font-size: 64.4755px;
  letter-spacing: 18px;
  font-weight: 700;
  margin: 5px 0px 5px 10px;
}

/* ===================== BEGIN:: ACTIVE GAMES ================== */
.single_active {
  background: var(--white);
  border: 2.89778px solid var(--white);
  box-shadow: 1.44889px 2.89778px 7.24444px rgba(0, 0, 0, 0.1);
  border-radius: 47.0889px;
  text-align: center;
  min-height: 400px;
  margin: 15px 0 15px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.single_active .sa_cnt {
  width: 100%;
  display: block;
}

.single_active .sa_cnt .img {
  width: 80%;
}

.single_active h4 {
  color: var(--mainBlue);
  font-size: 26px;
  line-height: 135%;
  font-weight: 700;
  margin-top: 40px;
}
.active_game_btn {
  background: var(--gradientBlue);
  box-shadow:
    0px 2.89778px 2.89778px rgba(0, 0, 0, 0.25),
    inset -2.17333px -6.52px 2.89778px rgba(0, 0, 0, 0.05),
    inset 4.34667px 2.89778px 2.89778px rgba(255, 255, 255, 0.1);
  border-radius: 36.2222px;
  font-size: 11.59px;
  line-height: 17.39px;
  color: var(--white);
  border: 0;
  padding: 11.59px 25.36px 11.59px 25.36px;
  display: inline-block;
  font-weight: 600;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: -22px;
  width: 60%;
}
.active_game_btn img {
  padding-right: 11px;
}

.single_active .img_active_main {
  height: 110px;
  width: 100%;
}

.pagination_active_games {
  display: flex;
  justify-content: center;
  padding-top: 65px;
}

.pagination_active_games ul {
  display: flex;
  align-items: center;
}
.pagination_active_games ul li {
  height: 30px;
  width: 30px;
  border: 2px solid var(--black);
  border-radius: 5px;
  cursor: pointer;
  text-align: center;
  align-content: center;
  line-height: 25px;
  margin: 0 8px;
  font-weight: 600;
}
.active_pagination {
  color: var(--mainBlue);
  border: 2px solid var(--mainBlue) !important;
}

.active_main .main_blue_color {
  font-style: normal;
  font-weight: 300;
  font-size: 24px;
  line-height: 142%;
  text-align: center;
  padding-top: 7%;
}
/* ===================== END:: ACTIVE GAMES ================== */

/* ===================== BEGIN:: UPCOMING GAMES ================== */

.upcoming_games {
  background-color: var(--white);
}
.upcoming_games .main_blue_color {
  font-weight: 300;
  font-size: 24px;
  line-height: 142%;
}
.upcoming_single_active {
  background: rgba(255, 255, 255, 0.55);
  /* Second */

  border: 0.671429px solid var(--lightPurpal);
  /* Shadow */

  box-shadow: 2px 4px 10px rgba(0, 0, 0, 0.1);
  border-radius: 6.71429px;
  text-align: center;
  margin: 10px 0;
}
.upcoming_single_active .upcoming_game_btn {
  background: var(--gray);
  box-shadow:
    0px 2.68571px 2.68571px rgba(0, 0, 0, 0.25),
    inset -2.01429px -6.04286px 2.68571px rgba(0, 0, 0, 0.05),
    inset 4.02857px 2.68571px 2.68571px rgba(255, 255, 255, 0.1);
  border-radius: 8.72857px;
  display: inline-block;
  border: 0;
  color: var(--white);
  text-transform: uppercase;
  margin: 15px 0 10px 0;
  padding: 10px 18px;
}
.upcoming_single_active h4 {
  color: var(--mainBlue);
  font-size: 18.8356px;
  line-height: 135%;
  padding-bottom: 6px;
}

/* ===================== END:: UPCOMING GAMES ================== */

/* ===================== BEGIN::LOGIN REGISTER POP_UP ================== */
.pop_up_inner {
  top: 0;
  background-color: red;
}

.modal-header {
  border-bottom: 0px solid transparent;
}
.modal-content {
  background-color: rgba(251, 251, 251, 0.9);
  -webkit-backdrop-filter: blur(4.5px);
          backdrop-filter: blur(4.5px);
  border-radius: 50px;
}
.modal-body {
  padding: 0px;
}
.modal-header .btn-close {
  padding: calc(var(--bs-modal-header-padding-y) * 1.5)
    calc(var(--bs-modal-header-padding-x) * 1.5);
}
.pop_logo_section {
  color: var(--black);
}
.pop_logo_section h3 {
  font-weight: 500;
  font-size: 32px;
  margin: 20px 0px;
}
.pop_logo_section p {
  font-weight: 400;
  font-size: 18px;
}
.login_fields {
  background: var(--lightPurpal);
  opacity: 100%;
  border-radius: 50px;
  padding: 32px;
  grid-gap: 16px;
  gap: 16px;
  box-shadow: 0px 10px 15px rgba(0, 0, 0, 0.2);
}

.login_button {
  padding: 16px 35px;
  background: linear-gradient(270deg, #4d3acd 0%, #514f93 100%);
  border: none;
  outline: none;
  color: var(--white);
  border-radius: 50px;
  width: 100%;
  margin-bottom: 20px;
  transition: 0.3s;
}
.login_button:hover {
  background: linear-gradient(270deg, #514f93 0%, #4d3acd 100%);
}
.create_acc_link a {
  text-decoration: underline;
  text-transform: uppercase;
  font-weight: 500;
  font-size: 18px;
  color: var(--black);
  transition: 0.3s;
}
.create_acc_link a:hover {
  color: var(--mainBlue);
}
.trouble_login span {
  font-weight: 500;
  font-size: 20px;
  color: var(--black);
}
.trouble_login span a {
  font-weight: 500;
  font-size: 18px;
  color: var(--black);
  text-decoration: underline;
  transition: 0.3s;
}
.trouble_login span a:hover {
  color: var(--mainBlue);
}
.create_acc_filds .login_field {
  width: calc(50% - 10px);
  margin: 5px;
}
/* ===================== END::LOGIN REGISTER POP_UP ================== */

/* ===================== BEGIN:: LOGIN REGISTER page ================== */

.sign_title p {
  font-weight: 700;
  font-size: 24px;
  color: var(--black);
}
.sign_title h2 {
  font-weight: 700;
  font-size: 60px;
  color: var(--mainBlue);
}
.trouble_login_page span a {
  color: var(--mainBlue);
}
.trouble_login_page span {
  font-weight: 400;
  font-size: 18px;
}

.auth_mian {
  background: var(--white);
  border-radius: 44px;
}
.auth_input_fie input {
  width: 40px;
  height: 50px;
  background: var(--gray);
  border: none;
  outline: none;
  border-radius: 5px;
  font-size: 38px;
  margin: 0px 3px;
  text-align: center;
  -webkit-appearance: none !important;
          appearance: none !important;
}

.error-message {
  color: red;
  font-size: 12px;
  margin-top: 5px;
}
.acc_border {
  border: 1px solid var(--mainBlue);
}
.acc_login div {
  margin-bottom: 20px;
}
.with_log {
  background: var(--white);
  border-radius: 70px;
  padding: 10px 0px;
  align-items: center;
  display: flex;
  justify-content: center;
}
.middle_border {
  border: 1px solid var(--mainBlue);
  height: 350px;
}
.login_main > div {
  margin: 90px 100px;
}
.login_main_register > div {
  margin: 70px 100px;
  margin-bottom: 0px;
}
.with_log {
  cursor: pointer;
}
.with_log span:last-child {
  font-weight: 500;
  font-size: 20px;
  margin-left: 15px;
}
/* ===================== END:: LOGIN REGISTER page================== */

/* ===================== BEGIN:: CASINO BANNER ================== */

.become_member_main {
  background: linear-gradient(270deg, #4d3acd 0%, #514f93 100%);
  text-transform: uppercase;
}
.become_member_main p {
  font-weight: 600;
  font-size: 24px;
  line-height: 142%;
}
.become_member_main .become_member input {
  background: transparent;
  border: 0;
  border-bottom: 2px solid var(--lightPurpal);
  width: 100%;
  height: 75px;
  font-size: 60px;
  color: var(--white);
  padding-right: 210px;
}
.become_member {
  position: relative;
}
.become_member_main .become_member input::placeholder {
  font-size: 60px;
  color: var(--white);
}
.become_member_main .become_member input:focus {
  outline: 0;
}
.become_member .common_button {
  position: absolute;
  bottom: 15px;
  right: 0;
  margin-bottom: 0 !important;
  padding: 12px 28px;
}
.section_rules {
  font-weight: 400;
  font-size: 14px;
  line-height: 110%;
  color: var(--white);
  text-transform: capitalize !important;
}
.login_button span:first-child {
  margin-right: 7px;
}

/* ===================== END:: CASINO BANNER ================== */

/* ===================== BEGIN:: user account ================== */
.user_details h3 {
  font-weight: 700;
  font-size: 26px;
  color: var(--black);
}
.user_details p {
  font-weight: 500;
  font-size: 20px;
  color: var(--mainBlue);
  padding: 7px 0px;
}
.user_details div {
  border: 1px solid var(--mainBlue);
}
.edit_profile_button {
  padding: 8px 16px;
  background: var(--white);
  border-radius: 15px;
  width: 130px;
  transition: 0.3s;
  margin-left: auto;
  text-align: center;
}
.edit_profile_button_main {
  display: flex;
  flex-direction: column-reverse;
}
.edit_profile_button a {
  font-weight: 400;
  font-size: 18px;
  color: var(--mainBlue);
}
.edit_profile_button:hover {
  background: var(--pink);
}

.p_button_common {
  padding: 16px 64px;
  /* background: var(--white); */
  border: 1px solid var(--mainBlue);
  color: var(--mainBlue);
  font-weight: 700;
  border-radius: 10px;
  font-size: 26px;
  align-items: center;
  display: flex;
  justify-content: center;
  transition: 0.3s;
}

.active {
  border: 1px solid transparent !important;
  color: var(--mainBlue) !important;
}
.p_button_common:hover {
  background: var(--white);
}
.p_button_common span:first-child {
  margin-right: 10px;
}

.balance_main {
  background: var(--white);
  border-radius: 20px;
  padding: 55px 110px;
}
.balance_contant span {
  font-weight: 500;
  font-size: 22px;
  color: var(--mainBlue);
}
.balance_contant span:last-child p {
  font-weight: 400;
  font-size: 18px;
  color: var(--black);
}
.balance_contant > span > div:first-child {
  border: 1px solid var(--mainBlue);
}
.account_background {
  padding: 55px 32px;
  background: var(--lightPurpal);
  border-radius: 20px;
  margin-top: 40px;
  box-shadow: 0px 10px 15px rgba(0, 0, 0, 0.2);
}

.t_top_btn_comman {
  padding: 15px 70px;
  border-radius: 50px;
  color: var(--white);
  border: 1px solid var(--mainBlue);
  cursor: pointer;
  color: var(--black);
  transition: 0.3s;
  text-transform: uppercase;
}
.t_top_btn_comman:hover {
  background: var(--mainBlue);
  color: var(--white);
}
.tbl_top_btn_active {
  border: 1px solid var(--mainBlue);
}
.active-a {
  background: var(--mainBlue);
  color: var(--white);
}
.balance_table_main table thead tr th {
  background: var(--mainBlue);
  color: var(--white);
}
.balance_table_main table tbody tr td {
  color: var(--mainBlue);
}

.balance_table_main {
  overflow: auto;
}

.wrapper {
  text-align: center;
  /* border-bottom: 1px solid #343436; */
  background-color: #333;
}

.tab-menu ul li a.active-a {
  background-color: #588d7d;
  color: #ffffff;
}
.tab {
  display: none;
}
.tabb {
  display: none;
}

.tab-active {
  display: block;
}
.tab-active-b {
  display: block;
}
/* ===================== END:: user account ================== */

/* ===================== BEGIN::  account ================== */
.a_button_common {
  border-radius: 10px 10px 0px 0px;
  border-top: 2px solid var(--mainBlue);
  border-left: 2px solid var(--mainBlue);
  border-right: 2px solid var(--mainBlue);
  background: transparent;
  /* transition: .3s; */
}
.a_button_common:hover {
  background-color: var(--white);
  border-top: 2px solid var(--white);
  border-left: 2px solid var(--white);
  border-right: 2px solid var(--white);
}
.a_button_common_active {
  background-color: var(--white);
  border-top: 2px solid var(--white);
  border-left: 2px solid var(--white);
  border-right: 2px solid var(--white);
}
.account_page tbody td div {
  display: flex;
  align-items: center;
}
td span.green {
  height: 20px;
  width: 20px;
  border-radius: 20px;
  background: #24ff00;
  margin-right: 10px;
}
td span.red {
  width: 20px;
  height: 20px;
  background: #ff344d;
  border-radius: 20px;
  margin-right: 10px;
}
.active_row_color div p {
  border-radius: 50px;
  width: 39px;
  height: 39px;
  background: var(--gray);
  margin-right: 15px !important;
}
.order_common {
  font-weight: 500;
  font-size: 20px;
  color: var(--black);
  padding: 16px 36px 16px 90px;
}

.active_row_color {
  font-weight: 700;
  font-size: 26px;
  color: var(--white);
  padding: 16px 36px;
  background-color: var(--mainBlue);

  border-radius: 100px;
}
.active_row_border {
  background: transparent;
  border: 1px solid var(--mainBlue);
  color: var(--black);
  padding: 7px 33px;
}
.active_row_border div p {
  background: transparent;
}
.confirm_order_btn ul li:first-child a {
  background-color: var(--white);

  color: var(--black);
  border: 1px solid transparent;
}
.confirm_order_btn ul li:last-child a,
.confirm_order_btn ul li:first-child a {
  padding: 8px 190px;
  border-radius: 100px;
  transition: 0.3s;
  display: block;
}
.confirm_order_btn ul li:first-child a:hover {
  border: 1px solid var(--mainBlue);
}
.confirm_order_btn ul li:last-child a {
  color: var(--white);
  background-color: var(--mainBlue);
}
.confirm_order_btn ul li:last-child a:hover {
  opacity: 0.7;
}
/* ===================== END::  account ================== */

/* ===================== BEGIN:: profile ================== */
.available_balance {
  background: var(--white);
  box-shadow: 2px 4px 10px rgba(0, 0, 0, 0.1);
  border-radius: 15px;
  padding: 8px 16px;
  color: var(--mainBlue);
  text-align: end;
  margin-bottom: 10px;
}
.available_balance span {
  font-size: 32px;
  line-height: 140%;
  font-weight: 500;
}

.single_profile_button_main {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.single_profile_button {
  width: 100%;
  margin: 8px;
}

.single_profile_button a.active,
.twofa_setup_box div a.active {
  border: 1px solid var(--mainBlue) !important;
}

.single_profile_button a {
  display: block;
  padding: 16px 60px;
  background: #fbfbfb;
  border-radius: 10px;
  text-align: center;
  font-size: 20px;
  font-weight: 700;
  color: var(--mainBlue);
  transition: 0.3s;
  border: 1px solid transparent;
  width: 100%;
}
.single_profile_button a:hover {
  border: 1px solid var(--mainBlue);
}
.twofa_setup_box {
  height: 100%;
}
.twofa_setup_box div {
  height: 100%;
}
.twofa_setup_box div a {
  height: calc(100% - 16px);
  background: #fbfbfb;
  border-radius: 10px;
  font-size: 26px;
  color: var(--mainBlue);
  font-weight: 700;
  align-items: center;
  display: flex;
  margin: 6px 0;
  justify-content: center;
  width: 100%;
}
.two_factor_title_profile div h4 {
  color: #000 !important;
}
.secret_profile_btn button {
  display: block;
  width: 100%;
  color: var(--black);
  font-weight: 500;
  padding: 40px 45px;
  border: 2px solid var(--mainBlue);
  border-radius: 44px;
}
secret_profile_btn button span {
  display: block;
}
.six_digit_code_input input {
  width: 100%;
  padding: 144px 20px;
  text-align: center;
  border: 2px solid var(--mainBlue);
  border-radius: 44px;
  background: transparent;
  outline: none;
}
.six_digit_code_input input:focus-visible {
  outline: none;
  border: 2px solid var(--mainBlue);
}
.submit_profile_btn button div {
  display: flex;
  align-items: center;
  justify-content: center;
}
.submit_profile_btn button div img {
  padding-right: 12px;
}
.bottum_steps p {
  font-weight: 500;
  font-size: 20px;
  color: var(--black);
}
.bala_profile_main {
  border: 1px solid var(--mainBlue);
}
.profile_btn_active {
  border: 1px solid var(--mainBlue) !important;
}
.password_validate_pin input {
  text-align: center;
  padding: 45px 0 45px 0px !important;
}
/* ===================== END::  profile ================== */

.slick-list .img_active_main img {
  width: 80%;
}

.alert {
  text-align: left;
  position: relative;
  padding: 16px;
  margin-bottom: 1rem;
  border: 0px solid transparent !important;
  border-radius: 5px !important;
  border-color: none !important;
  font-size: 16px;
  font-weight: 700;
  line-height: 23px;
  letter-spacing: 0px;
}

.alert-error {
  color: rgba(244, 116, 30, 1) !important;
  background-color: #ffffff !important;
}

.alert-success {
  color: #fff !important;
  background: rgba(25, 103, 108, 1) !important;
}

.alert-warning {
  color: #fff !important;
  background: rgba(244, 116, 30, 1);
  font-weight: 500;
}

.alert-warning:before {
  content: "";
  background-image: url(/static/media/ic-smile-white.65c62394.svg);
  display: inline-block;
  z-index: 100000;
  width: 22px;
  height: 22px;
  background-size: contain;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.alert-white-warning {
  padding: 26px 34px 26px 34px;
  color: rgba(244, 116, 30, 1);
  border-radius: 98px !important;
  background-color: #fff;
}

.alert-white-warning:before {
  content: "";
  background-image: url(/static/media/ic-close-circle.20b719da.svg);
  display: inline-block;
  z-index: 100000;
  width: 24px;
  height: 24px;
  background-size: contain;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.alert-success:before {
  content: "";
  background-image: url(/static/media/ic-check-circle-white.615022ab.svg);
  display: inline-block;
  z-index: 100000;
  width: 22px;
  height: 22px;
  background-size: contain;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.alert-info-dark {
  color: rgba(24, 26, 35, 1) !important;
  background: rgba(212, 212, 212, 1) !important;
}

.alert-info-dark:before {
  content: "";
  background-image: url(/static/media/ic-info-dark.5ecf2a5f.svg);
  display: inline-block;
  z-index: 100000;
  width: 22px;
  height: 22px;
  background-size: contain;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.alert img {
  display: inline-block;
}

.alert p {
  display: inline-block;
  font-size: 18px !important;
  font-weight: normal !important;
  margin-left: 30px !important;
}

.alert-outline-warning {
  color: rgba(244, 116, 30, 1);
  border: 1px solid rgba(244, 116, 30, 1) !important;
}

.alert-outline-warning:before {
  content: "";
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAeCAYAAAA7MK6iAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAMuSURBVHgBxZZRUhpBEIa7Z9lESrTgLQ8BNyfQnCDrCaInwJwgJGjlEXhMCFXcIHoCcwPXG+AJ3Bgf8iYlUEZhp9Mzu0st6y5ZRMv/QYedmf6mZ3u7G+CZhIsspppVHBqwRcKzDMINbQCh70l5VvByPey6/ay2MoEHdctGpAaj7fkryTEEHua/XRzBMuBkIDlI6PJOV/8CKgKIregavgVXCLGd/+q6C4MH9UqTDTR8FrgkoDsZXx+Vuv3E67yqvbIMYdoCscFWLb2NZGutc9nMDJ6BAnXHk0ErDZik0X6lQQDNeXBMg/LGPhF9WO/8/gkP0HW9vMPe/+BhMQk+A775YlmeJ8/VWBLtPhQagx+rMcO3Ge6EcyK6UEp54p9GNpeFKvk2qKttqncf0RQ8PCjvEXFQcCCtfr9swSNpPDFayiaj7UH9tX0PDCSqwaiZZGC4X6kN98snV7ViMT6nXtHoYONYrYnPlTipCP4i1DjqtQZfcUYKv8Oxd3eaBOZN79SpV8zCZnzOkyqAaIdtvE/aezsWQUJBOzy4Buc4DfoT5JS6f9ykzXfj20+A4m2+fXnvYIW22zNQ2PzZ7SbtLelUio7PKmhWTv0xhNyUpNf0IEVpBwqVb7un8+Y5A7ocQxy4Qud47TE/0O4jYOYksbDIT7EYZDUBzyQN9pB+gX8qC55IfMv+rXIZnYJRGq6eRLLgyYQ6qDg56TjSwTXxoGfqkR/u8YIwOqicU8bbUCVxtX3xJvosSMW2Gv8dD8/Uf+1xNNxfmuvVuDGSkFlJaz3p2cGsEzqVm24gbHHI2/wuauz1TN0tdGY9WFT8tTT010p4GD6bRvVax3WU1+pKzRfrDXgkqTIb1gB24OgeWB+IvdYDCTVV0mBJjdhG2FBwKt6Ozs2AldcYFAlVxJeBKyj5jYAus/HMl9j6ZGld0uS3wFJ1MR9DaFKZTW32onDdNSKPUZymdY4KeGPKqgrO8NNLg/pzc6Q6R9N4cRJ2jQHCEYA91ZP5P8HiK7XiLbCB1EyqZJnAoa4/l/e4T65mauj/A1wIHEpltRWzuDkBuSWCisb9tmuQdFVGWqQFfjb9A9YtiV1WMlMVAAAAAElFTkSuQmCC);
  display: inline-block;
  z-index: 100000;
  width: 22px;
  height: 22px;
  background-size: contain;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.alert-outline-success {
  color: rgba(25, 103, 108, 1);
  border: 1px solid rgba(25, 103, 108, 1) !important;
}

.alert-outline-success:before {
  content: "";
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAeCAYAAAA7MK6iAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAOPSURBVHgBxZfNThNRFIDPmU5CK8Q0McHCxvIEQFC2Djt2ws4dmCg/oSbDE1B27qih/FUT4AnQJwC2RJKy05XjQhnd2ESQYjpzPOfeTmnLtLRAwlm0t/eee7/7c/4KcEeC7SjHbTseLZYGwMMkID1SCyAUyIOjYpeZL2QyhVbXagmcmElZSLDATesK1T0yaMtdW9mGm4DDgMSL86cDhI76jRhHoAGs2RQ5hN6Iu77utA3unUmlSUPVQuBj5qzT3G50nYkZOwmeZ6EhcyipFidY/JHLplsGV0N9wMx5LLLYzvvxTS0wNN0Mjo2gPKFQAnjxK5f9ANeQnqnUGC++SQjxMDjW7tROIpW+StsjGL8utBrOhB1ps+WPuO+ze8GYUbML8naVEl/TTaEix2oNzKi1I4G91IETM68ntVGQ4+ayi3BL0sH2wViHm1biZcq6BEaiCfmmslHUS8/UnN0zndqVIFI/Jk/E17rzkHXqxxxllHTp1Aqc1ItZusfbDwMj4lPRiZ56/fVjEeNfnN9yjHWehc3tiJlBQLGCjStwUcIg6ODQyOl9NOcN3x903y1f2tj31dU8GWgVY+Z42Fx9agk8ANETzTLVaQj6NRjz0EDc9YwDTcRdW95vNs5rOyhHM3SMVyfmd43rDVDLQaJtIY5+SjBZAd+FaDDhN/WNOsbWi7haYnZuAq6QZnpseHGNgsIFOKKvAQGToZOINtHHrTB3CUTcLdAL1yBlVDyer4CjUTMwKivMT3mXaaWMuNQ7PbcZBALRldQp/s1HWlK6IXFAZa6yuxY7I0cVcLW5d5yVLl2Vu86RjGheEgdb5yQHgl2GUeys9Jv7JMxK3i74AHZ41PMUVNw1yHJG1akWdQfZYac+zq1kfMMc9HXsrbiduImc8u89s+/nRvYthAgGeZ1oq9JXraCuTK6EMHOcW56HW5CL3E7O8cZKX9Bf407BqXmLdrektBtK92xqLCgopBSqHotU/zj5dOB0PRmWa7B4R6P3hoa/nB4efIZrQk0PNnmxqDyFu7H6sSG4DN8vw0cNhOf3h4bxz+HBHrQoYh8P+h+/4XddqkBDDK5hsVddN6mq0eA2efuNkogAY8oj0A6KvUbQpmANl1JIqpKaiLbH0/JUjusGRzu2bBm3AgVVAvuYDstkLYErG3jF1YlBE3hFQd8KsC1wIOovzLnXjyUakOpR9xqOZB6JSO2UwHcm/wFf665qELAEHAAAAABJRU5ErkJggg==);
  display: inline-block;
  z-index: 100000;
  width: 22px;
  height: 22px;
  background-size: contain;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

p {
  text-transform: initial !important;
}

.card-profile {
  display: inline-block;
  border: 0px;
  min-width: 470px;
}

.card-profile .card-body {
  padding: 32px;
}

.card-profile .profile-info {
  margin-left: 30px;
  min-width: 250px;
}

.card-profile .profile-info h1 {
  font-size: 26px;
  font-weight: 700;
  color: rgba(24, 26, 35, 1);
  margin-bottom: 6px !important;
}

.card-profile .profile-info label {
  margin-bottom: 10px !important;
}

.card-profile a {
  font-size: 16px;
  font-weight: 500;
  color: rgba(24, 26, 35, 1);
  text-decoration: underline !important;
}

.mt-10 {
  margin-top: 10px;
}

.txt-avl-bal {
  color: rgba(77, 58, 205, 1);
  font-size: 18px;
  font-weight: 400;
}

.qab {
  padding: 20px !important;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.qab.mini {
  padding: 15px 20px !important;
}

.qab.mini .txt-avl-bal {
  font-weight: 500 !important;
  text-transform: uppercase;
}

.qab div label {
  color: rgba(77, 58, 205, 1);
  font-size: 32px;
  font-weight: 500;
  display: inline-block;
  position: relative;
  top: 5px;
}

.qab div img {
  width: 26px;
  margin-top: -2px;
  margin-right: 6px;
}

.qab.mini div label {
  font-size: 20px;
  top: 2px;
}

.qab.mini div img {
  width: 16px;
  margin-top: 0px;
}

/* user profile */
.user-account-tabs,
.accounting-tabs {
  margin: 30px 6%;
}

.user-account-tabs ul,
.accounting-tabs ul {
  display: flex;
  flex-wrap: wrap;
}

.user-account-tabs ul li,
.accounting-tabs ul li {
  flex-grow: 1;
  width: 33%;
}

.accounting-tabs ul li {
  flex-grow: 1;
  width: 25%;
}

.user-account-tabs ul li a,
.accounting-tabs ul li a {
  background: #fff;
  box-shadow: 2px 4px 10px 0px rgba(0, 0, 0, 0.1);
  color: rgba(77, 58, 205, 1);
  font-weight: 700;
  font-size: 20px;
  padding: 15px;
  text-align: center;
  border-radius: 10px;
  margin: 10px;
  display: block;
}

.accounting-tabs ul li a {
  text-transform: uppercase;
}

.user-account-tabs ul li a.active,
.accounting-tabs ul li a.active {
  border: 2px solid #4d3acd !important;
}

.user-account-tabs-card {
  border: 2px solid rgba(81, 79, 147, 1);
}

.user-account-tabs-card .card-body,
.accounting-tabs-card .card-body {
  padding: 50px 80px;
}

.accounting-tabs-card,
.card {
  border: 0px;
}

.user-account-tabs-card .card-content,
.accounting-tabs-card .card-content {
  margin-top: 35px;
  background: linear-gradient(0deg, #f1efff, #f1efff),
    linear-gradient(0deg, rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0.2));
  border: 1px solid rgba(255, 255, 255, 0.2);
  box-shadow: 0px 10px 15px 0px rgba(0, 0, 0, 0.2);
  padding: 32px;
  border-radius: 20px;
  border: 1px;
  grid-gap: 16px;
  gap: 16px;
}

.accounting-tabs-card .card-content {
  margin: 0px;
}

/* user profile end */

/* crypto tabs */

.crypto-tabs {
  margin: 10px 0;
}

.crypto-tabs ul {
  display: flex;
  flex-wrap: wrap;
}

.crypto-tabs ul li {
  flex-grow: 1;
  width: 25%;
}

.crypto-tabs ul li a {
  background: #fff;
  box-shadow: 2px 4px 10px 0px rgba(0, 0, 0, 0.1);
  color: rgba(24, 26, 35, 1);
  font-weight: 500;
  font-size: 20px;
  padding: 15px 5px;
  text-align: center;
  border-radius: 10px;
  margin: 10px;
  display: block;
}

.crypto-tabs ul li a {
  text-transform: uppercase;
}

.crypto-tabs ul li a.active {
  border: 2px solid rgba(24, 26, 35, 1) !important;
  color: rgba(24, 26, 35, 1) !important;
}

.crypto-tabs ul li a img {
  display: inline-block;
  height: 32px;
  margin-right: 5px;
}

.crypto-tabs ul li a label {
  display: inline-block;
  position: relative;
  top: 1px;
}

/* transactions tabs */

.transactions-tabs ul {
  display: flex;
  flex-wrap: wrap;
}

.transactions-tabs ul li {
  flex-grow: 1;
  width: 50%;
}

.transactions-tabs ul li:first-child a {
  margin-left: 0px;
}

.transactions-tabs ul li:last-child a {
  margin-right: 0px;
}

.transactions-tabs ul li a {
  background: #fff;
  box-shadow: 2px 4px 10px 0px rgba(0, 0, 0, 0.1);
  color: rgba(77, 58, 205, 1);
  font-weight: 500;
  font-size: 20px;
  padding: 15px 5px;
  text-align: center;
  border-radius: 10px;
  margin: 0px 10px;
  display: block;
}

.transactions-tabs ul li a {
  text-transform: uppercase;
}

.transactions-tabs ul li a.active {
  border: 2px solid rgba(77, 58, 205, 1) !important;
  color: rgba(77, 58, 205, 1) !important;
}

.transactions-tabs ul li a img {
  display: inline-block;
  height: 32px;
  margin-right: 5px;
}

.transactions-tabs ul li a label {
  display: inline-block;
  position: relative;
  top: 1px;
}

a * {
  cursor: pointer;
}

/* transactions tabs end */

.form-control {
  padding: 8px 18px 8px 18px;
  border-radius: 10px;
  border: 2px solid rgba(212, 212, 212, 1);
  outline: none;
  box-shadow: none !important;
}

.form-control:focus {
  border: 2px solid #4d3acd;
}

.card-content .tfa-auth {
  text-align: center;
  background: linear-gradient(0deg, #f1efff, #f1efff),
    linear-gradient(0deg, #dbb7fc, #dbb7fc);
  border: 1px solid rgba(219, 183, 252, 1) !important;
  padding: 16px 32px 16px 32px;
  border-radius: 10px;
  border: 1px;
}

.card-content .tfa-auth img {
  width: 40px;
}

.card-content .tfa-auth label {
  font-family: Roboto;
  font-size: 20px;
  font-weight: 500;
  line-height: 23px;
  letter-spacing: 0px;
  text-align: center;
  color: rgba(219, 183, 252, 1);
  vertical-align: middle;
  margin: 7px 0px 0px 7px;
}

.btn-quinto {
  padding: 16px 35px 16px 35px;
  border-radius: 80px;
  grid-gap: 16px;
  gap: 16px;
  background: linear-gradient(270deg, #4d3acd 0%, #514f93 100%);
  color: #fff;
  /* text-transform: uppercase; */
  font-size: 1.25rem
}

.btn-quinto img {
  width: 20px;
  margin-top: -2px;
  margin-right: 10px;
}

.btn-quinto:hover,
.btn-quinto:active,
.btn-quinto:focus {
  background: linear-gradient(270deg, #0b0a32 0%, #4d3acd 100%);
  color: #fff;
}

.btn-black {
  display: block;
  width: 100%;
}

.btn-progress,
.btn-progress:hover,
.btn-progress:active,
.btn-progress:focus {
  background: rgba(219, 183, 252, 1);
  color: black;
}

.btn-quinto:disabled {
  background: rgba(222, 222, 222, 1) !important;
  color: #1b1a1a;
}

.tfa-auth.tfa-auth-in-prog {
  background: #fff !important;
  border: 2px solid rgba(219, 183, 252, 1) !important;
}

.tfa-auth-in-prog img,
.tfa-warning img,
.tfa-success img {
  width: 28px !important;
}

.tfa-auth-in-prog label,
.tfa-warning label,
.tfa-success label {
  color: #000 !important;
}

.tfa-auth.tfa-warning {
  background: #fff !important;
  border: 2px solid rgba(244, 116, 30, 1) !important;
}

.tfa-auth.tfa-success {
  background: #fff !important;
  border: 2px solid rgba(25, 103, 108, 1) !important;
}

.otp-field {
  display: inline-block;
}

.otp-field input,
.otp-field input:active,
.otp-field input:focus,
.otp-field input:hover {
  width: 40px !important;
  border: 0px !important;
  background: rgb(200, 200, 200) !important;
  border-radius: 5px !important;
  display: inline-block !important;
  color: #000 !important;
  padding: 10px 10px !important;
  text-align: center !important;
  font-weight: 500 !important;
  font-size: 20px !important;
  line-height: 23.44px !important;
  outline: none !important;
}

.pwd-field {
  position: relative;
}

.pwd-field .pwd-hand {
  position: absolute;
  top: 0px;
  height: 100%;
  border-radius: 10px;
  right: 0px;
  box-sizing: border-box;
  padding: 30px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.pwd-field .pwd-hand img {
  width: 24px;
}

.form-control {
  font-family: Roboto;
  font-size: 20px;
  font-weight: 500;
  line-height: 23px;
  letter-spacing: 0px;
}

main {
  margin-bottom: 100px;
}

footer {
  border-top: 2px solid rgba(77, 58, 205, 1);
}

footer .container.pt {
  padding-top: 20px;
}

footer h3 {
  font-size: 26px;
  font-weight: 700;
  line-height: 30px;
  letter-spacing: 0px;
  text-align: left;
  margin-bottom: 20px;
}

footer ul.links li {
  display: block;
}

footer ul.links li a {
  display: inline-block;
  padding: 4px 8px 4px 8px;
  border-radius: 20px;
  grid-gap: 8px;
  gap: 8px;
  background: rgba(251, 251, 251, 1);
  margin: 8px 0px;
  color: #000;
  font-weight: 400;
  font-size: 16px;
}

footer ul.links li a img {
  width: 21.5px;
}

.h-line {
  border-top: 2px solid rgba(77, 58, 205, 1);
}

input[readonly] {
  border: 2px solid rgba(24, 26, 35, 1) !important;
  background: rgba(241, 239, 255, 1) !important;
}

.form-control-lg {
  padding: 64px 32px 64px 32px !important;
  text-align: center;
}

.navbar > .container {
  padding-left: 4px;
  padding-right: 4px;
}

.navbar-nav {
  align-items: center;
  width: 60%;
  justify-content: space-between;
  max-height: 100px;
}

.navbar-toggler {
  padding: 0px !important;
}

.navbar-close-section-mobile,
.navbar-info-section-mobile,
.home-section-sticky-mobile,
.navbar-profile-section-mobile,
.navbar-buttons-section-mobile {
  display: none;
}

.go_back_to_main {
  font-weight: 500;
  font-size: 18px;
  line-height: 21.09px;
  color: rgba(24, 26, 35, 1);
  text-decoration: underline !important;
}

.profile-top-card {
  background: #fff;
  box-shadow: 2px 4px 10px 0px rgba(0, 0, 0, 0.1);
  padding: 6px 10px 6px 10px;
  border-radius: 40px;
}

.qpoints {
  display: inline-block;
  color: rgba(77, 58, 205, 1);
  border: 1px solid rgba(77, 58, 205, 1);
  border-radius: 40px;
  padding: 2px 8px 4px 8px;
}

.qpoints img {
  width: 18px;
  margin: 0px;
  margin-right: 0px !important;
}

.qpoints label {
  font-size: 22px;
  line-height: 24px;
  font-weight: 500;
  position: relative;
  top: 3px;
}

.notification-bell {
  background: #fff;
  box-shadow: 2px 4px 10px 0px rgba(0, 0, 0, 0.1);
  padding: 10px;
  border-radius: 40px;
  margin-right: 10px;
  position: relative;
}

.navbar .notification-bell {
  display: none;
}

.notification-bell img {
  margin: 0px !important;
}

.badge {
  display: inline-block;
  padding: 4px 7px 4px 6px;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 100px;
  background: rgba(77, 58, 205, 1);
}

.notification-bell .badge {
  position: absolute;
  top: 0px;
  font-size: 75%;
  right: 0px;
}

.newsletter {
  color: #fff;
  background: linear-gradient(270deg, #4d3acd 0%, #514f93 100%);
}

.newsletter h4,
.newsletter h1 {
  color: #fff;
  font-weight: 700;
}

.newsletter h4 {
  font-size: 26px;
}

.newsletter h1 {
  font-size: 48px;
  margin-top: 10px;
  margin-bottom: 40px;
}

.newsletter .input-group {
  position: relative;
  background: rgba(241, 239, 255, 1);
  border-radius: 99px !important;
}

.newsletter input {
  padding: 32px 64px;
  min-height: 112px;
  font-size: 20px;
  border-radius: 99px !important;
  background: rgba(241, 239, 255, 1);
  position: relative;
}

.newsletter input:focus {
  outline: none !important;
  box-shadow: none !important;
  border: 0px !important;
}

/* .newsletter button{
	font-size:20px !important;
	position:absolute !important;
	background:linear-gradient(270deg, #4D3ACD 0%, #514F93 100%) !important;
	top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    right: 18px !important;
    padding: 26px 40px !important;
    border-radius: 99px !important;
	z-index: 9999 !important;
	height: auto !important;
	font-weight: normal !important;
	letter-spacing: initial !important;
}

.newsletter button svg{
	fill:#FFF;
	margin-right:5px;
}


.newsletter button:hover {
    background: linear-gradient(180deg, #6819A3 2.44%, #5D1394 10.33%, #4E0B80 23.48%, #430571 39.26%, #3D0269 58.54%, #3C0167 90.1%) !important;
} */

.home-banner {
  min-height: 90vh;
  background-image: url(/static/media/comming-soon.1282ccb3.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  width: 100vw;
  position: relative;
}

.home-banner .cnt-right {
  padding-top: 2vh;
  text-align: center;
}

.home-banner .cnt-right h1 {
  color: #fff;
  font-weight: 700;
  font-size: 96px;
  line-height: 112.5px;
}

.home-banner .tickets-presale {
  position: absolute;
  z-index: 2;
  width: 100%;
  bottom: 0;
}

.home-banner .tickets-presale::before {
  content: "";
  position: absolute;
  top: 0px;
  left: 0px;
  background-blend-mode: multiply;
  background: linear-gradient(270deg, #00052dd4 0%, #514f93cf 100%);
  height: 100%;
  width: 100%;
  display: inline-block;
  z-index: 1;
  pointer-events: none;
}

.home-banner .tickets-presale .tp-content {
  padding: 30px 0px;
  position: relative;
  z-index: 2;
}

.home-banner .tickets-presale .tp-content .windfall-img {
  margin: 0 auto;
  width: 357px;
  display: block;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: -40px;
}

.h_language.country-flag ul li label {
  margin-left: 10px !important;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 21px;
  cursor: pointer;
}

.h_language.country-flag ul li {
  padding: 10px;
  cursor: pointer;
}

.img-responsive {
  width: 100%;
}

.pages {
  position: relative;
}

.page-bg-white {
  background: #fff;
  margin-bottom: -100px;
  padding: 64px 0px;
}

.page-bg-white .container .heading {
  font-size: 48px;
  line-height: 56.25px;
}

.page-bg-white a[target="_blank"] {
  color: rgba(24, 26, 35, 1);
  text-decoration: underline !important;
}

.pages .pages-header .container {
  background-image: url(/static/media/img-pages-header.c79064f2.svg);
  width: 100%;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: right;
  background-position-y: -6px;
  min-height: 41vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.pages .pages-header .container h1 {
  font-weight: 700;
  color: rgba(77, 58, 205, 1);
  text-transform: uppercase;
  font-size: 50px;
}

.pages .heading {
  color: rgba(77, 58, 205, 1);
  font-weight: 700;
  font-size: 82px;
}

.pages .small_heading {
  color: rgba(24, 26, 35, 1);
  font-weight: 700;
  font-size: 26px;
  text-transform: capitalize;
}

.pages p {
  color: rgba(24, 26, 35, 1);
  font-weight: 400;
  font-size: 18px;
}

.pages .inside {
  padding: 0px 100px;
}

.pages .blue-card {
  border-radius: 30px;
  background: linear-gradient(270deg, #4d3acd 0%, #514f93 100%),
    linear-gradient(0deg, #f1efff, #f1efff);
  box-shadow: 2px 4px 10px 0px rgba(0, 0, 0, 0.1);
  text-align: center;
  padding: 32px;
  min-height: 360px;
  justify-content: center;
}

.pages .blue-card .fl-centered {
  justify-content: center;
  align-items: center;
  display: flex;
}

.pages .blue-card h3 {
  color: rgba(241, 239, 255, 1);
  font-weight: 500;
  font-size: 32px;
  line-height: 46px;
  margin-bottom: 16px;
}

.pages .blue-card p {
  color: rgba(241, 239, 255, 1);
  font-weight: 400;
  font-size: 18px;
  line-height: 21.09px;
}

.pages .rect-icon {
  background: linear-gradient(0deg, #f1efff, #f1efff),
    linear-gradient(0deg, #fbfbfb, #fbfbfb);
  width: 66px;
  height: 66px;
  border: 3px solid rgba(251, 251, 251, 1);
  opacity: 0.6;
  border-radius: 15px;
  display: inline-block;
}

.pages .card-white {
  padding: 110px 222px 110px 222px;
  border-radius: 20px;
  border: 0px;
}

.pages .card-white .small_heading {
  font-size: 26px;
  line-height: 30.47px;
  font-weight: 700;
  text-transform: capitalize;
}

.pages .card-white .heading {
  font-size: 82px;
  line-height: 96.09px;
  font-weight: 700;
}

.line-infront-icon {
  margin-top: 30px;
}

.line-infront-icon .rect-icon {
  height: 50px;
  flex-basis: 50px;
  flex-grow: 0;
  flex-shrink: 0;
  border: 3px solid rgba(251, 251, 251, 1);
  background: linear-gradient(270deg, #4d3acd 0%, #514f93 100%),
    linear-gradient(0deg, #fbfbfb, #fbfbfb);
  display: block;
  margin-right: 30px;
}

.pages .code-block-content {
  margin-left: 80px;
  margin-top: 10px;
}

.pages .code-block {
  border: 1px solid rgba(251, 251, 251, 1);
  background: linear-gradient(0deg, #f1efff, #f1efff),
    linear-gradient(0deg, #fbfbfb, #fbfbfb);
  color: #000;
  border-radius: 5px;
  padding: 8px;
  font-size: 14px;
  line-height: 16.41px;
  font-weight: 400;
}

.pages .code-block:first-child {
  margin-right: 10px;
}

.pages .code-block-content ul {
  margin-left: 50px !important;
  list-style: disc !important;
}

.pages .code-block-content ul li {
  margin: 10px 0px;
}

.pages .code-block-content ul li p {
  font-size: 16px;
  line-height: 21.09px;
  font-weight: 400;
}

.mini-table {
  border-collapse: separate;
  border-spacing: 0px;
}

.mini-table th {
  background: rgba(24, 26, 35, 1);
  color: rgba(241, 239, 255, 1);
  font-size: 18px;
  font-weight: 400;
  line-height: 21.09px;
  padding: 10px 12px 10px 12px;
}

.mini-table td {
  border: 1px solid rgba(24, 26, 35, 1);
  font-size: 18px;
  font-weight: 400;
  line-height: 21.09px;
  padding: 10px 12px 10px 12px;
}

.mini-table th:first-child {
  border-top-left-radius: 4px;
}

.mini-table th:last-child {
  border-top-right-radius: 4px;
}

.mini-table tbody tr:last-child td:first-child {
  border-bottom-left-radius: 4px;
}

.mini-table tbody tr:last-child td:last-child {
  border-bottom-right-radius: 4px;
}

.mini-table tbody tr td:first-child {
  border-left: 2px solid rgba(24, 26, 35, 1);
}

.mini-table tbody tr td:last-child {
  border-right: 2px solid rgba(24, 26, 35, 1);
}

.mini-table tbody tr:last-child td {
  border-bottom: 2px solid rgba(24, 26, 35, 1);
}

.section_how_it_works {
  min-height: 400px;
  position: relative;
}

.section_how_it_works:before {
  content: "";
  background-image: url(/static/media/img-how-it-works.f1f91df3.svg);
  display: inline-block;
  background-repeat: no-repeat;
  z-index: 100000;
  width: 100%;
  height: 100%;
  right: 0;
  background-size: contain;
  position: absolute;
  background-position: right;
}

.hiw-card {
  padding: 110px !important;
}

.hiw-card h2 {
  font-size: 26px;
  line-height: 30.47px;
  font-weight: 700;
}

.hiw-card p {
  margin: 16px 0px !important;
}

.faqs-item a.read-more-less,
.faqs-section a.read-more-less {
  color: rgba(24, 26, 35, 1);
  font-weight: 500;
  font-size: 16px;
  line-height: 21.09px;
  text-decoration: underline !important;
  text-transform: capitalize;
}

.mh-1200 {
  min-height: 1400px;
}

.section-sticky-right {
  position: absolute;
  top: 0;
  right: 0;
  border-radius: 80px 0px 0px 80px;
  background: linear-gradient(270deg, #4d3acd 0%, #514f93 100%);
  padding: 100px;
}

.section-sticky-right .sticky-content {
  color: rgba(241, 239, 255, 1);
  text-align: center;
}

.section-sticky-right h2 {
  font-weight: 700;
  font-size: 36px;
  line-height: 56.25px;
  color: rgba(241, 239, 255, 1);
}

.section-sticky-right h3 {
  font-weight: 700;
  font-size: 26px;
  line-height: 30.47px;
  color: rgba(241, 239, 255, 1);
}

.section-sticky-right label {
  font-weight: 400;
  font-size: 14px;
  line-height: 16.41px;
  color: rgba(241, 239, 255, 1);
}

.faqs-section {
  padding: 16px 32px;
  margin: 32px 0px;
  border-radius: 20px;
  background: rgba(251, 251, 251, 1);
}

.faqs-section .text {
  font-weight: 700;
  font-size: 26px;
  line-height: 30.47px;
  color: rgba(24, 26, 35, 1);
}

.faqs-section .divider {
  border: 1px solid rgba(77, 58, 205, 1);
  margin: 16px 0px;
}

.faqs-section a.read-more-less {
  margin-left: 150px;
}

.section_support {
  min-height: 400px;
  position: relative;
}

.section_support:before {
  content: "";
  background-image: url(/static/media/img-support-bg.cb805a12.svg);
  display: inline-block;
  background-repeat: no-repeat;
  z-index: 100000;
  width: 100%;
  height: 100%;
  right: 0;
  background-size: contain;
  position: absolute;
  background-position: right;
}

.error-page {
  padding: 128px;
  background: linear-gradient(202.12deg, #4d3acd 50.34%, #514f93 61.96%);
  margin-bottom: -100px;
}

.error-page a {
  font-size: 18px;
  line-height: 21.09px;
  font-weight: 500;
  color: rgba(219, 183, 252, 1);
}

.error-page label {
  font-size: 26px;
  line-height: 30.47px;
  font-weight: 700;
  color: rgba(251, 251, 251, 1);
}

.error-page h1 {
  font-size: 82px;
  line-height: 96.09px;
  font-weight: 700;
  color: rgba(241, 239, 255, 1);
}

.error-page h2 {
  font-size: 48px;
  line-height: 56.25px;
  font-weight: 700;
  color: rgba(241, 239, 255, 1);
}

.error-page .divider {
  border: 1px solid rgba(241, 239, 255, 1);
  margin: 24px 0px;
}

.back-to-doc {
  font-weight: 500;
  font-size: 18px;
  line-height: 21.09px;
  color: rgba(0, 0, 0, 1);
}

.section-sticky-right .game-box {
  border-radius: 47px;
  border: 2.9px solid rgba(251, 251, 251, 1);
  box-shadow: 1.448888897895813px 2.897777795791626px 7.244444370269775px 0px
    rgba(0, 0, 0, 0.1);
  background: #fff;
  padding: 0px 20px;
  margin: 60px 0px;
  position: relative;
  min-height: 300px;
  max-width: 350px;
}

.section-sticky-right .game-box .game-sec img {
  min-width: 300px;
  max-width: 300px;
}

.section-sticky-right .game-box .game-sec {
  display: flex;
  align-items: center;
}

.section-sticky-right .game-box label {
  font-weight: 700;
  font-size: 18.84px;
  line-height: 22.07px;
  color: rgba(77, 58, 205, 1);
  margin-top: 30px;
  text-transform: uppercase;
}

.section-sticky-right .game-box a {
  right: 10%;
  position: absolute;
  bottom: -20px;
  background: linear-gradient(270deg, #4d3acd 0%, #514f93 100%) !important;
  box-shadow:
    0px 2.897777795791626px 2.897777795791626px 0px rgba(0, 0, 0, 0.25),
    4.3466668128967285px 2.897777795791626px 2.897777795791626px 0px
      rgba(255, 255, 255, 0.1) inset,
    -2.1733334064483643px -6.519999980926514px 2.897777795791626px 0px
      rgba(0, 0, 0, 0.05) inset;
}

.section-sticky-right .game-box a:hover {
  background: linear-gradient(270deg, #514f93 0%, #4d3acd 100%) !important;
}

.section-sticky-right .game-box.trimmora {
  background: radial-gradient(
      50% 50% at 50% 50%,
      rgba(255, 255, 255, 0.5) 0%,
      rgba(255, 255, 255, 0) 100%
    ),
    rgba(255, 255, 255, 0.55);
}

.card-white-normal {
  background: #fff;
  border-radius: 20px;
  padding: 25px;
}

.twofa-card .card-white-normal {
  text-align: center;
}

.twofa-card .card-white-normal label {
  font-size: 20px;
  line-height: 23.44px;
  font-weight: 500;
}

.twofa-card .card-white-normal.qr label {
  color: rgba(77, 58, 205, 1);
  font-size: 20px;
  line-height: 23.44px;
  font-weight: 500;
  margin-top: 20px;
}

.twofa-card .card-white-normal canvas {
  width: 100% !important;
  height: auto !important;
  display: block;
}

.twofa-card .card-white-normal.sk .otp-field input {
  background: rgba(212, 212, 212, 1) !important;
}

.ms-10 {
  margin-left: 10px;
}

main .main-loader {
  height: 60vh;
}

main .main-loader {
  margin-bottom: -100px;
}

.main-loader {
  height: 100vh;
  width: 100%;
  background: rgba(77, 58, 205, 1);
}

.main-loader div {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.main-loader svg {
  width: 60% !important;
  margin: 0 auto;
  margin-top: -2%;
}

.qtable {
  border-radius: 10px;
}

.qtable thead tr th {
  background: rgba(77, 58, 205, 1);
  color: #fff;
  font-size: 1.2rem;
}

.qtable {
  border-spacing: 0;
  border-radius: 10px 10px 10px 10px;
  -moz-border-radius: 10px 10px 10px 10px;
  -webkit-border-radius: 10px 10px 10px 10px;
}

.qtable th:first-child {
  border-radius: 10px 0 0 0;
  -moz-border-radius: 10px 0 0 0;
  -webkit-border-radius: 10px 0 0 0;
}

.qtable th:last-child {
  border-radius: 0 10px 0 0;
  -moz-border-radius: 0 10px 0 0;
  -webkit-border-radius: 0 10px 0 0;
}

.qtable tr:last-child td {
  border: 0px;
}

.qtable tr:last-child td:first-child {
  border-radius: 0px 0px 0px 10px;
  -moz-border-radius: 0px 0px 0px 10px;
  -webkit-border-radius: 0px 0px 0px 10px;
}

.qtable tr:last-child td:last-child {
  border-radius: 0 0 10px 0;
  -moz-border-radius: 0 0 10px 0;
  -webkit-border-radius: 0 0 10px 0;
}

.qtable td:first-child,
.qtable th:first-child {
  border-left: medium none;
}

.qtable th {
  background-color: rgba(77, 58, 205, 1);
  border-top: medium none;
}

.qtable td,
.qtable th {
  padding: 15px;
  text-align: left;
  vertical-align: middle;
}

.qtable tbody tr td {
  color: rgba(77, 58, 205, 1);
  font-size: 16px;
  line-height: 27px;
  text-transform: uppercase;
  font-weight: 600;
}

.qtable .label {
  font-size: 20px;
  font-weight: 500;
  line-height: 23.44px;
  margin-right: 10px;
}

.qtable.qtable-dark thead tr th {
  background: rgba(24, 26, 35, 1) !important;
}

.qtable.qtable-dark tbody tr td {
  color: rgba(24, 26, 35, 1) !important;
}

.qtable .qtable-inner-headings {
  font-weight: 400;
  font-size: 14px;
  line-height: 16.41px;
  display: block;
  margin-bottom: 10px;
}

.label {
  display: inline;
  padding: 0.2em 0.6em 0.3em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25em;
  background: rgba(77, 58, 205, 1);
}

.label-default-light {
  background: rgba(241, 239, 255, 1) !important;
  color: rgba(77, 58, 205, 1) !important;
  padding: 0.1em 0.6em 0.1em;
}

.label-default-dark {
  background: rgba(241, 239, 255, 1) !important;
  color: rgba(24, 26, 35, 1) !important;
  padding: 0.1em 0.6em 0.1em;
}

.btn-outline-primary {
  color: rgba(77, 58, 205, 1);
  background-color: transparent;
  background-image: none;
  border-color: rgba(77, 58, 205, 1);
}

.btn-outline-primary:hover img {
  filter: invert(98%) sepia(85%) saturate(0%) hue-rotate(341deg)
    brightness(103%) contrast(102%);
}

.btn-mini {
  padding: 8px !important;
  font-weight: 500 !important;
  font-size: 18px !important;
  border-radius: 15px !important;
  text-transform: none !important;
}

.btn-mini img {
  width: 32px;
}

.btn-mini.btn-icon-bg img {
  background: rgba(241, 239, 255, 1);
  padding: 9px 8px;
  border-radius: 10px;
}

.btn-white,
.btn-white:active,
.btn-white:focus {
  box-shadow: 2px 4px 10px 0px rgba(0, 0, 0, 0.1);
  background: rgba(251, 251, 251, 1);
  color: rgba(24, 26, 35, 1);
}

.btn-white:hover,
.btn-white.active {
  background: linear-gradient(270deg, #000 0%, #000 100%);
  color: #fff !important;
}

.btn-white:hover img,
.btn-white.active img {
  filter: invert(100%) sepia(0%) saturate(19%) hue-rotate(309deg)
    brightness(105%) contrast(105%);
  background: rgba(241, 239, 255, 1);
}

.hr {
  border: 1px solid rgba(77, 58, 205, 1);
  opacity: 1;
}

.fw-700 {
  font-weight: 700 !important;
}

.tbl-transactions th {
  font-weight: 600 !important;
}

.tbl-transactions td {
  font-weight: 400 !important;
  color: rgba(24, 26, 35, 1) !important;
  text-transform: none !important;
  word-wrap: break-word;
  word-break: break-all;
  white-space: normal;
}

.qselect-container {
  border-radius: 10px;
  overflow: hidden;
  background: #fff;
  border: 2px solid rgba(24, 26, 35, 1)
}

.qselect.qselect-active {
  margin: 0px 36px 1px 36px;
}

.qselect-container label {
  border-radius: 10px;
  margin-right: 8px;
  font-weight: 500;
  font-size: 20px;
  line-height: 23.44px;
}

.qselect .css-1nmdiq5-menu {
  position: relative;
  box-shadow: none;
  border-radius: 0px;
  margin: 0px;
}

.qselect .css-13cymwt-control {
  border: 0px;
  border-radius: 0;
  display: none;
}

.qselect .css-1nmdiq5-menu > div {
  max-height: 400px !important;
}

.qselect .css-1nmdiq5-menu > div > div,
.qselect-selected {
  border: 1px solid rgba(212, 212, 212, 1);
  margin: 16px 0px;
  border-radius: 10px;
  padding: 16px;
  cursor: pointer;
}

.qselect-selected {
  margin: 0px;
  margin: 0px 36px 16px 36px;
}

.qselect-selected .qselect-close-icon {
  width: 32px;
  margin-left: 16px;
}

.qselect .css-1nmdiq5-menu > div > div:first-child {
  margin-top: 0px;
}

.qselect .css-1nmdiq5-menu > div > div:last-child {
  margin-bottom: 0px;
}

.qselect-container .qselect-item {
  display: flex;
  align-items: center;
}

.qselect .css-1nmdiq5-menu div[aria-disabled="true"] {
  background: rgba(212, 212, 212, 1);
  color: #000;
}

.qselect-container .qselect-item .qselect-item-label {
  border-radius: 10px;
  margin-right: 8px;
  font-weight: 500;
  font-size: 20px;
  line-height: 23.44px;
}

.qselect-container .qselect-item .qselect-item-text {
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
  text-transform: uppercase;
}

.qselect-container .qselect-item .qselect-item-network {
  margin-left: auto;
}

.qselect-container .qselect-item.unused .qselect-item-network {
  margin-left: 16px !important;
}

.qselect-container .qselect-item.unused .qselect-item-unused {
  margin-left: auto;
}

.qselect-container .qselect-item.unused .qselect-item-unused-msg img {
  width: 22px;
}

.qselect-container .qselect-item.unused .qselect-item-unused-msg span {
  font-size: 18px;
  margin-left: 8px;
}

.qselect-container .qselect-item.unused .qselect-item-unused {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.qselect-container .qselect-item.unused .btn {
  font-size: 18px !important;
  line-height: 21.09px !important;
  font-weight: 400 !important;
  padding: 8px 16px 8px 16px !important;
  margin-left: 16px;
}

.qselect-container .qselect-item.unused .btn:active {
  color: #fff;
}

.qselect #react-select-2-placeholder {
  border-radius: 10px;
  margin-right: 8px;
  font-weight: 500;
  font-size: 20px;
  line-height: 23.44px;
  color: #000;
}

.qselect-placeholder {
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 16px 36px 16px 36px;
}

.qselect-placeholder .icon {
  margin-left: auto;
}

.qselect .css-tr4s17-option,
.qselect .css-tr4s17-option:active,
.qselect .css-tr4s17-option:focus {
  background: #fff;
  color: #000;
  border: 2px solid rgba(24, 26, 35, 1) !important;
}

.qselect .css-d7l1ni-option,
.qselect .css-tr4s17-option:hover {
  background: rgba(241, 239, 255, 1);
  color: #000;
}

.counter-step-container {
  display: flex;
  padding: 16px 36px 16px 36px;
  align-items: center;
}

.counter-step-container .csc-label {
  font-weight: 500;
  font-size: 20px;
  line-height: 23.44px;
}

.qcounter {
  display: flex;
  align-items: center;
}

.qcounter img {
  width: 36px;
  cursor: pointer;
}

.qcounter .qcounter-label {
  font-size: 26px;
  font-weight: 700;
  line-height: 30.47px;
  position: relative;
  top: 1px;
  margin: 0px 16px;
}

.qcounter.qcounter-disabled .qcounter-label {
  color: rgba(212, 212, 212, 1);
}

.qcounter.qcounter-disabled img {
  filter: invert(94%) sepia(9%) saturate(21%) hue-rotate(327deg) brightness(92%)
    contrast(85%);
}

.qcounter img.disabled {
  filter: invert(94%) sepia(9%) saturate(21%) hue-rotate(327deg) brightness(92%)
    contrast(85%);
}

.cr-deopsite .exchange-rate {
  padding: 16px 36px 16px 36px;
}

.cr-deopsite .exchange-rate img {
  width: 28px;
}

.cr-deopsite .exchange-rate label {
  font-weight: 500;
  font-size: 20px;
  line-height: 23.44px;
  margin-left: 16px;
}

.cr-deopsite .exchange-rate span {
  font-weight: 500;
  font-size: 20px;
  line-height: 23.44px;
}

.cr-deopsite .deposit {
  border: 1px solid rgba(77, 58, 205, 1);
  padding: 16px 36px 16px 36px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #fff;
}

.cr-deopsite .deposit label {
  font-size: 26px;
  font-weight: 700;
  line-height: 30.47px;
}

.cr-deopsite .deposit .deposit-right {
  display: flex;
  align-items: center;
}

.cr-deopsite .deposit .deposit-right img {
  width: 28px;
}

.cr-deopsite .deposit .deposit-right label {
  color: rgba(77, 58, 205, 1);
  margin-left: 16px;
}

.cr-deopsite .sk label {
  font-weight: 700;
  font-size: 26px;
  line-height: 30.47px;
}

.cr-deopsite .card-white-normal {
  padding: 15px !important;
}

.cr-deopsite .sk span {
  font-weight: 500;
  font-size: 20px;
  line-height: 23.44px;
}

/* dropdowns */
.dropdown-menu {
  background: var(--white);
  box-shadow: 2px 1px 16px var(--gray);
  border-radius: 5px;
  transition: 0.3s;
  z-index: 9999;
  border-radius: 10px;
  /* overflow: hidden; */
  border: 0px;
  top: 60% !important;
  padding: 0;
}

.h_language .dropdown-menu {
  top: 40% !important;
}

.dropdown-menu:before {
  position: absolute;
  top: -7px;
  right: 10%;
  display: inline-block;
  border-right: 7px solid transparent;
  border-bottom: 7px solid #fff;
  border-left: 7px solid transparent;
  border-bottom-color: rgba(0, 0, 0, 0.01);
  content: "";
}

.dropdown-menu:after {
  position: absolute;
  top: -6px;
  right: 10%;
  display: inline-block;
  border-right: 6px solid transparent;
  border-bottom: 6px solid #ffffff;
  border-left: 6px solid transparent;
  content: "";
}

.dropdown-item:focus,
.dropdown-item:hover,
.dropdown-menu ul li:hover {
  background-color: transparent !important;
}

.dropdown-menu ul li {
  border-bottom: 2px solid rgba(241, 239, 255, 1);
  transition: 0.3s;
  min-width: 200px;
}

.dropdown-menu ul li:last-child {
  border-bottom: 0px solid rgba(241, 239, 255, 1);
}

.dropdown-menu ul li a {
  color: rgba(24, 26, 35, 1);
  padding: 10px;
  display: block;
  text-align: left;
}

.dropdown-menu ul li:hover a {
  color: rgba(var(--bs-link-color-rgb), var(--bs-link-opacity, 1));
}

.dropdown-menu ul li:hover {
  background: #f3f3f3;
  cursor: pointer;
}

.dropdown-menu ul li label {
  margin-left: 10px !important;
}

.dropdown-item.active,
.dropdown-item:active {
  background-color: transparent !important;
  color: rgba(var(--bs-link-color-rgb), var(--bs-link-opacity, 1));
}

.presale {
  background: #010b17;
  position: relative;
  z-index: 1;
}
.presale .container {
  z-index: 99;
  position: relative;
}

.presale .presale-rect-top {
  background: radial-gradient(
    61.87% 61.87% at 50% 38.13%,
    #dcf2f4 0%,
    rgba(41, 172, 227, 0) 100%
  );
  width: 100%;
  height: 173px;
  position: absolute;
  top: 0;
}

.presale .presale-rect-top .inner-view {
  background: radial-gradient(
    61.87% 61.87% at 50% 38.13%,
    #a9d2fb 0%,
    rgba(179, 226, 247, 0.12) 100%
  );
  width: 100%;
  height: 15px;
  position: relative;
  top: 56%;
}

.presale::before {
  content: "";
  width: 100%;
  height: 70vh;
  background: url(/static/media/img-presale-background.4b7ffb84.jpg);
  display: block;
  position: absolute;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  top: 0;
}

.presale::after {
  content: "";
  width: 100%;
  height: 91px;
  background: linear-gradient(
    0deg,
    #000000 21.22%,
    rgba(99, 209, 255, 0.3) 91.55%
  );
  display: block;
  top: 500px;
  position: absolute;
}

.presale .white-card {
  background: rgba(241, 239, 255, 0.9);
  border-radius: 20px;
  padding: 8px;
  text-align: center;
}

.presale .white-card h1 {
  color: rgba(77, 58, 205, 1);
  font-size: 48px;
  font-weight: 700;
  margin-bottom: 8px;
}

.presale-counter {
  background: #fff;
  border: 2.23px solid rgba(77, 58, 205, 1);
  border-radius: 13.38px;
  padding: 4px;
  margin: 0px;
  list-style: none;
  display: inline-block;
}

.presale-counter li {
  display: inline-block;
  background: linear-gradient(0deg, #f1efff, #f1efff),
    linear-gradient(0deg, rgba(255, 255, 255, 0.3), rgba(255, 255, 255, 0.3));
  padding: 17.83px 13.38px 17.83px 13.38px;
  border-radius: 2.23px;
  border-left: 1.11px solid rgba(255, 255, 255, 0.3);
  box-shadow: 4.458333492279053px 4.458333492279053px 4.458333492279053px -2.2291667461395264px
    rgba(255, 255, 255, 0.25) inset;
  box-shadow: -4.458333492279053px -4.458333492279053px 11.145833969116211px 0px
    rgba(0, 0, 0, 0.09) inset;
  font-weight: 700;
  font-size: 60px;
  text-align: center;
  line-height: 50px;
  color: rgba(77, 58, 205, 1);
  min-width: 62px;
}

.presale-counter li.non-digit {
  min-width: 40px; /* Reset min-width */
}
.presale-counter li:first-child {
  border-radius: 8.92px 2.23px 2.23px 8.92px;
}

.presale-counter li:last-child {
  border-radius: 2.23px 8.92px 8.92px 2.23px;
}

.blue-link-underlined {
  color: rgba(77, 58, 205, 1);
  font-size: 18px;
  line-height: 21.09px;
  font-weight: 500;
  text-decoration: underline !important;
  margin-top: 8px;
  display: inline-block;
}

.presale .card-blue {
  background: rgba(77, 58, 205, 1);
  border-radius: 30px;
  padding: 16px 36px 16px 36px;
  color: #fff;
}

.presale .card-blue h2 {
  font-weight: 700;
  font-size: 48px;
  line-height: 56.25px;
  text-transform: uppercase;
  display: flex;
  justify-content: center;
}

.presale .ticket-remaining-section {
  display: flex;
  margin-top: 16px;
  border: 2px solid rgba(241, 239, 255, 1);
  padding: 16px;
  border-radius: 26px;
}

.presale .ticket-remaining-section .remaining {
  text-align: center;
  background: rgba(241, 239, 255, 1);
  border-radius: 18.5px;
  padding: 14.8px;
}

.presale .ticket-remaining-section .remaining .presale-counter li {
  font-size: 34px !important;
  line-height: 30px !important;
}

.presale .ticket-remaining-section .remaining h3 {
  color: rgba(0, 0, 0, 1);
  font-size: 33.3px;
  line-height: 39.02px;
  font-weight: 400;
  margin-top: 29.6px;
  text-transform: uppercase;
}

.tr-left {
  width: 56%;
  text-align: center;
}

.tr-left .inp-wapper {
  background: rgba(241, 239, 255, 1);
  border-radius: 20px;
  text-align: left;
  padding: 8px;
  display: flex;
  width: 100%;
}

.tr-left .inp-wapper label {
  font-size: 20px;
  font-weight: 500;
  height: 100%;
  line-height: 23.44px; 
  color: rgba(77, 58, 205, 1);
  text-align: center;
  margin: 0 16px;
}

/* .tr-left .inp-wapper input{
    border: 1px solid rgba(77, 58, 205, 1);
    width: 80%;
    margin: 0 16px;
    border-radius: 20px;
    color: rgba(77, 58, 205, 1);
    font-size: 32px;
    line-height: 46px;
    font-weight: 500;
    padding: 0px 16px;
} */

.tr-left .inp-wapper .qstepview {
  width: 80%;
}

.tr-left button {
  margin-top: 8px;
}

.tr-left p {
  font-size: 14px;
  line-height: 16.41px;
  font-weight: 400;
}

.tr-vline {
  border-right: 2px solid rgba(241, 239, 255, 1);
  margin: 0 4%;
}

.tr-right {
  width: 36%;
}

.btn-bordered,
.btn-bordered:hover,
.btn-bordered:focus,
.btn-bordered:active {
  border: 2px solid rgba(241, 239, 255, 1);
}

.ticket-info-section {
  border: 1px solid rgba(219, 183, 252, 1);
  display: flex;
  margin-top: 32px;
  border-radius: 20px;
  padding: 8px 64px 8px 64px;
}

.ticket-info-section div {
  text-align: center;
  padding: 8px 0px 8px 0px;
  width: 33%;
}

.ticket-info-section div strong {
  font-size: 18px;
  line-height: 21.09px;
  font-weight: 700;
  margin: 8px 0px;
}

.ticket-info-section div label {
  font-size: 18px;
  line-height: 21.09px;
  font-weight: 400;
  margin: 8px 0px;
}

.ticket-info-section div img {
  width: 16px;
  margin-right: 4px;
}

.ticket-info-section .divider {
  border-right: 2px solid rgba(241, 239, 255, 1);
  width: auto !important;
}

.white-link-underlined {
  color: #fff;
  font-size: 21px;
  font-weight: 700;
  display: inline-block;
}

.ticket-info-section .last-item {
  width: 67%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.tbl-border {
  border-radius: 10px;
  border: 1px solid #fff;
  overflow: hidden;
}

.form-check-input {
  margin-top: 0px;
}

.presale .top-prices {
  background: radial-gradient(
    47.92% 47.92% at 50% 47.45%,
    #954ecc 0%,
    rgba(149, 78, 204, 0) 100%
  );
  color: #fff;
  text-align: center;
}

.presale .top-prices p {
  font-weight: 400;
  font-size: 18px;
  line-height: 21.09px;
}

.presale .top-prices h1 {
  font-weight: 700;
  font-size: 48px;
  line-height: 56.25px;
  text-transform: uppercase;
}

.presale .top-prices .card-blue {
  padding: 36px;
  border-radius: 20px;
  background: linear-gradient(270deg, #4d3acd 0%, #514f93 100%);
  margin: 16px 0px;
}

.hl-black {
  border: 2px solid rgb(60, 60, 60);
  margin: 16px 0px;
}

.hl-pink {
  border: 2px solid rgba(219, 183, 252, 1);
  margin: 16px 0px;
}

.hl-pink-half {
  border: 2px solid rgba(219, 183, 252, 1);
  width: 50%;
  margin: 16px auto;
}

.presale .card-blue h3 {
  margin: 24px 0px;
}

.qstepview {
  border: 1px solid rgba(77, 58, 205, 1);
  margin: 0 16px;
  border-radius: 20px;
  background: #fff;
  display: flex;
}

.qstepview input {
  color: rgba(77, 58, 205, 1);
  font-size: 32px;
  border-radius: 20px;
  line-height: 46px;
  font-weight: 500;
  padding: 0px 16px;
  border: 0px;
  width: 80%;
}

.qstepview input:focus,
.qstepview input[readonly],
.qstepview input:active {
  outline: none;
  border: 0px !important;
  background: transparent !important;
}

.qstepview div img {
  width: 20px;
  cursor: pointer;
}

.presale .windfall-logo {
  position: relative;
  z-index: 99;
}

.presale .windfall-logo img {
  width: 442px;
}

.presale .drag-to-logo {
  position: relative;
  top: -15px;
}

.presale .purchase-process {
  border: 1px solid rgba(24, 26, 35, 1);
  background: #fff;
  padding: 32px;
  border-radius: 20px;
  margin-top: 32px;
}

.presale .purchase-process h2 {
  color: rgba(24, 26, 35, 1);
  text-transform: initial;
  font-size: 32px;
  line-height: 48px;
  font-weight: 700;
}

.presale-modal .modal-lg {
  max-width: 60% !important;
}

.presale-modal .modal-content {
  border-radius: 20px;
}

.presale-modal .modal-body {
  padding: 32px 64px;
}

.presale-modal .modal-title {
  font-weight: 500;
  font-size: 32px;
  line-height: 46px;
  color: rgba(77, 58, 205, 1);
  text-align: center;
  padding-top: 0px;
  width: 100%;
}

.presale-modal .btn-close {
  padding: 20px;
}

.presale-modal table th,
.presale-modal table td {
  text-transform: initial !important;
}

.presale-modal table th {
  font-weight: 500;
}

.mw-400 {
  min-width: 400px;
}

.fw-400 {
  font-weight: 400 !important;
}

.link-black {
  color: #000;
  font-weight: 500;
  font-size: 18px;
  line-height: 21.09px;
  text-decoration: underline !important;
  padding: 10px;
  cursor: pointer;
}

.purchase-process {
  color: #000;
}

.purchase-process p {
  font-weight: 500;
  font-size: 20px;
  line-height: 23.44px;
  color: #000;
}

.purchase-process .confirm-info {
  display: flex;
  padding: 16px 16px 16px 16px;
  justify-content: space-between;
  border-radius: 10px;
  border: 1px solid rgba(212, 212, 212, 1);
}

.purchase-process .confirm-info .cnt {
  text-align: center;
  width: 50%;
}

.purchase-process .confirm-info .hdivider {
  border: 1px solid rgba(219, 183, 252, 1);
}

/* .purchase-process .confirm-info .q-symbol{
    background: rgba(241, 239, 255, 1);
    border-radius: 10px;
    margin: 0px 8px;
    padding: 5px 10px;
} */

.purchase-process .confirm-info h4 {
  font-weight: 500;
  font-size: 20px;
  line-height: 23.44px;
  margin: 8px 0px;
}

.purchase-process .confirm-info h5 {
  font-weight: 700;
  font-size: 18px;
  line-height: 24.51px;
  margin: 8px 0px;
}

.purchase-process .confirm-info span {
  font-weight: 400;
  font-size: 18px;
  line-height: 21.09px;
  margin: 8px 0px;
}

.bordered-info {
  display: flex;
  justify-content: space-evenly;
}

.bordered-info div {
  border: 1px solid rgba(219, 183, 252, 1);
  padding: 16px;
  border-radius: 10px;
  font-size: 20px;
  font-weight: 500;
  line-height: 23.44px;
  width: 100% !important;
  margin: 0px 16px;
}

.presale .page-link {
  background: transparent;
  color: #fff;
  border: 1px solid rgba(251, 251, 251, 1);
  border-radius: 5px;
  padding: 8px 16px;
  font-size: 18px;
  line-height: 21.09px;
  font-weight: 400;
  margin: 0 10px;
}

.presale .page-link.active,
.presale .page-link:hover {
  background: linear-gradient(180deg, #4d3acd 0%, #292734 100%);
  border-color: #fff !important;
  color: #fff !important;
}

.pagination {
  display: flex;
  justify-content: center;
}

.w-20 {
  width: 20px !important;
}

.w-25 {
  width: 25px !important;
}

.w-50 {
  width: 50px !important;
}

.mr-10 {
  margin-right: 10px;
}

.w-30 {
  width: 30px !important;
}

.w-40 {
  width: 40px !important;
}

.w-100 {
  width: 100px !important;
}

.img-filter-warning {
  filter: invert(63%) sepia(53%) saturate(4718%) hue-rotate(348deg)
    brightness(98%) contrast(95%);
}

.Toastify .Toastify__toast-icon svg {
  fill: var(--mainBlue) !important;
}

.Toastify__toast-theme--light {
  background: #fff;
}

.Toastify__progress-bar--success {
  background: var(--mainBlue) !important;
}

.form-check-input:checked {
  background-color: #4d3acd;
  border-color: #fff;
}

.form-check-input:focus {
  outline: none;
  box-shadow: none;
  border-color: #fff;
}

.text-primary {
  color: rgba(77, 58, 205, 1) !important;
}

.bg-transp {
  background: transparent !important;
  box-shadow: none !important;
}

.text-gray {
  color: #a5a5a5 !important;
}

.divider-primary {
  border: 1px solid rgba(77, 58, 205, 1);
}

.mx-16 {
  margin: 0px 16px;
}

.my-16 {
  margin: 16px 0px;
}

.w-150 {
  width: 150px !important;
}

.labeled-radio:has(input:checked) {
  border: 1px solid rgba(77, 58, 205, 1);
  background: rgba(241, 239, 255, 1);
  color: rgba(77, 58, 205, 1);
}

.labeled-radio {
  border: 1px solid rgba(24, 26, 35, 1);
  color: rgba(24, 26, 35, 1);
  display: inline-block;
  padding: 8px;
  border-radius: 5px;
  text-transform: uppercase;
  font-weight: 400;
  font-size: 18px;
  line-height: 21.09px;
  cursor: pointer;
  margin: 0 8px;
}

.labeled-radio label {
  cursor: pointer;
}

.labeled-radio input {
  -webkit-appearance: none;
          appearance: none;
}

.game-goxes {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 80%;
  margin: 0 auto;
  margin-top: 50px;
}

.game-goxes .game-item {
  width: 300px;
  min-height: 260px;
  background: var(--white);
  border: 2.89778px solid var(--white);
  box-shadow: 1.44889px 2.89778px 7.24444px rgba(0, 0, 0, 0.1);
  border-radius: 47.09px;
  text-align: center;
  margin: 15px 0 15px 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
}

.game-goxes .game-item .img_active_main {
  width: 100%;
  display: block;
}

.game-goxes .game-item .img_active_main .img {
  width: 268px;
  height: 153px;
}

.game-goxes .game-item h4 {
  margin-top: 5px;
  font-weight: 700;
  font-size: 18.84px;
  line-height: 22.07px;
  color: rgba(77, 58, 205, 1);
}

.lst li {
  margin: 5px 0px;
}

.wlp-bg-yellow {
  background: #e6e64c;
}

.wlp-bg-orange {
  background: #ffcc01;
}

.wlp-bg-green {
  background: #99cc00;
}

.wlp-bg-light-blue {
  background: #00ccff;
}

.wlp-bg-blue {
  background: #0c47f7;
  color: #fff;
}

.wwl-aligned th:first-child,
.wwl-aligned td:first-child {
  text-align: center !important;
}

.wwl-aligned th:nth-child(2),
.wwl-aligned td:nth-child(2) {
  text-align: right !important;
}

.wwl-aligned th:nth-child(3),
.wwl-aligned td:nth-child(3) {
  text-align: right !important;
}

.wwl-aligned th:nth-child(4),
.wwl-aligned td:nth-child(4) {
  text-align: right !important;
}

.wwl-aligned td {
  padding: 6px 14px !important;
}

.w-100p {
  width: 100% !important;
}

.fs-1rem {
  font-size: 1rem !important;
}

.alert p.ml-30 {
  margin: 0px 0px 0px 30px !important;
}

.p-50 {
  padding: 50px !important;
}

.nav-item.dropdown:hover .dropdown-menu {
  display: block;
}

.nav-item.dropdown .dropdown-menu {
  top: 95% !important;
  padding: 0.5rem 0;
}

.nav-item.dropdown .dropdown-menu a {
  padding: 0.5rem 2rem;
  color: #000;
  display: block;
  text-decoration: none !important;
  margin: 0 !important;
  font-size: 18px !important;
}

.nav-item.dropdown .dropdown-menu a:hover {
  color: var(--mainBlue) !important;
  background: #f8f8f8;
}

.provably-border {
  border: 1px solid var(--mainBlue);
  border-radius: 5px;
  display: flex;
  align-items: center;
  padding: 16px;
  min-height: 74px;
}
.provably-bottom {
  min-height: 183px;
  border-radius: 5px;
  background-color: var(--white);
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding: 32px;
}

.equal-width-three a {
  width: 30%;
}

.pagination-dark .page-item {
  margin: 0 8px;
}

.pagination-dark .page-item .page-link {
  color: rgba(24, 26, 35, 1) !important;
  background: transparent !important;
  border-color: #181a23 !important;
  border-radius: 5px !important;
  font-size: 18px !important;
  font-weight: 400 !important;
}

.pagination-dark .page-item .page-link.active,
.pagination-dark .page-item .page-link:hover {
  color: #4d3acd !important;
  border-color: #4d3acd !important;
}

.form-switch .form-check-input {
  width: 2.5rem !important;
  height: 1.5rem !important;
  margin-right: 10px;
}

.form-check-input:focus,
.form-check-input:active {
  border-color: var(--bs-border-color) !important;
}

.form-check {
  margin-bottom: 0px !important;
}

.card-profile .profile-info label.m-0,
.m-0 {
  margin: 0px !important;
}

a,
a:hover {
  cursor: pointer !important;
}

/* games css */

.windfall .lottery_windfall {
  background-image: url(/static/media/img-presale-background.4b7ffb84.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  min-height: 525px;
}

.windfall .lottery_windfall > .lottert_gradient {
  position: absolute;
  background: radial-gradient(
    61.87% 61.87% at 50% 38.13%,
    rgba(196, 123, 253, 0.73) 0%,
    rgba(229, 199, 253, 0) 100%
  );
  height: 173px;
  background-size: cover;
  background-repeat: no-repeat;
  width: 100vw;
  min-height: 173px;
  background-position: center;
}

.windfall .lottery_windfall .windfall_image > img {
  z-index: 10;
  width: 70%;
}

.windfall .lottery_Remaining {
  /* position: absolute; */
  font-weight: 700;
  font-size: 32px;
  text-align: center;
  bottom: 0;
  color: #f1efff;
}

.windfall .lottery_countdown {
  position: absolute;
  top: 33px;
  left: 14px;
  color: var(--mainBlue);
}

.windfall .lottery-pool-section {
  background: linear-gradient(0deg, #010b17, #904cc5, #13132d);
}

/* .lottery-offers{
    max-width: 1121px;
    margin: auto;
    } */

.windfall .lottery-offers > div > h5 {
  color: #f1efff;
  font-weight: 500;
  font-size: 32px;
}

.windfall .lottery-offers > div > p {
  color: #f1efff;
  font-weight: 400;
  font-size: 18px;
}

.windfall .lottery-pool {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.windfall .lottery-pool-ticket {
  padding-top: 32px;
  border-style: solid;
  border-width: 4px 0px 0px 0px;
  border-color: #dbb7fc;
}

.windfall .lottery-pool > h3 {
  color: #f1efff;
  font-weight: 700;
  font-size: 48px;
}

.windfall .lottery-pool-button {
  min-height: 43px;
  background-color: #fbfbfb;
  border-radius: 100px;
  padding: 2px;
  grid-gap: 4px;
  gap: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid var(--mainBlue);
  flex-shrink: 1;
}

.windfall .lottery-pool-button > a > button {
  color: var(--mainBlue);
  font-weight: 400;
  font-size: 20px;
  text-align: center;
}

.windfall .lottery-pool-button .button_active {
  max-width: 211px;
  background: var(--mainBlue);
  padding: 10px 15px;
  border-radius: 100px;
  font-weight: 500;
  font-size: 18px;
  color: #fbfbfb;
}

.windfall .lottery-pool > p {
  color: #f1efff;
  font-weight: 400;
  font-size: 18px;
  text-align: center;
}

.windfall .create-lottery-pool {
  background: var(--mainBlue);
  border-radius: 30px;
  padding: 16px;
  margin: 32px auto;
}

.windfall .create-lottery-section {
  border-radius: 20px;
  border: 2px solid #f1efff;
  padding: 16px;
  background-color: #6658ce;
}

.windfall .create-lottery-section > div:nth-child(n) {
  margin-top: 16px;
}

.windfall .create-lottery-box {
  border: 1px solid #dbb7fc;
  border-radius: 10px;
  color: #f1efff;
}

.windfall .create-lottery-box > div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
}

.windfall .create-lottery-box > div > label:nth-child(1) {
  font-weight: 400;
  font-size: 18px;
}
.windfall .create-lottery-box > div > label:nth-child(2) {
  font-weight: 700;
  font-size: 18px;
  word-break: break-all;
}

.windfall .create-lottery-button {
  width: 100%;
  border: 2px solid #f1efff;
  border-radius: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 700;
  font-size: 18px;
  color: var(--white);
  background: #dbb7fc;
}

.windfall .create-lottery-section .nexus-box-game {
  width: 100%;
  height: 60px;
  border: 2px solid var(--mainBlue);
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  font-size: 18px;
  color: #181a23;
  background: var(--white);
  text-decoration: underline;
}

.windfall .create-lottery-section .subtext {
  font-weight: 400;
  font-size: 14px;
  text-align: center;
  color: var(--white);
}

.windfall .create-lottery-section .cost-lottery-box {
  border: 1px solid #dbb7fc;
  border-radius: 20px;
  display: flex;
  justify-content: space-around;
  color: #f1efff;
  font-weight: 700;
  font-size: 18px;
}

.windfall .create-lottery-section .nexusbox-pool {
  border: 1px solid var(--black);
  border-radius: 20px;
  background-color: var(--white);
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}

.windfall .create-lottery-section .nexusbox-pool > h4 {
  font-weight: 700;
  font-size: 32px;
  color: var(--black);
}

.windfall .create-lottery-section .nexusbox-pool > button {
  width: 40%;
  height: 80px;
  border-radius: 50px;
  background: var(--gradientBlue);
  font-weight: 600;
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
}

.windfall .create-lottery-section .nexusbox-pool > p {
  font-size: 18px;
  font-weight: 500;
  color: var(--black);
  text-decoration: underline;
}

.windfall .ticket-qty-section {
  border: 1px solid var(--mainBlue);
  border-radius: 10px;
  display: flex;
  justify-content: space-between;
  background: var(--white);
  min-height: 80px;
}

.windfall .ticket-qty-section .ticket-qty {
  display: flex;
  justify-content: space-around;
  align-items: center;
  /* flex-grow: 1; */
}

.windfall .ticket-qty-section .ticket-qty > div {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.windfall .ticket-qty-section .ticket-qty > div > label:nth-child(1) {
  font-weight: 500;
  font-size: 20px;
  color: var(--mainBlue);
}

.windfall .ticket-qty-section .ticket-qty > div > label:nth-child(2) {
  font-weight: 400;
  font-size: 16px;
}

.windfall .ticket-qty-section .ticket-qty > div > p:nth-child(3) {
  font-weight: 500;
  font-size: 32px;
  color: var(--mainBlue);
  line-height: 35px;
}

.windfall .ticket-qty input {
  color: var(--mainBlue);
  width: 15%;
  height: 44px;
  border: 1px solid #f1efff;
  border-radius: 10px;
  outline: none;
  font-weight: 500;
  font-size: 32px;
  text-align: center;
  color: var(--mainBlue);
}

.ticket-qty input:focus,
.ticket-qty input[readonly],
.ticket-qty input:active {
  outline: none;
  border: 1px solid #f1efff !important;
  background: transparent !important;
}

.windfall .ticket-qty-section .ticket-qty-img {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 55px;
  background: #f1efff;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}

.windfall .ticket-qty-section .ticket-qty-img > img {
  padding: 2px 4px 2px 4px;
  cursor: pointer;
}

.windfall .create-lottery-section .checkbox-section > input {
  width: 30px;
  height: 30px;
  border-radius: 10px;
}

.windfall .create-lottery-section .checkbox-section > label {
  font-weight: 400;
  font-size: 18px;
  color: var(--white);
}

.windfall .pools {
  border-top: 4px solid #dbb7fc;
}

.windfall .pools .pool-h1 {
  font-size: 48px;
  font-weight: 700;
  color: var(--white);
}

.windfall .pools .pool-search {
  height: 56px;
  border-radius: 10px;
  background-color: var(--lightPurpal);
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.windfall .pools .pool-search > input {
  border: none;
  outline: none;
  font-weight: 400;
  font-size: 18px;
  background-color: var(--lightPurpal);
}

.windfall .pools-container {
  border-radius: 20px;
  background-color: var(--white);
}
.windfall .pools-container > div > div > p {
  font-weight: 400;
  font-size: 18px;
}
.windfall .pools-container > div > div > label {
  font-weight: 400;
  font-size: 18px;
}

.windfall .pools-container > div > p {
  font-weight: 500;
  font-size: 20px;
}
.windfall .pools-container > div > label {
  font-weight: 400;
  font-size: 18px;
}
.windfall .pools-container > div > div:nth-child(1) > p {
  color: var(--mainBlue);
}

.windfall .pools-container > div > div > p:nth-child(1) {
  color: var(--mainBlue);
}
.windfall .pools-container > div > div > button {
  border: 2px solid var(--lightPurpal);
  border-radius: 50px;
  background: var(--gradientBlue);
  font-weight: 500;
  font-size: 16px;
  color: var(--white);
}
.windfall .pools-container .pools-container-box {
  padding: 32px 64px;
}
.windfall .pools .pools-box-container {
  border-radius: 20px;
  background-color: var(--white);
}
.windfall .pools .pools-box {
  padding: 32px 64px;
}

.windfall .pools .pool-purchase {
  border: 1px solid var(--mainBlue);
  border-radius: 20px;
  padding: 16px;
}

.windfall .purchase-ticket {
  background-color: var(--lightPurpal);
  padding: 16px;
  border-radius: 10px;
}

.windfall .purchase-ticket .min-purchase-card {
  background-color: var(--white);
  border: 1px solid var(--pink);
  border-radius: 20px;
  display: flex;
  justify-content: space-around;
}

.windfall .purchase-ticket .min-purchase-card > div > label {
  font-weight: 700;
  font-size: 18px;
  color: var(--mainBlue);
}

.windfall .min-purchase-card {
  border: 1px solid var(--pink);
  border-radius: 20px;
  display: flex;
  justify-content: space-around;
}

.windfall .min-purchase-card > div > label {
  font-weight: 700;
  font-size: 18px;
  color: var(--white);
  word-break: break-all;
}

.windfall .purchase-ticket .confirm-purchase {
  background-color: var(--white);
  border: 1px solid var(--black);
  border-radius: 20px;
}
.windfall .confirm-purchase .purchase-game {
  display: flex;
  justify-content: space-between;
}
.windfall .confirm-purchase .purchase-game > div {
  flex: 50% 1;
  border: 1px solid var(--pink);
  border-radius: 10px;
  display: flex;
  justify-content: center;
  font-weight: 500;
  font-size: 20px;
}

.windfall .purchase-ticket .confirm-purchase > h5 {
  font-weight: 500;
  font-size: 20px;
  text-align: center;
}

.windfall .purchase-ticket .confirm-purchase > h4 {
  font-weight: 700;
  font-size: 32px;
  text-align: center;
}

.windfall .confirm-purchase .confirm-purchase-items {
  border: 1px solid var(--pink);
  border-radius: 10px;
}

.windfall .confirm-purchase .purchase-items {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 50% 1;
}

.windfall .confirm-purchase .purchase-items > label {
  font-weight: 500;
  font-size: 20px;
}
.windfall .confirm-purchase .purchase-items > p {
  font-weight: 400;
  font-size: 18px;
}
.windfall .confirm-purchase .purchase-items > h5 {
  font-weight: 700;
  font-size: 18px;
}

.windfall .purchase-ticket .confirm-purchase > div > div > button {
  width: 40%;
  height: 80px;
  border-radius: 50px;
  background: var(--gradientBlue);
  font-weight: 600;
  color: #ffffff;
}

.windfall .purchase-ticket .confirm-purchase > div > p {
  font-size: 18px;
  font-weight: 500;
  color: var(--black);
  text-decoration: underline;
}

.windfall .pools-box > div > div:nth-child(1) > p {
  font-size: 20px;
  font-weight: 500;
  color: var(--mainBlue);
}

.windfall .pools-container .pool-members {
  border: 1px solid var(--mainBlue);
  border-radius: 20px;
  padding: 16px;
}

.windfall .pool-members .pool-members-box {
  background: var(--gradientBlue);
  border-radius: 10px;
  padding: 16px;
}

.windfall .pool-members .pool-members-box .pool-members-card {
  background-color: var(--white);
  border-radius: 20px;
  padding: 8px 32px 8px 32px;
}

.windfall .pool-members-box .pool-members-profile {
  background-color: var(--white);
  border: 0.98px solid var(--lightPurpal);
  border-radius: 23px;
  padding: 48px 36px;
}

.windfall .pool-members-box .pool-members-profile > div > label {
  font-weight: 500;
  font-size: 32px;
}

.windfall .pool-members-box .pool-members-profile > div > p {
  font-weight: 400;
  font-size: 18px;
  color: var(--mainBlue);
}

.windfall .pool-members-card > div > label {
  font-weight: 400;
  font-size: 18px;
  color: var(--mainBlue);
}

.windfall .pool-members-compact .compact-id {
  background-color: var(--lightPurpal);
  border-radius: 5px;
}

.windfall .circle-status {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: #24ff00;
}

.windfall .pool-members-compact .pool-compact {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
}

.windfall .pool-members-compact .pool-compact > div {
  flex: 20% 1;
}

.windfall .nav-tabs {
  border-bottom: 0px !important;
}

.windfall .pools .page-link {
  background: transparent;
  color: #fff;
  border: 1px solid rgba(251, 251, 251, 1);
  border-radius: 5px;
  padding: 8px 16px;
  font-size: 18px;
  font-weight: 400;
  margin: 0 5px !important;
}
.windfall .pools-container-bg .page-link {
  background: var(--white);
  color: #fff;
  border: 1px solid rgba(251, 251, 251, 1);
  border-radius: 5px;
  padding: 8px 16px;
  font-size: 18px;
  font-weight: 400;
  margin: 0 5px !important;
}

.pools .page-link.active,
.pools .page-link:hover {
  background-color: #dbb7fc;
  border-color: var(--white) !important;
  color: var(--white) !important;
}

.windfall .page-link-circlewhite > div {
  width: 20px;
  height: 20px;
  border: 5px solid var(--lightPurpal);
  border-radius: 50%;
  margin: 0px 5px;
}

.windfall .pools .page-link-circlewhite {
  background: transparent;
  display: flex;
  justify-content: space-around;
}

.pools .page-link-circlewhite > div.active,
.pools .page-link-circlewhite > div:hover {
  width: 20px;
  height: 20px;
  background-color: var(--lightPurpal);
  border-radius: 50%;
}

.windfall .pools .page-link-blue {
  color: var(--mainBlue);
  border: 1px solid var(--mainBlue);
  border-radius: 5px;
  padding: 8px 16px;
  font-size: 18px;
  font-weight: 400;
  margin: 0 5px !important;
}

.windfall .pools .page-link-circleblue {
  background: transparent;
  display: flex;
  justify-content: space-around;
}

.windfall .pools .page-link-circleblue > div {
  width: 20px;
  height: 20px;
  border: 5px solid var(--mainBlue);
  border-radius: 50%;
  margin: 0px 5px;
}

.pools .page-link-circleblue > div.active,
.pools .page-link-circleblue > div:hover {
  width: 20px;
  height: 20px;
  background-color: var(--mainBlue);
  border-radius: 50%;
}

.windfall .pools-container-bg {
  background-color: var(--lightPurpal);
}

.windfall .pools-container-bg .pool-compact-bg {
  background-color: var(--mainBlue);
  padding: 10px;
  border-radius: 10px;
}

.windfall .pools-container-bg .page-link-blue {
  color: #fff;
  border: 1px solid rgba(251, 251, 251, 1);
  border-radius: 5px;
  padding: 8px 16px;
  font-size: 18px;
  line-height: 21.09px;
  font-weight: 400;
  margin: 0 5px !important;
}

.windfall .detail-view {
  padding-right: 10px;
}

.windfall .pools-container-bg .page-link-circleblue {
  background: transparent;
  display: flex;
  justify-content: space-around;
}

.windfall .pools-container-bg .page-link-circleblue > div {
  width: 20px;
  height: 20px;
  border: 5px solid var(--lightPurpal);
  border-radius: 50%;
  margin: 0px 5px;
}

.pools-container-bg .page-link-circleblue > div.active,
.pools-container-bg .page-link-circleblue > div:hover {
  width: 20px;
  height: 20px;
  background-color: var(--lightPurpal);
  border-radius: 50%;
}

/*-----------------------------------------------------------------------------------------------------------------------------------*/

.trimorra {
  background: var(--gradientBlue);
}

.jackpot-payout-section {
  background: linear-gradient(
    90deg,
    #f7d62f -3.09%,
    #f6cf2d -1.34%,
    #f6bb28 1.29%,
    #f59c20 3.92%,
    #f59920 4.8%,
    #d47e34 8.3%,
    #a65853 11.81%,
    #c6723e 16.19%,
    #f59920 20.58%,
    #f6b827 24.96%,
    #f7d62f 28.47%,
    #f6cf2d 31.97%,
    #f6bb28 36.36%,
    #f59c20 41.62%,
    #f59920 42.5%,
    #f59c20 47.76%,
    #f5a623 51.26%,
    #f6b827 55.65%,
    #f6d02d 59.15%,
    #f7d62f 60.03%,
    #f6bb28 64.41%,
    #f59920 69.67%,
    #d47e34 73.18%,
    #a65853 77.56%,
    #c6723e 80.19%,
    #f59920 84.58%
  );
  height: -webkit-max-content;
  height: max-content;
  border-radius: 20px;
  padding: 5px;
}

.jackpot-payout-box {
  background: linear-gradient(
    180deg,
    #6819a3 2.44%,
    #5d1394 10.33%,
    #4e0b80 23.48%,
    #430571 39.26%,
    #3d0269 58.54%,
    #3c0167 90.1%
  );
  height: 100%;
  border-radius: 20px;
  padding: 8px;
}

.jackpot-payout-border {
  border: 3px solid rgba(246, 181, 39, 1);
  height: 100%;
  border-radius: 10px;
  padding: 10px;
}

.jackpot-number {
  border: 2.5px solid rgba(246, 181, 39, 1);
  padding: 8px;
}

.jackpot-payout-border > div > h1 {
  font-weight: 700px;
  font-size: 48px;
  color: var(--lightPurpal);
}

.trimorra .container > p {
  font-weight: 500;
  font-size: 20px;
  text-align: center;
  color: var(--lightPurpal);
  padding-top: 20px;
}

.new-trimorra {
  background-color: var(--lightPurpal);
  border-radius: 20px;
}

.new-trimorra > h3 {
  font-weight: 700;
  font-size: 48px;
  text-align: center;
  padding: 5px 0;
  background-color: var(--black);
  color: var(--white);
}

.new-trimorra-tier {
  border: 5px solid var(--mainBlue);
  border-radius: 20px;
  padding: 18px 32px 32px 32px;
  background-color: var(--white);
}

.new-trimorra-buy {
  border: 1px solid var(--mainBlue);
  border-radius: 20px;
  padding: 18px 32px 32px 32px;
  background-color: var(--white);
}

.new-trimorra-buy > h3 {
  font-weight: 700;
  font-size: 48px;
  text-align: center;
}

.new-trimorra-buy > div > button {
  border: 3px solid var(--lightPurpal);
  font-weight: 600;
  font-size: 25.16px;
  padding: 6px 22px;
  text-decoration: underline;
  text-underline-offset: 12px;
  text-decoration-thickness: 5px;
  margin: 5px 2px;
}
.new-trimorra-buy > div > button:hover,
.new-trimorra-buy > div > button:active,
.new-trimorra-buy > div > button:focus {
  background: var(--pink);
  border: 3px solid var(--mainBlue);
}

.tier-jion > p {
  font-weight: 500;
  font-size: 18px;
}

.tier-jion > button {
  padding: 24px 20px 24px 20px;
  font-weight: 600;
  font-size: 21px;
  background: var(--black);
  border: 4px solid var(--gradientBlue);
}

.new-trimorra > div > button {
  width: 100%;
  background: var(--pink);
  font-weight: 600;
  font-size: 24px;
  color: var(--black);
  text-transform: none;
}

.new-trimorra-table {
  background-color: var(--white);
  border-radius: 20px;
  padding: 64px;
}

.new-trimorra-table .trimorra-game-table {
  background-color: var(--lightPurpal);
  border-radius: 50px;
  padding: 0px 16px 16px 16px;
}

.trimorra-game-table > h4 {
  font-weight: 700;
  font-size: 32px;
  color: var(--mainBlue);
  text-align: center;
}

.trimorra-game-table > table {
  width: 100%;
  text-align: center;
  border: 1px solid var(--mainBlue);
  border-radius: 20px;
  background-color: var(--white);
}

.trimorra-game-table > table > thead {
  background-color: var(--mainBlue);
}

.trimorra-game-table > table > thead > th {
  padding: 10px;
  color: var(--white);
  font-weight: 500;
  font-size: 18px;
}

.trimorra-game-table > table > tbody > tr {
  border: 1px solid var(--mainBlue);
}

.trimorra-game-table > table > tbody > tr > td {
  padding: 10px;
}

.trimorra .page-link {
  background: transparent;
  color: var(--black);
  border: 1px solid var(--black);
  border-radius: 5px;
  padding: 8px 16px;
  font-size: 18px;
  line-height: 21.09px;
  font-weight: 400;
  margin: 0 10px;
}

.trimorra .page-link.active,
.trimorra .page-link:hover {
  /* background: linear-gradient(180deg, #4D3ACD 0%, #292734 100%); */
  border-color: var(--mainBlue) !important;
  color: var(--mainBlue) !important;
}

.trimorra-game-table > table > tbody > tr > td {
  padding: 10px;
}

.trimorra-game-table > table > tbody > tr > td {
  font-size: 18px;
  font-weight: 400;
  color: var(--mainBlue);
}

.trimorra-game-table > table > tbody > tr > td:nth-child(1) {
  font-size: 18px;
  font-weight: 400;
  color: var(--black);
}

.trimorra-game-table > table > tbody > tr > td:nth-child(4) {
  font-size: 18px;
  font-weight: 600;
}

.trimorra-game-table > table > tbody > tr > td:nth-child(7) {
  font-size: 18px;
  font-weight: 400;
  color: #6ab72d;
}

.trimorra-modal .modal-header {
  background-color: var(--black);
  text-align: center;
  color: var(--white);
  display: flex;
  justify-content: center;
}

.trimorra-modal .modal-body-tier {
  padding: 64px;
  background-color: var(--lightPurpal);
  border-radius: 10px;
}

.trimorra-modal .modal-body-tier > div > div > h3 {
  font-weight: 600;
  font-size: 30px;
}

.trimorra-modal .modal-body-tier > div > div > p {
  background-color: var(--white);
  border-radius: 20px;
  padding: 12px;
}

.progress-bar {
  width: 100%;
  height: 36px;
  border: 7px solid var(--lightPurpal);
  background-color: var(--white);
  border-radius: 20px;
}

.progress-bar > div {
  background: var(--mainBlue);
  width: 0%;
  height: 36px;
  border-radius: 20px;
  /* animation: progres 2s linear forwards; */
}

@keyframes progres {
  100% {
    width: 100%;
  }
}

.modal-profile > div > img {
  border: 15px solid var(--black);
  border-radius: 50%;
  padding: 5px;
}

.modal-profile > div > img.greyed-out {
  border: 15px solid var(--gray);
  filter: grayscale(1);
}

.modal-profile > div {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px;
}
.modal-profile > div > p {
  font-size: 20px;
  font-weight: 700;
  padding-top: 10px;
}

.modal-profile > div > label {
  font-size: 20px;
  font-weight: 400;
}

.modal-body-tier > div > label {
  font-size: 28px;
  font-weight: 500;
}

.modal-profile > div:disabled {
  background-color: #dddddd;
}

.modal-body-winner {
  background-color: var(--lightPurpal);
  border-radius: 10px;
  padding: 24px;
}

.winner-box {
  display: flex;
  padding: 20px;
}

.winner-box > div:nth-child(1) {
  flex: 25% 1;
  background-color: var(--white);
  align-items: center;
  display: flex;
  justify-content: center;
}

.winner-box > div:nth-child(2) {
  flex: 75% 1;
  background-color: var(--white);
  padding: 20px 30px;
  margin-left: 10px;
}

.winner-box .modal-profile > div > img {
  border: 5px solid var(--mainBlue);
  border-radius: 50%;
}

.winner-box .modal-profile > div > p {
  font-size: 20px;
  font-weight: 700;
  padding-top: 10px;
  color: var(--mainBlue);
}

.winner-box .modal-profile > div > label {
  font-size: 20px;
  font-weight: 400;
  color: var(--mainBlue);
}

.winner-tag > h3 {
  font-weight: 700;
  font-size: 40px;
  color: var(--mainBlue);
}
.winner-tag > h2 {
  background-color: var(--lightPurpal);
  font-weight: 700;
  font-size: 60px;
  padding: 20px;
  border-radius: 20px;
}

.profile-top-card .avtr {
  width: 30px;
  margin-top: -1px;
  border-radius: 100%;
}

.home-section {
  background: url(/static/media/comming-soon.1282ccb3.jpg);
  height: 900px;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
}

.home-section .intro-section {
  display: flex;
  justify-content: end;
  padding-top: 00px;
}

.home-section h1 {
  color: #fff;
  font-weight: 700;
  font-size: 70px;
  line-height: 112.5px;
  margin-top: 20px;
}

.home-section .home-section-sticky {
  position: absolute;
  bottom: 0;
  width: 100%;
}

.home-section .home-section-sticky::before {
  content: "";
  width: 100%;
  height: 100%;
  background: linear-gradient(270deg, #4d3acd 0%, #514f93 100%);
  mix-blend-mode: multiply;
  display: block;
  position: absolute;
}

.home-section .home-section-sticky .left-section {
  background: rgba(241, 239, 255, 1);
  z-index: 99999;
  position: relative;
  box-shadow: 2px 4px 10px 0px rgba(0, 0, 0, 0.1);
  border-radius: 0px 30px 30px 0px;
  margin: 30px 0px;
}

.home-section .home-section-sticky .left-section .windfall-img {
  width: 357px;
  margin: 0 auto;
  display: block;
  position: relative;
  top: -40px;
}

.profile-top-card .unm {
  font-weight: bold;
  color: rgba(77, 58, 205, 1);
}

.page-header-sticky {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  left: 0;
  z-index: 9999999999;
}

.qselect-container * {
  cursor: pointer;
}

/* .modal-backdrop{
    z-index: 99999999999 !important;
} */

.modal {
  z-index: 99999999999 !important;
}

.home-bgf {
  width: 400px;
}

.newsletter .container {
  padding-left: 10%;
  padding-right: 10%;
}

.presale_my_entries {
  border-top: 1px solid #fff;
  padding: 12px;
  display: flex;
  align-items: center;
  justify-content: end;
}

.table-responsive {
  overflow: auto !important;
}

.wrecap {
  background: #fff;
  padding: 32px;
  border-radius: 10px;
  margin-top: 20px;
}

.wrecap .wrecap-section {
  border: 1px solid #181a23;
  padding: 32px;
  border-radius: 10px;
  margin: 8px 0px;
}

.withdrawal-profile {
  background: #fff;
  border: 2px solid #4d3acd;
  padding: 32px;
  border-radius: 20px;
}

.withdrawal-profile-inner-content {
  padding: 16px;
  border-radius: 10px;
  margin: 32px 8px;
  border: 1px solid #504e97;
}

.close-icon {
  cursor: pointer;
}

.form-check-box {
  display: block;
  border: 1px solid rgba(24, 26, 35, 1);
  background: #fff;
  border-radius: 10px;
  padding: 8px;
}

.form-check-box .form-check .form-check-input {
  margin-top: 0px !important;
  cursor: pointer;
}

.form-check-label {
  cursor: pointer;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.amount-picker {
  display: flex;
  align-items: center;
}

.amount-picker label {
  cursor: pointer;
}

.badge-white {
  background: #fff;
  color: #191923;
  border: 2px solid #191923;
  padding: 6px 10px;
  text-align: center;
}

.badge-white:hover {
  background: #191923;
  color: #fff;
  border: 2px solid #191923;
}

/* Footer styles */
.footer {
  padding: 20px 0;
  background: #f0f0f0;
}

.footer .container {
  max-width: 1200px;
  margin: 0 auto;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 20px;
  gap: 20px;
}

.footer-grid {
  display: contents;
}

.footer-column {
  text-align: left;
}

.footer-column h3 {
  font-size: 2.0rem; /* Adjusted font size */
  font-weight: bold;
  margin-bottom: 10px;
}

.footer .links {
  list-style: none;
  padding: 0;
  margin: 0;
}

.footer .links li {
  margin-bottom: 10px;
  display: block; /* Ensure list items stack vertically */
}

.footer .links a {
  font-size: 1.2rem;
  text-decoration: none;
  color: #333;
  display: flex;
  align-items: center;
  background: transparent;
  padding: 10px 15px;
  border-radius: 20px;
  width: 100%; /* Ensure links take full width */
}

.footer .links a img {
  margin-right: 8px;
  width: 30px;  /* Set width for all SVGs */
  height: 30px; /* Set height for all SVGs */
}

/* Additional CSS for Footer middle section */
.footer-sub {
  padding: 10px 0px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.eplus {
  display: flex;
  align-items: center;
  background: rgba(77, 58, 205, 1);
  padding: 8px 16px;
  border-radius: 10px;
  color: white;
  flex: 0 0 65%;
  margin-left: 100px; /* Maintain the left margin */
  margin-right: 20px;
}

.eplus img {
  margin-right: 10px; /* Further reduce margin to move text closer */
  width: 75px; /* Ensure the correct width */
}

.eplus p {
  margin: 0;
  padding-left: 0; /* Ensure no left padding */
  font-weight: 600;
  font-size: 1.35rem;
}

.footer-sub ul {
  display: flex;
  align-items: center;
  padding-left: 0;
  margin: 0;
  list-style: none;
}

.footer-sub li {
  margin: 0 10px;
}

.footer-sub a img {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  transition: transform 0.3s ease-in-out;
}

.footer-sub a img:hover {
  transform: scale(1.1); /* Slightly enlarge the icon on hover */
}

.footer-sub a:not(:last-child) img {
  margin-right: -10px; /* Overlap the icons slightly */
}

/* Additional CSS for Footer bottom section */
.copyright_bar {
  background-color: rgba(77, 58, 205, 1); /* Adjust as needed */
  color: white;
  padding: 10px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.copyright_bar .container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.list-inline {
  padding-left: 0;
  list-style: none;
  margin: 0;
  display: flex;
  align-items: center;
}

.list-inline-item {
  display: inline-block;
  padding: 0 20px; /* Increased padding for more spacing */
  color: white; /* Ensure the font color is white */
}

.list-inline-item a {
  color: white; /* Ensure the link color is white */
  text-decoration: none; /* Optional: remove underline from links */
}

.list-inline-item::before,
.list-inline-item::after {
  content: none; /* Ensure no separators are added */
}

.no_border a {
  border-right: none;
}

.modal-content {
  border-radius: 8px !important;
}

.modal-body {
  padding: 25px !important;
  text-align: left;
}

/* Styles for the dropdown toggle */
.lang-dropdown-toggle {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.lang-dropdown-toggle .toggle-icon img {
  width: 14px;
  height: 8px;
  margin-right: 8px;
  image-rendering: crisp-edges; /* Ensures better image rendering */
}

.lang-dropdown-toggle span {
  font-size: 20px;
  color: #000;
  margin-left: 8px; /* Adjust spacing between text and icon */
}

.lang-dropdown-toggle img {
  width: 30px;
  height: 20px;
  margin-left: 8px; /* Adjust spacing between flag and text */
}

/* Styles for the dropdown menu */
.lang-dropdown-menu {
  background-color: white;
  border-radius: 4px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.lang-dropdown-item {
  display: flex;
  align-items: center;
  padding: 4px 16px; /* Adjust padding to reduce height */
  cursor: pointer;
  transition: background-color 0.3s ease;
  height: 35px; /* Set a fixed height for consistency */
}

.lang-dropdown-item:hover {
  background-color: #f0f0f0;
}

.lang-dropdown-item img {
  margin-right: 2px;
}

.lang-dropdown-item label {
  margin: 0;
  font-size: 1.0rem;
  color: #333;
}

/* Avatar.css */

/* Base class for circular avatars */
.avatar-image {
  border-radius: 50%;
  object-fit: cover;
  object-position: center;  
  overflow: hidden;
}

/* Sizes for avatars */
.avatar-tiny {
  width: 25px;
  height: 25px;
}

.avatar-small {
  width: 40px;
  height: 40px;
}

.avatar-medium {
  width: 60px;
  height: 60px;
}

.avatar-large {
  width: 80px;
  height: 80px;
}

.avatar-XL {
  width: 100px;
  height: 100px;
}

.avatar-XXL {
  width: 120px;
  height: 120px;
}

/* Flexbox layout for left and right sections */
.avatar-left, .avatar-right {
  padding: 16px;
}

.avatar-left {
  display: flex;
  flex-direction: column;
  align-items: center;
  border-right: 1px solid #ddd;
  width: 40%; /* Adjust width as needed */
}

.avatar-right {
  flex: 1 1;
  padding-left: 20px;
}

.avatar-grid {
  display: flex;
  flex-wrap: wrap;
  grid-gap: 10px;
  gap: 10px;
  justify-content: center;
  margin-top: 20px;
}

.avatar-grid img {
  cursor: pointer;
  transition: transform 0.2s ease-in-out;
}

.avatar-grid img:hover {
  transform: scale(1.1);
  border: 2px solid #007bff;
}

/* Buttons and links */
.blue-link-underlined {
  color: #007bff;
  text-decoration: underline;
  cursor: pointer;
  border: none;
  background: none;
  font-size: 16px;
  padding: 0;
}

.avatar-header button {
  margin: 0 4px;
  padding: 8px 16px;
  border: 1px solid #007bff;
  background-color: #fff;
  color: #007bff;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
}

.avatar-header button:hover {
  background-color: #007bff;
  color: #fff;
}

/* Divider */
.divider-primary {
  width: 100%;
  height: 2px;
  background-color: #ddd;
  margin: 16px 0;
}

.animator {
  background: #fff;
  border: 2.23px solid rgba(77, 58, 205, 1);
  border-radius: 15px;
  padding: 4px;
  margin: 0px;

  display: flex; /* Use flexbox layout */
  justify-content: center; /* Center items horizontally */
  align-items: center; /* Center items vertically */
  white-space: nowrap; /* Prevent wrapping */
  overflow: hidden; /* Prevent overflow */
}

.animator li{
  display: inline-block;
  background: linear-gradient(0deg, #F1EFFF, #F1EFFF), linear-gradient(0deg, rgba(255, 255, 255, 0.3), rgba(255, 255, 255, 0.3));
  padding: 10px 0px;
  border-radius: 2.23px;
  border-left: 1.11px solid rgba(255, 255, 255, 0.3);
  box-shadow: 4.458333492279053px 4.458333492279053px 4.458333492279053px -2.2291667461395264px rgba(255, 255, 255, 0.25) inset;
  box-shadow: -4.458333492279053px -4.458333492279053px 11.145833969116211px 0px rgba(0, 0, 0, 0.09) inset;
  font-weight: 500;
  font-size: 50px;
  text-align: center;
  line-height: 50px;
  color: rgba(77, 58, 205, 1);
  margin: 0 0.5px;
  min-width: 55px;
  white-space: nowrap; /* Prevent wrapping */  
}

.animator li:first-child{
  border-radius: 8.92px 2.23px 2.23px 8.92px;
}

.animator li:last-child{
  border-radius: 2.23px 8.92px 8.92px 2.23px;
}

.animator li:non-digit {
  min-width: 31px; /* Adjusted width for non-digit characters */
}
/* Avatar Selection and Category Buttons */

.avatar-selector-wrapper {
  display: grid !important;
  grid-template-columns: 20% 1fr !important; /* Set first column to 20% width and second column to take remaining space */
  align-items: start;
  grid-column-gap: 20px;
  column-gap: 20px; /* Gap between the columns */
}

/* Avatar Selection and Save */

.avatar-save-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 120px; /* Minimum width to accommodate the avatar and button */
}

.avatar-image {
  margin-bottom: 10px;
}

.default-submit-button .save-button {
  height: 30px;
}

/* Category Buttons */
.category-buttons-container {
  display: flex;
  align-items: center;
  grid-gap: 10px;
  gap: 10px;
}

/* Avatar List in Card Content */
.card-content {
  display: flex;
  flex-wrap: wrap;
  grid-gap: 10px;
  gap: 10px; /* Space between avatars */
}

/* Avatar Image Styles */
.avatar-circle {
  transition: transform 0.2s ease-in-out, border 0.2s ease-in-out;
}

.avatar-circle:hover {
  border: 1px solid #000; /* Border on hover */
}

.avatar-circle.selected {
  transform: scale(1.1); /* Scale selected avatar */
  border: 2px solid #000; /* Border for selected avatar */
}
.pwd-field-container {
  display: flex;
  align-items: center;
  margin-bottom: 15px; /* Adjust as needed */
  position: relative;
}

.pwd-input {
  flex: 1 1; /* Allows the input to take all available space */
  padding-right: 10px; /* Adjust padding to avoid text touching the edge */
  height: 40px; /* Adjust height as needed */
  font-size: 1.2rem; /* Adjust font size as needed */
  font-weight: 500;
  border: 1px solid #ccc; /* Standard border for the input field */
  border-radius: 5px; /* Rounded corners for the input field */
}

.pwd-hand {
  margin-left: 10px; /* Space between input and icon */
  cursor: pointer;
  display: flex;
  align-items: center; /* Center the icon vertically */
}

.pwd-hand img {
  width: 20px; /* Adjust icon size as needed */
  height: 20px; /* Ensure the icon is square */
}

.tfa-auth {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: white !important; /* Set background to white */
  border: 2px solid rgb(212, 212, 212) !important;
  padding: 16px;
  border-radius: 10px;
  text-align: center;
}

.tfa-content {
  display: flex;
  align-items: center;
  margin: 10px;
}

.tfa-icon {
  width: 30px; /* Adjust icon size */
  margin-right: 10px; /* Space between icon and inputs */
}

.otp-input {
  width: 40px !important;
  border: 0px !important;
  background: rgb(200, 200, 200) !important;
  border-radius: 5px !important;
  display: inline-block !important;
  color: #000 !important;
  padding: 10px 10px !important;
  text-align: center !important;
  font-weight: 500 !important;
  font-size: 20px !important;
  line-height: 23.44px !important;
  outline: none !important;
  margin: 0 3px; /* Space between input fields */
}

.otp-input:active,
.otp-input:focus,
.otp-input:hover {
  border: 1px solid rgb(69, 69, 69) !important; /* Slightly darker border on focus */
  background: rgb(200, 200, 200);
  outline: none;
}

.tfa-text {
  color: black !important; /* Ensure the label text color is black */
}

/* Status classes */
.tfa-auth-in-prog {
  background-color: #e0f7fa;
}

.tfa-warning {
  background-color: #ffebee;
}

.tfa-success {
  background-color: #e8f5e9;
}

/* Selector.css 
** 
** This CSS is used in selectorCurrency and seletorCryptoAddress
*/

.selector-container {
  border-radius: 10px;
  overflow: hidden;
  background: #fff;
  border: 2px solid rgb(200, 200, 200, 1);
  font-size: 20px;
  font-weight: 700;
}

.selector-expander {
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 16px 36px 16px 36px;
}

.selector-expander .icon {
  margin-left: auto;
}

.selector {
  border: 1px solid rgba(212, 212, 212, 1) !important;
  margin: 0px 36px 8px 36px;
  border-radius: 10px;
  cursor: default;
}

.selector.disabled {
  cursor: not-allowed;
  pointer-events: none;
}

.selector.selected {
  background-color: rgb(215, 215, 180);
  cursor: default; /* Optionally change cursor to default to indicate it's selected */
}

.selector:hover:not(.selected) {
  border: 1px solid black !important;
  background-color: rgb(212, 212, 212, 1) !important;
  cursor: pointer;
}

.selector-item {
  display: flex;
  align-items: center;
  border-radius: 10px;
  margin-right: 8px;
  font-weight: 500;
  font-size: 20px;
  margin-left: auto;
  padding: 5px;
  line-height: 24px;
  background: transparent;
}

.selector-item .icon {
  width: 36px;
  margin-right: 5px;
}

.selector-item-currency {
  text-transform: uppercase;
}

.selector-item-right {
  margin-left: auto;
  white-space: nowrap;
}

.selector-item-address {
  line-height: 12px;
  padding-left: 20px;
}

.selector-address-text {
  font-weight: 700;
  font-size: 18px;
  line-height: 20px;
  justify-content: space-between;
  width: 100%; /* Ensures the span elements span the full width */
}

/* Acccounts.css
**
** Strictly used for our accounting sections
*/

/* Override the min-height to reduce the gap */
.user_account .container {
  min-height: 30vh !important; /* Adjust this value as needed */
}

/* Ensure no extra margins and padding on the ProfileHeader */
.user_account_main {
  margin: 0 !important;
  padding: 0 !important;
  width: 100%;
  height: auto !important; /* Ensure height is set to auto */
}

/* Ensure no extra margins and padding on the section */
.accounts-section {
  padding: 0 !important;
  margin: 0 !important;
}

/* Ensure no extra margins and padding on the container */
.accounts-section .container {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

/* Accounts.tsx - Start */
.accounting-tabs {
  display: flex;
  justify-content: center;
  margin-top: 20px;
  margin-bottom: 20px;
  grid-gap: 20px;
  gap: 20px;
}

.accounting-tabs .tab {
  flex: 1 1;
  display: flex;
  justify-content: center;
}

.accounting-tabs a {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 250px;
  padding: 10px 20px;
  border: 2px solid #3A36DB;
  border-radius: 8px;
  background: ivory;
  text-align: center;
  text-decoration: none;
  color: #3A36DB;
  font-size: 1.25rem;
  font-weight: bold;
  transition: background-color 0.3s, color 0.3s;
  white-space: nowrap;
  height: 50px;
}

.accounting-tabs a:hover {
  background-color: #7371bc;
  color: white !important;
}

.accounting-tabs a.active {
  background: linear-gradient(270deg, #0b0a32 0%, #4d3acd 100%) !important;
  color: white !important;
}
/* Accounts.tsx - End */

/* Deposit.tsx - Start */
.deposit-container {
  padding: 20px;
  background-color: #f8f9fa;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.deposit-container h2 {
  margin-bottom: 20px;
  color: #333;
}

.deposit-selection {
  margin-bottom: 20px;
}

.deposit-selection label {
  margin-right: 10px;
}

.deposit-selection select {
  padding: 8px;
  border-radius: 4px;
  border: 1px solid #ccc;
}

.deposit-form {
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.deposit-form h3 {
  margin-bottom: 20px;
  color: #333;
}
/* Deposit.tsx - End */

/* CryptoDeposit.tsx - Start */

.request-button {
  border-radius: 30px;
  min-width: 250px; /* Minimum width of 250px */
  max-width: 100%; /* Allow it to expand to the width of its container */
  padding: 10px 20px; /* Add padding to ensure it looks good with varying text lengths */
  text-align: center; /* Center the text */
  margin-top: 12px; /* Add horizontal margin to space the buttons apart */
}

.crypto-deposit {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.deposit-info {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  padding: 20px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  margin-top: 20px;
}

.address-section {
  flex: 1 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.currency-name {
  font-size: 1.5em;
  font-weight: bold;
  margin-bottom: 10px;
}

.address {
  font-family: monospace;
  word-break: break-all;
  background-color: #f8f9fa;
  padding: 10px;
  border-radius: 4px;
}

.copy-address {
  cursor: pointer;
  display: flex;
  align-items: center;
  margin-top: 10px;
}

.copy-address img {
  width: 20px;
  height: 20px;
  margin-left: 5px;
}

/* CryptoDeposit.tsx - End */

/* Withdraw.tsx - Start */
.withdraw-container {
  padding: 20px;
  background-color: #f8f9fa;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.withdraw-container h2 {
  margin-bottom: 20px;
  color: #333;
}

.withdraw-selection {
  margin-bottom: 20px;
}

.withdraw-selection label {
  margin-right: 10px;
}

.withdraw-selection select {
  padding: 8px;
  border-radius: 4px;
  border: 1px solid #ccc;
}

.withdraw-form {
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.withdraw-form h3 {
  margin-bottom: 20px;
  color: #333;
}
/* Withdraw.tsx - End */

/* Transaction.tsx - Start */
.pending-input {
  margin-top: 0px;
  border-color: #000;  
  background-color: #fff
}

.pending-input:checked {
  background-color: rgba(255, 240, 0, 1);
}

.pending-input:focus {
  outline: none;
  box-shadow: none;
}

/* Table Header Styling */
.transaction-container .deposit-container .withdraw-container {
  border: 2px solid var(--mainBlue);
  border-radius: 8px;
  background: transparent;
  margin: 12px 5px; /* Fix margin settings */
  overflow: hidden;
  width: 100%; /* Ensure the container takes up the full width */
}

.transaction-table {
  top: -2px;
  border-collapse: collapse; /* Ensure borders collapse into a single line */
  width: 100%;
}

.transaction-table tr {
  height: 35px
}

.transaction-table th {
  background: linear-gradient(to top, #0b0a32 0%, #7568c9 100%) !important;
  /* Avatars.css */
  
  .avatar-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px;
  }
  
  .avatar-header h3 {
    margin: 0;
  }
  
  .avatar-header .labeled-radio {
    margin-left: 8px;
  }
  
  .avatar-header .btn-group {
    display: flex;
    grid-gap: 8px;
    gap: 8px;
  }
  
  .avatar-modal-content {
    display: flex;
    justify-content: space-between;
    padding: 16px;
  }
  
  .avatar-left {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 16px;
    border-right: 1px solid #ddd;
    width: 40%; /* Adjust width as needed */
  }
  
  /* Base class for circular avatars */
  .avatar-image {
    border-radius: 50%;
    object-fit: cover;
    object-position: center;  
    overflow: hidden;
  }
  
  .avatar-image:hover {
    border: 2px solid black;
  }
  
  .avatar-current, .avatar-selected {
    border-radius: 50%;
    object-fit: cover;
    object-position: center;
    overflow: hidden;
  }
  
  .avatar-current {
    width: 50px; /* Size of the current avatar image */
    height: 50px;
    margin-bottom: 10px;
  }
  
  .avatar-selected {
    width: 100px; /* Size of the selected avatar image */
    height: 100px;
    margin-bottom: 10px;
  }
  
  /* Sizes for avatars */
  .avatar-tiny {
    width: 25px;
    height: 25px;
  }
  
  .avatar-small {
    width: 40px;
    height: 40px;
  }
  
  .avatar-medium {
    width: 60px;
    height: 60px;
  
  }
  .avatar-large {
    width: 110px;
    height: 110px;
  }
  
  
  .avatar-xl {
    width: 110px;
    height: 110px;
  }
  
  /* Flexbox layout for left and right sections */
  .avatar-left, .avatar-right {
    padding: 16px;
  }
  
  .avatar-right {
    flex: 1 1;
    padding-left: 20px;
  }
  
  .avatar-grid {
    display: flex;
    flex-wrap: wrap;
    grid-gap: 10px;
    gap: 10px;
    justify-content: center;
    margin-top: 20px;
  }
  
  .avatar-grid img {
    cursor: pointer;
    transition: transform 0.2s ease-in-out;
  }
  
  .avatar-grid img:hover {
    transform: scale(1.1);
    border: 2px solid #007bff;
  }
  
  /* Buttons and links */
  .blue-link-underlined {
    color: #007bff;
    text-decoration: underline;
    cursor: pointer;
    border: none;
    background: none;
    font-size: 16px;
    padding: 0;
  }
  
  .avatar-header button {
    margin: 0 4px;
    padding: 8px 16px;
    border: 1px solid #007bff;
    background-color: #fff;
    color: #007bff;
    cursor: pointer;
    transition: background-color 0.3s, color 0.3s;
  }
  
  .avatar-header button:hover {
    background-color: #007bff;
    color: #fff;
  }
  
  /* Divider */
  .divider-primary {
    width: 100%;
    height: 2px;
    background-color: #ddd;
    margin: 16px 0;
  }
    
  color: #ffffff;
  font-size: 1.15rem;
  text-transform: none; /* Override uppercase transformation */
}

.transaction-table th,
.transaction-table td {
  border: 1px solid #ddd; /* Set a consistent border style */
  padding: 8px; /* Ensure padding is consistent */
  vertical-align: middle; /* Ensure vertical alignment is centered */
  text-transform: none; /* Override uppercase transformation */
}

.transaction-table th:nth-child(1),
.transaction-table td:nth-child(1) { 
  width: 15%; 
  text-align: center;
}

.transaction-table th:nth-child(2),
.transaction-table td:nth-child(2) { 
  width: 10%;  
  text-align: center;
}

.transaction-table th:nth-child(3),
.transaction-table td:nth-child(3) {
  width: 15%; 
  text-align: center;
}

.transaction-table th:nth-child(4),
.transaction-table td:nth-child(4) {
  width: 30%; 
  text-align: left;  
}

.transaction-table th:nth-child(5),
.transaction-table td:nth-child(5) { 
  width: 18%; 
  text-align: right;
}

.transaction-table th:nth-child(6),
.transaction-table td:nth-child(6) { 
  width: 12%; 
  text-align: center;
}

/* Table Row Styling */
.transaction-table td {
  padding: 10px;
  border-bottom: 1px solid #ccc;
  text-align: center;
}

.transaction-table .currency-icon {
  margin-right: 5px;
  width: 25px !important;  
}

/* Hover Effect for Rows */
.transaction-table tr:hover {
  background: transparent;
}

.dot-status-wrapper {
  display: flex;
  align-items: center;
  justify-content: flex-end; /* Align to the right */
}

.dot-status {
  text-align: center;
  display: flex;
  align-items: center;
}

.dot-status .status {
  width: 15px;
  height: 15px;
  display: inline-block;
  border-radius: 100px;
  margin-right: 8px; /* Adjust spacing as needed */
}

.dot-status[data-status="ERROR"] .status {
  background: rgba(255, 0, 0, 1);
}

.dot-status[data-status="COMPLETED"] .status {
  background: rgba(76, 255, 47, 1);
}

.dot-status[data-status="PENDING"] .status {
  background: rgba(255, 240, 0, 1);
}

.dot-status[data-status="CONFIRMING"] .status,
.dot-status[data-status="PROCESSING"] .status {
  background: rgba(77, 58, 205, 1);
}

.status-label {
  font-weight: 400;
  font-size: 14px;
  color: rgba(24, 26, 35, 1);
  line-height: 16.41px;
}

/* Transaction.tsx - End */
/* Define CSS variables for color themes */
:root {
  --pagination-bg-color: #fff; /* Default light theme background color */
  --pagination-text-color: #000; /* Default light theme text color */
  --pagination-hover-bg-color: #a286c6; /* Default light theme hover background color */
  --pagination-hover-text-color: #000; /* Default light theme hover text color */
  --pagination-active-bg-color: transparent; /* Default light theme active background color */  
  --pagination-active-text-color: #000; /* Default light theme active text color */
  --pagination-active-border-color: #290b4f; /* Default light theme active border color */
}

body.dark-theme {
  --pagination-bg-color: #000; /* Dark theme background color */
  --pagination-text-color: #fff; /* Dark theme text color */
  --pagination-hover-bg-color: #333; /* Dark theme hover background color */
  --pagination-hover-text-color: #fff; /* Dark theme hover text color */
  --pagination-active-bg-color: #007bff; /* Dark theme active background color */
  --pagination-active-text-color: #fff; /* Dark theme active text color */
}

/* Pagination Container */
.pagination {
  display: flex;
  justify-content: center;
  padding: 0;
  margin: 0;
  list-style-type: none;
  white-space: nowrap; /* Prevent wrapping */
}

/* Line Item */
.line-item {
  margin: 0 5px;
  padding: 0;
}

/* Rounded Selection */
.rounded-selection {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px 12px;
  background-color: var(--pagination-bg-color); /* Use variable for background color */
  color: var(--pagination-text-color); /* Use variable for text color */
  text-decoration: none;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
  border: none; /* Remove border */
}

.rounded-selection:hover {
  background-color: var(--pagination-hover-bg-color); /* Use variable for hover background color */
  color: var(--pagination-hover-text-color); /* Use variable for hover text color */
  border-radius: 50%; /* Rounded corners on hover */
}

.rounded-selection:focus {
  outline: none; /* Remove default focus outline */
  background-color: var(--pagination-hover-bg-color); /* Use variable for focus background color */
}

.rounded-selection.active {
  border: 4px solid var(--pagination-active-border-color); /* Add border for active item */
  border-radius: 50%; /* Rounded corners for active item */
  background-color: transparent; /* Make background transparent */
  color: var(--pagination-text-color); /* Keep text color the same */
}

/* Page Number Pagination Container */
.page-number-pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
}

.page-number-pagination button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  font-size: 16px;
  margin: 0 5px;
  transition: all 0.3s ease;
}

.page-number-pagination button:hover {
  color: var(--pagination-hover-text-color);
}

.page-number-pagination .active {
  background-color: var(--pagination-active-bg-color);
  color: var(--pagination-active-text-color);
  border-radius: 50%;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.page-number-pagination .dots {
  cursor: default;
}

.page-number-pagination .arrow {
  font-size: 20px;
}

.aggregate-value {
  color: #3a4585;
  font-size: 40px;
  white-space: nowrap; /* Prevent text from wrapping */
}

.confirm-button {
  background-color: #7667d7; /* Purple background for purchase */
  color: white; /* White text */
}

.confirm-button:hover:not(:disabled) {
  background-color: #26237e; /* White background on hover */
}


/* Container Styling */
.presale-list-container {
  border: 1px solid #ccc;
  border-radius: 8px;
  background: transparent;
  margin-top: 2px;
  overflow: hidden;  
}

/* Table Styling */
.presale-list-table {
  width: 100%;
  border-collapse: collapse;
}

/* Table Header Styling */
.presale-list-table th {
  background-color: #333;
  color: #fff;
  padding: 10px;
  border-bottom: 2px solid #ccc;
  text-align: center;
}

.presale-list-table th:nth-child(1) { width: 30%; }
.presale-list-table th:nth-child(2) { width: 30%; }
.presale-list-table th:nth-child(3) { width: 10%; }
.presale-list-table th:nth-child(4) { width: 30%; }

/* Left Align Player Name */
.presale-list-table th.text-start,
.presale-list-table td.text-start {
  text-align: left;
}

/* Table Row Styling */
.presale-list-table td {
  padding: 10px;
  border-bottom: 1px solid #ccc;
  text-align: center;
}

/* Specific Styling for Player Name Column */
.presale-list-table .player-info {
  text-align: left;
  display: flex;
  align-items: center;
}

.presale-list-table .participant-avatar {
  margin-right: 5px;
  width: 25px !important;  
}

/* Hover Effect for Rows */
.presale-list-table tr:hover {
  background: transparent;
}

/* Pagination Styling */
.pagination {
  margin-top: 2px;
  display: flex;
  justify-content: center;
  padding: 10px 0;
  list-style: none; /* Ensure no default list styling */
}

.page-item {
  margin: 0 2px; /* Adjusted margin */
  padding: 0; /* Ensure no extra padding */
}

.page-link {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px 10px; /* Adjust padding for better alignment */
  border: 1px solid #ddd; /* Border for the page link */
  border-radius: 4px; /* Rounded corners */
  background-color: #fff; /* Background color */
  color: #000; /* Text color */
  text-decoration: none; /* Remove underline */
  cursor: pointer; /* Pointer cursor on hover */
}

.page-link img {
  margin: 0 2px; /* Adjust margin for images within buttons */
}

.page-link:hover,
.page-link.active {
  background-color: #007bff; /* Background color on hover and active state */
  color: #fff; /* Text color on hover and active state */
}

.page-link.circle {
  border-radius: 50%;
  width: 12px; /* Adjust size as needed */
  height: 12px; /* Adjust size as needed */
  padding: 0;
  background-color: transparent; /* Remove background for non-active dots */
  border: none; /* Remove border */
  color: transparent; /* Hide the default dot */
  position: relative; /* For positioning the ::before element */
  cursor: pointer; /* Indicate clickable area */
}

.page-link.circle::before {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 8px; /* Adjust size as needed */
  height: 8px; /* Adjust size as needed */
  background-color: #007bff; /* Non-active dot color */
  border-radius: 50%;
  transition: background-color 0.3s;
}

.page-link.circle.active::before {
  background-color: #007bff;
}

.page-link.circle:hover::before {
  background-color: #0056b3;
}

.pagination-header {
  text-align: center;
  margin-bottom: 10px;
}


/* Presale Event Styling */
.presale .presale-event {
  color: #fff;
  text-align: center;
}

.presale .presale-event .hl {
  border: 1px solid rgba(81, 79, 147, 1);
  margin: 32px 0px;
}

.presale .presale-event h1 {
  font-weight: 700;
  font-size: 48px;
  line-height: 56.25px;
  margin: 32px 0px;
}

.presale .presale-event h2 {
  font-weight: 500;
  font-size: 32px;
  line-height: 46px;
  margin: 32px 0px;
}

.presale .qtable {
  margin: 0px;
}

.presale .qtable th,
.presale .qtable td {
  text-transform: initial;
  text-align: center;
}

.presale .qtable thead tr th {
  background: linear-gradient(180deg, #4d3acd 0%, #292734 100%);
}

.presale .table > :not(caption) > * > * {
  background: transparent;
  color: #fff;
  font-size: 18px;
  font-weight: 400;
  line-height: 21.09px;
}

/* Responsive styles */
@media (max-width: 768px) {
  .presale .presale-event h1 {
    font-size: 32px;
    line-height: 40.25px;
    margin: 16px 0px;
  }

  .presale-event .text-start {
    text-align: justify !important;
    margin: 0px 10px !important;
  }

/* Provably Fair Document- Start */

.footnote {
  font-size: 0.60rem;
  vertical-align: super;
  text-decoration: none;
}

.verbatim {
  font-family: monospace;
}

/* Provably Fair Document- End */
}

/* Profile.tsx - Start */

.profile-tabs {
  display: flex;
  justify-content: center;
  margin-top: 20px;
  margin-bottom: 20px;
  grid-gap: 10px;
  gap: 10px;
}

.profile-tabs .tab {
  flex: 1 1;
  display: flex;
  justify-content: center;
}

.profile-tabs a {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 250px;
  padding: 10px 20px;
  border: 2px solid #3A36DB;
  border-radius: 8px;
  background: ivory;
  text-align: center;
  text-decoration: none;
  color: #3A36DB;
  font-size: 1.25rem;
  font-weight: bold;
  transition: background-color 0.3s, color 0.3s;
  white-space: nowrap;
  height: 50px;
}

.profile-tabs a:hover {
  background-color: #7371bc;
  color: white !important;
}

.profile-tabs a.active {
  background: linear-gradient(270deg, #0b0a32 0%, #4d3acd 100%) !important;
  color: white !important;
}
/* Accounts.tsx - End */

/* Trimorra.css */

.trimorra-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
}

@media (max-width: 600px) {
  .trimorra-container {
    padding: 10px;
  }
}

.pagination-container {
  width: 100%;
}

.pagination-header {
  display: flex;
  justify-content: space-between;
  background-color: #333;
  color: #fff;
  padding: 10px;
}

.pagination {
  display: flex;
  justify-content: center;
  padding: 10px;
}

.page-item {
  margin: 0 5px;
}

.page-link {
  cursor: pointer;
}

.player-info {
  display: flex;
  align-items: center;
}

/* Trimorra View Buttons */
.view-container {
  border-radius: 10px;
  padding: 10px 20px; /* Add padding to ensure it looks good with varying text lengths */  
}

.view-button-container {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.view-button {
  border-radius: 30px;
  min-width: 250px; /* Minimum width of 250px */
  max-width: 100%; /* Allow it to expand to the width of its container */
  padding: 10px 20px; /* Add padding to ensure it looks good with varying text lengths */
  text-align: center; /* Center the text */
  margin: 0 10px; /* Add horizontal margin to space the buttons apart */
}

/* TrimorraTierSelection.css */

.button-row {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around; /* Use space-around to distribute buttons */
  width: 100%; /* Ensure row takes full width */
  margin: 0 auto;
}

.button-row .tier-button-container {
  width: 120px; /* Set a fixed width for the buttons */
  margin: 10px; /* Explicit margin for spacing between buttons */
}

/* Tier Button styles */
.tier-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 175px; /* Ensure the width matches the button */
  margin: 10px; /* Adjust margin for spacing between buttons */
  padding-bottom: 5px; /* Adjust space between button and progress bar */
  position: relative;
}

.tier-info {
  display: flex;
  flex-direction: column;
  justify-content: space-between; /* Align items at the top and bottom */
  align-items: flex-end; /* Right-align the items */
  height: 100%;
  width: 100%; /* Ensure it takes full width */
  text-align: right; /* Right-align the text inside */
}

.tier-button {
  display: flex;
  flex-direction: column; /* Stack items vertically */
  align-items: flex-end; /* Align items to the right */
  width: 100%;
  height: 70px; /* Adjust the height as needed */  
  padding: 10px;
  margin: 10px; /* Adjust margin for spacing between buttons */
  padding-bottom: 5px; /* Adjust space between button and progress bar */
  position: relative;
  cursor: pointer;
}

.tier-button-title {
  font-weight: 700;
  font-size: 1.2rem;
  text-align: right; /* Right-align the text */
  margin: 0; /* Remove default margin */
}

.tier-button-label {
  font-size: 0.75rem;
  margin: 0; /* Remove default margin */
  text-align: right; /* Right-align the text */
  white-space: nowrap; /* Prevent wrapping to keep the text on one line */
}

.tier-image {
  position: absolute;
  top: 12px; /* Position it vertically centered */
  left: 10px; /* Adjust as needed */
  width: 45px; /* Adjust size as needed */
  height: 45px; /* Adjust size as needed */

  color: inherit;
  pointer-events: none; /* Allow clicks to pass through */  
}

.section-separator {
  width: 100%;
  border: 3px solid #948989;
  margin: 10px 0;
}

.wager-selection-header {
  margin-top: 10px;
  margin-bottom: 20px;
}

/* TrimorraWagerSelection.css */
.trimorra-base {
  border: 5px solid var(--mainBlue);
  border-radius: 20px;
  padding: 18px 32px 32px 32px;
  background-color: var(--white);
}

.trimorra-selection {
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 3px solid var(--black);
  border-radius: 15px;
  padding: 5px 12px 12px 12px;
  background-color: var(--white);
  position: relative;
  width: 100%;
}

.trimorra-wager-section {
  border: none
}

.trimorra-title > h3 {
  font-weight: 600;
  font-size: 36x;
  text-align: center;
}

.trimorra-buttons {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  grid-gap: 20px;
  gap: 20px; /* Adjust spacing between buttons */
  width: 100%;
}

.tier-participant-image {
  position: absolute;
  top: -4px; /* Adjust as needed */
  right: -4px; /* Adjust as needed */
  width: 24px; /* Adjust size as needed */
  height: 24px; /* Adjust size as needed */
}

.payout-box, .player-list {
  margin-top: 20px;
  width: 100%;
  max-width: 600px;
  text-align: center;
}

.trimorra-wager-payout-container {
  width: 100%;
  margin-top: 20px;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}

/* TrimorraWagerContainer */

.wager-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 150px; /* Ensure the width matches the button */
  margin: 10px; /* Adjust margin for spacing between buttons */
  padding-bottom: 5px; /* Adjust space between button and progress bar */
  position: relative
}

.wager-button {
  width: 100%; /* Ensure the button takes the full width of the container */
  height: 50px; /* Fixed height */
  display: flex;
  align-items: center;
  justify-content: center;
  transition: transform 0.2s, background-color 0.2s;
  font-size: 1.5rem; /* Adjust this value to increase or decrease the text size */
  font-weight: bold; /* Optional: make the text bold */  
}

.progress-bar-container {
  width: 100%; /* Ensure the progress bar takes the full width of the container */
  height: 8px; /* Fixed height */
  background-color: #ddd; /* Background color */
  border-radius: 5px; /* Border radius */
  overflow: hidden; /* Hide overflow */
  margin-top: 5px; /* Adjust margin for spacing between button and progress bar */
}

.progress-bar {
  height: 100%; /* Full height */
  background-color: #76c7c0; /* Progress bar color */
  border: 1px solid #000; /* Thin border for visibility */
}

.wager-participant-image {
  position: absolute;
  top: 8px; /* Adjust as needed */
  right: 6px; /* Adjust as needed */
  width: 20px; /* Adjust size as needed */
  height: 20px; /* Adjust size as needed */
}

/* TrimorraDetailsContainer */

.trimorra-details-container {
  display: flex;
  justify-content: space-between;
  width: 100%; /* Ensure it takes full width */
  background: transparent;
  align-items: flex-start; /* Align items at the start */
}

.trimorra-details-section {
  margin: 0 10px;
  display: flex;
  flex-direction: column;
  align-items: center; /* Center content horizontally */
}

/* Payout Table Section - Start */

.trimorra-payout-container {
  flex: 0 0 40%; /* 35% width */
  padding: 10px;
  padding-bottom: 10px; /* Add extra padding to the bottom */
  margin-top: 20px;
}

.trimorra-payout-container h3 {
  text-align: center;
  margin-bottom: 10px;
  width: 100%; /* Ensure the header takes full width */
}

/* Specific styles for tables */
.trimorra-payout-container table {
  width: 100%;
  border-collapse: collapse;
  min-width: 300px; /* Ensure table has a minimum width */
}

.trimorra-payout-container th,
.trimorra-payout-container td {
  white-space: nowrap; /* Prevent word wrapping */
  padding: 10px;
  border: 1px solid #ddd;
}

.trimorra-payout-container th {
  background-color: #f0f0f0; /* Light grey tone */
}

.trimorra-payout-container tbody tr:nth-child(odd) {
  background-color: #e6f7e6; /* Light green tone */
}

/* Alignment classes with higher specificity */
.trimorra-payout-container .center-align {
  text-align: center;
}

.trimorra-payout-container .right-align {
  text-align: right;
}

/* Highlighting the current round */
.trimorra-payout-container .highlight {
  background-color: var(--mainBlue) !important; /* Ensure the highlight color is applied */
  color: white;
}

/* Payout Table Section - End */

.waiting-list-container {
  flex: 0 0 35%; /* 35% width */
  padding: 10px;
  padding-bottom: 10px;
  margin-top: 20px;
}

/* Trimorra Game Manager Container - Start */
.game-play-manager {
  display: flex;
  flex-direction: column;
  grid-gap: 10px;
  gap: 10px;
}

.game-play-manager-section {
  margin-top: 10px;
  width: 100%;
  /* Additional styles as needed */
}

.game-section-header {
  background: #c0c0e8;
  padding: 6px;
  border-radius: 5px;
  border: 1px solid var(--black);    
  font-size: 24px;
  margin-bottom: 10px;
  text-align: center;
  color: #000
}
/* Trimorra Game Manager Container - End */

/* Trimorra Game Container - Start */
.game-container {
  padding: 10px;
  border: 2px solid var(--black);  
  border-radius: 10px;
  margin-bottom: 0px; /* Adjust this value to reduce spacing */  
}

.game-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.header-left {
  display: flex;
  flex-direction: column;
}

.header-left h3 {
  margin: 0;
}

.header-right {
  display: flex;
  align-items: center; /* Vertically centers the button */
}

.game-header h3 {
  font-size: 1.2rem;
  margin: 0;
}

.game-header p {
  margin: 0;
}

.game-details {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 2px;
  align-items: center; /* Ensure vertical alignment */
}

.game-details-left,
.game-details-right {
  display: flex;
  flex-direction: column;
}

.game-details-left {
  font-size: 1.2rem; 
}

.game-details-left p,
.game-details-right p {
  margin: 0;
  padding: 2px 0;
}

.game-seed {
  margin-top: -5px;
  font-size: 0.9rem;
  font-weight: normal;
}

.game-time {
  font-size: 0.9rem;
  font-weight: normal;
  margin-top: 5px; /* Ensure proper spacing below the tier information */
}

.start-game-button {
  padding: 10px 20px;
  font-size: 1.2rem;
  font-weight: bold;
  cursor: pointer;  
}
/* Trimorra Game Container - End */



/* Game Play Section - Start */

/* Container for the entire game play area */
.game-play {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

/* Layout container for payout table and player avatars */
.layout {
  display: flex;
  width: 100%;
  margin-bottom: 20px;
}

/* Payout table styles */
.payout-table {
  width: 25%; /* Occupies 25% of the container width */
}

/* Container for player avatars */
.players-container {
  display: flex;
  justify-content: space-around;
  width: 75%; /* Occupies 75% of the container width */
}

/* Bottom container for messages, timer, and buttons */
.bottom-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-top: 20px;
  min-height: 50px; /* Add a minimum height to ensure enough space */
}

/* Styles for the play message */
.play-message {
  flex: 1 1;
  text-align: center;
  margin: 0;
}

/* Timer message styles */
.timer-message {
  font-size: 1.2rem;
  margin-right: 10px; /* Add space between timer and button */
}

/* Styles for game play buttons */
button {
  padding: 10px 20px;
  font-size: 1rem;
  cursor: pointer;
}

/* Container for button group */
.button-group {
  display: flex;
  justify-content: flex-end; /* Align items to the start (right) */
  width: 100%;
}

/* Styles for the replay button container */
.replay-button-container {
  display: flex;
  align-items: center;
}

/* Styles for the replay button */
.replay-button {
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
}

/* Styles for the replay image */
.replay-image {
  width: 40px; /* Adjust the width as needed */
  height: auto;
}

/* Styles for the replay text */
.replay-text {
  margin-top: 5px; /* Space between the image and text */
}

/* Styles for the button container (used for both start and finish buttons) */
.button-container {
  display: flex;
  align-items: center;
}

.button-container .button {
  width: auto; /* Ensure the button takes its own width */
  height: 40px; /* Fixed height */
  display: flex;
  align-items: center;
  justify-content: center;
  transition: transform 0.2s, background-color 0.2s;
  font-size: 1.2rem; /* Adjust this value to increase or decrease the text size */
  font-weight: bold; /* Optional: make the text bold */
  margin-right: 25px; /* Ensure there's space between buttons if needed */
}

/* Game Play Section - End */



/* Game Player Section - Start */
.player-avatar {
  width: 110px; /* Set a base width for the avatar */
  height: 110px; /* Set a base height for the avatar */
  transition: transform 0.3s; /* Smooth transition for size change */
  border: 3px solid gold; /* Fancy border */
  position: relative;
}
.player-avatar.winner {
  transform: scale(1.1); /* Scale up the avatar instead of changing its size */
  box-shadow: 0 0 10px 5px var(--mainBlue); /* Glowing effect */
}

/* Adjust the winner container styles */
.player-avatar.winner .avatar-image {
  border: 3px solid gold; /* Outer ring for winner */
}

/* Container for individual player */
.player-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 25%; /* Each player occupies 25% of the container width */
  padding: 10px; /* Add padding for better spacing */
  box-sizing: border-box; /* Include padding and border in the element's total width and height */
}

.player-container p {
  font-size: 1.2rem; /* Increase font size */
  text-align: center;
}

/* Player's Avatar container */
.avatar-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%; /* Ensure it takes up the full width of the parent container */
  height: 125px;
  margin: 8px 0; /* Add margin above and below the avatar */
}

.player-container .roll-label {
  font-weight: bold;
}

.player-container .roll {
  margin-top: 5px; /* Adjust spacing above the roll */
}

.player-container .payout {
  margin-top: 6px;
  font-size: 0.8rem;
}

.payout.winner {
  font-size: 1.2rem !important;
  font-weight: bold;
}

/* Game Player Section - End */

/* Container for the game play */
.game-play {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.trimorra-details-section {
  display: flex;
  justify-content: flex-start; /* Align to the left */
  width: 100%;
  margin-bottom: 20px; /* Space between sections */
}

.players-container {
  display: flex;
  justify-content: space-evenly; /* Distribute space evenly around the players */
  align-items: center;
  width: 100%;
  margin-bottom: 20px; /* Space between players and buttons */
}

.buttons-container {
  display: flex;
  justify-content: space-between; /* Distribute buttons evenly */
  width: 100%;
  padding: 0 10%; /* Add padding to the sides */
}

/* Specific styles for pagination */
.paginations-container {
  width: 100%; /* Ensure pagination takes the full width */
  text-align: center; /* Center pagination conttierrols */
}

/* Content Container */
.trimorra-content-container {
  width: 100%;
  padding: 20px;
}

/* Game History */

.history-list-container {
  border: 2px solid var(--mainBlue);
  border-radius: 8px;
  background: transparent;
  margin: 12px 5px; /* Fix margin settings */
  overflow: hidden;
  width: 100%; /* Ensure the container takes up the full width */
}

/* Table Styling */
.history-list-table {
  width: 100%;
  border-collapse: collapse;
}

/* Table Header Styling */
.history-list-table {
  top: -2px;
  border-collapse: collapse; /* Ensure borders collapse into a single line */
  width: 100%;
}

.history-list-table tr {
  height: 45px
}

.history-list-table th {
  background: linear-gradient(to top, #0b0a32 0%, #7568c9 100%) !important;  
  color: #ffffff;
  text-transform: none; /* Override uppercase transformation */
}

.history-list-table th,
.history-list-table td {
  border: 1px solid #ddd; /* Set a consistent border style */
  padding: 8px; /* Ensure padding is consistent */
  vertical-align: middle; /* Ensure vertical alignment is centered */
  text-transform: none; /* Override uppercase transformation */
  font-size: 1.15rem;
}

.history-list-table th:nth-child(1),
.history-list-table td:nth-child(1) { 
  width: 12%; 
  text-align: center;
}

.history-list-table th:nth-child(2),
.history-list-table td:nth-child(2) { 
  width: 6%;  
  text-align: center;
}

.history-list-table th:nth-child(3) { 
  width: 10%; 
  text-align: center;
}

.history-list-table th:nth-child(4) {
  width: 10%; 
  text-align: right;  
}
.history-list-table td:nth-child(4) { 
  text-align: right;
  color:#582121;    
}

.history-list-table th:nth-child(5),
.history-list-table td:nth-child(5) { 
  width: 20%; 
  text-align: center;
}

.history-list-table th:nth-child(6),
.history-list-table td:nth-child(6) { 
  width: 26%; 
  text-align: left;
}

.history-list-table th:nth-child(7) { 
  width: 13%;
  text-align: right;
}

.history-list-table td:nth-child(7) { 
  width: 13%; 
  text-align: right;
  color:#1fa71f;  
  font-weight: 600 !important;
}

/* Left Align Player Name */
.history-list-table th.text-start,
.history-list-table td.text-start {
  text-align: left;
}

/* Table Row Styling */
.history-list-table td {
  padding: 10px;
  border-bottom: 1px solid #ccc;
  text-align: center;
}

/* Specific Styling for Player Name Column */
.history-list-table .player-info {
  text-align: left;
  display: flex;
  align-items: center;
}

.winner-container {
  margin-right: 5px;
  display: flex;
  align-items: center;  
}

/* Hover Effect for Rows */
.history-list-table tr:hover {
  background: transparent;
}

.history-tier-image {
  width: 30px; /* Adjust size as needed */
  height: 30px; /* Adjust size as needed */
  display: block; /* Ensure the image is treated as a block element */
  margin: 0; /* Ensure no extra margin affects the alignment */
  padding: 0; /* Ensure no extra padding affects the alignment */
}

