@import '../../../assets/css/avatar.css';

/* Trimorra.css */

.trimorra-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
}

@media (max-width: 600px) {
  .trimorra-container {
    padding: 10px;
  }
}

.pagination-container {
  width: 100%;
}

.pagination-header {
  display: flex;
  justify-content: space-between;
  background-color: #333;
  color: #fff;
  padding: 10px;
}

.pagination {
  display: flex;
  justify-content: center;
  padding: 10px;
}

.page-item {
  margin: 0 5px;
}

.page-link {
  cursor: pointer;
}

.player-info {
  display: flex;
  align-items: center;
}

/* Trimorra View Buttons */
.view-container {
  border-radius: 10px;
  padding: 10px 20px; /* Add padding to ensure it looks good with varying text lengths */  
}

.view-button-container {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.view-button {
  border-radius: 30px;
  min-width: 250px; /* Minimum width of 250px */
  max-width: 100%; /* Allow it to expand to the width of its container */
  padding: 10px 20px; /* Add padding to ensure it looks good with varying text lengths */
  text-align: center; /* Center the text */
  margin: 0 10px; /* Add horizontal margin to space the buttons apart */
}

/* TrimorraTierSelection.css */

.button-row {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around; /* Use space-around to distribute buttons */
  width: 100%; /* Ensure row takes full width */
  margin: 0 auto;
}

.button-row .tier-button-container {
  width: 120px; /* Set a fixed width for the buttons */
  margin: 10px; /* Explicit margin for spacing between buttons */
}

/* Tier Button styles */
.tier-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 175px; /* Ensure the width matches the button */
  margin: 10px; /* Adjust margin for spacing between buttons */
  padding-bottom: 5px; /* Adjust space between button and progress bar */
  position: relative;
}

.tier-info {
  display: flex;
  flex-direction: column;
  justify-content: space-between; /* Align items at the top and bottom */
  align-items: flex-end; /* Right-align the items */
  height: 100%;
  width: 100%; /* Ensure it takes full width */
  text-align: right; /* Right-align the text inside */
}

.tier-button {
  display: flex;
  flex-direction: column; /* Stack items vertically */
  align-items: flex-end; /* Align items to the right */
  width: 100%;
  height: 70px; /* Adjust the height as needed */  
  padding: 10px;
  margin: 10px; /* Adjust margin for spacing between buttons */
  padding-bottom: 5px; /* Adjust space between button and progress bar */
  position: relative;
  cursor: pointer;
}

.tier-button-title {
  font-weight: 700;
  font-size: 1.2rem;
  text-align: right; /* Right-align the text */
  margin: 0; /* Remove default margin */
}

.tier-button-label {
  font-size: 0.75rem;
  margin: 0; /* Remove default margin */
  text-align: right; /* Right-align the text */
  white-space: nowrap; /* Prevent wrapping to keep the text on one line */
}

.tier-image {
  position: absolute;
  top: 12px; /* Position it vertically centered */
  left: 10px; /* Adjust as needed */
  width: 45px; /* Adjust size as needed */
  height: 45px; /* Adjust size as needed */

  color: inherit;
  pointer-events: none; /* Allow clicks to pass through */  
}

.section-separator {
  width: 100%;
  border: 3px solid #948989;
  margin: 10px 0;
}

.wager-selection-header {
  margin-top: 10px;
  margin-bottom: 20px;
}

/* TrimorraWagerSelection.css */
.trimorra-base {
  border: 5px solid var(--mainBlue);
  border-radius: 20px;
  padding: 18px 32px 32px 32px;
  background-color: var(--white);
}

.trimorra-selection {
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 3px solid var(--black);
  border-radius: 15px;
  padding: 5px 12px 12px 12px;
  background-color: var(--white);
  position: relative;
  width: 100%;
}

.trimorra-wager-section {
  border: none
}

.trimorra-title > h3 {
  font-weight: 600;
  font-size: 36x;
  text-align: center;
}

.trimorra-buttons {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px; /* Adjust spacing between buttons */
  width: 100%;
}

.tier-participant-image {
  position: absolute;
  top: -4px; /* Adjust as needed */
  right: -4px; /* Adjust as needed */
  width: 24px; /* Adjust size as needed */
  height: 24px; /* Adjust size as needed */
}

.payout-box, .player-list {
  margin-top: 20px;
  width: 100%;
  max-width: 600px;
  text-align: center;
}

.trimorra-wager-payout-container {
  width: 100%;
  margin-top: 20px;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}

/* TrimorraWagerContainer */

.wager-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 150px; /* Ensure the width matches the button */
  margin: 10px; /* Adjust margin for spacing between buttons */
  padding-bottom: 5px; /* Adjust space between button and progress bar */
  position: relative
}

.wager-button {
  width: 100%; /* Ensure the button takes the full width of the container */
  height: 50px; /* Fixed height */
  display: flex;
  align-items: center;
  justify-content: center;
  transition: transform 0.2s, background-color 0.2s;
  font-size: 1.5rem; /* Adjust this value to increase or decrease the text size */
  font-weight: bold; /* Optional: make the text bold */  
}

.progress-bar-container {
  width: 100%; /* Ensure the progress bar takes the full width of the container */
  height: 8px; /* Fixed height */
  background-color: #ddd; /* Background color */
  border-radius: 5px; /* Border radius */
  overflow: hidden; /* Hide overflow */
  margin-top: 5px; /* Adjust margin for spacing between button and progress bar */
}

.progress-bar {
  height: 100%; /* Full height */
  background-color: #76c7c0; /* Progress bar color */
  border: 1px solid #000; /* Thin border for visibility */
}

.wager-participant-image {
  position: absolute;
  top: 8px; /* Adjust as needed */
  right: 6px; /* Adjust as needed */
  width: 20px; /* Adjust size as needed */
  height: 20px; /* Adjust size as needed */
}

/* TrimorraDetailsContainer */

.trimorra-details-container {
  display: flex;
  justify-content: space-between;
  width: 100%; /* Ensure it takes full width */
  background: transparent;
  align-items: flex-start; /* Align items at the start */
}

.trimorra-details-section {
  margin: 0 10px;
  display: flex;
  flex-direction: column;
  align-items: center; /* Center content horizontally */
}

/* Payout Table Section - Start */

.trimorra-payout-container {
  flex: 0 0 40%; /* 35% width */
  padding: 10px;
  padding-bottom: 10px; /* Add extra padding to the bottom */
  margin-top: 20px;
}

.trimorra-payout-container h3 {
  text-align: center;
  margin-bottom: 10px;
  width: 100%; /* Ensure the header takes full width */
}

/* Specific styles for tables */
.trimorra-payout-container table {
  width: 100%;
  border-collapse: collapse;
  min-width: 300px; /* Ensure table has a minimum width */
}

.trimorra-payout-container th,
.trimorra-payout-container td {
  white-space: nowrap; /* Prevent word wrapping */
  padding: 10px;
  border: 1px solid #ddd;
}

.trimorra-payout-container th {
  background-color: #f0f0f0; /* Light grey tone */
}

.trimorra-payout-container tbody tr:nth-child(odd) {
  background-color: #e6f7e6; /* Light green tone */
}

/* Alignment classes with higher specificity */
.trimorra-payout-container .center-align {
  text-align: center;
}

.trimorra-payout-container .right-align {
  text-align: right;
}

/* Highlighting the current round */
.trimorra-payout-container .highlight {
  background-color: var(--mainBlue) !important; /* Ensure the highlight color is applied */
  color: white;
}

/* Payout Table Section - End */

.waiting-list-container {
  flex: 0 0 35%; /* 35% width */
  padding: 10px;
  padding-bottom: 10px;
  margin-top: 20px;
}

/* Trimorra Game Manager Container - Start */
.game-play-manager {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.game-play-manager-section {
  margin-top: 10px;
  width: 100%;
  /* Additional styles as needed */
}

.game-section-header {
  background: #c0c0e8;
  padding: 6px;
  border-radius: 5px;
  border: 1px solid var(--black);    
  font-size: 24px;
  margin-bottom: 10px;
  text-align: center;
  color: #000
}
/* Trimorra Game Manager Container - End */

/* Trimorra Game Container - Start */
.game-container {
  padding: 10px;
  border: 2px solid var(--black);  
  border-radius: 10px;
  margin-bottom: 0px; /* Adjust this value to reduce spacing */  
}

.game-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.header-left {
  display: flex;
  flex-direction: column;
}

.header-left h3 {
  margin: 0;
}

.header-right {
  display: flex;
  align-items: center; /* Vertically centers the button */
}

.game-header h3 {
  font-size: 1.2rem;
  margin: 0;
}

.game-header p {
  margin: 0;
}

.game-details {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 2px;
  align-items: center; /* Ensure vertical alignment */
}

.game-details-left,
.game-details-right {
  display: flex;
  flex-direction: column;
}

.game-details-left {
  font-size: 1.2rem; 
}

.game-details-left p,
.game-details-right p {
  margin: 0;
  padding: 2px 0;
}

.game-seed {
  margin-top: -5px;
  font-size: 0.9rem;
  font-weight: normal;
}

.game-time {
  font-size: 0.9rem;
  font-weight: normal;
  margin-top: 5px; /* Ensure proper spacing below the tier information */
}

.start-game-button {
  padding: 10px 20px;
  font-size: 1.2rem;
  font-weight: bold;
  cursor: pointer;  
}
/* Trimorra Game Container - End */



/* Game Play Section - Start */

/* Container for the entire game play area */
.game-play {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

/* Layout container for payout table and player avatars */
.layout {
  display: flex;
  width: 100%;
  margin-bottom: 20px;
}

/* Payout table styles */
.payout-table {
  width: 25%; /* Occupies 25% of the container width */
}

/* Container for player avatars */
.players-container {
  display: flex;
  justify-content: space-around;
  width: 75%; /* Occupies 75% of the container width */
}

/* Bottom container for messages, timer, and buttons */
.bottom-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-top: 20px;
  min-height: 50px; /* Add a minimum height to ensure enough space */
}

/* Styles for the play message */
.play-message {
  flex: 1;
  text-align: center;
  margin: 0;
}

/* Timer message styles */
.timer-message {
  font-size: 1.2rem;
  margin-right: 10px; /* Add space between timer and button */
}

/* Styles for game play buttons */
button {
  padding: 10px 20px;
  font-size: 1rem;
  cursor: pointer;
}

/* Container for button group */
.button-group {
  display: flex;
  justify-content: flex-end; /* Align items to the start (right) */
  width: 100%;
}

/* Styles for the replay button container */
.replay-button-container {
  display: flex;
  align-items: center;
}

/* Styles for the replay button */
.replay-button {
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
}

/* Styles for the replay image */
.replay-image {
  width: 40px; /* Adjust the width as needed */
  height: auto;
}

/* Styles for the replay text */
.replay-text {
  margin-top: 5px; /* Space between the image and text */
}

/* Styles for the button container (used for both start and finish buttons) */
.button-container {
  display: flex;
  align-items: center;
}

.button-container .button {
  width: auto; /* Ensure the button takes its own width */
  height: 40px; /* Fixed height */
  display: flex;
  align-items: center;
  justify-content: center;
  transition: transform 0.2s, background-color 0.2s;
  font-size: 1.2rem; /* Adjust this value to increase or decrease the text size */
  font-weight: bold; /* Optional: make the text bold */
  margin-right: 25px; /* Ensure there's space between buttons if needed */
}

/* Game Play Section - End */



/* Game Player Section - Start */
.player-avatar {
  width: 110px; /* Set a base width for the avatar */
  height: 110px; /* Set a base height for the avatar */
  transition: transform 0.3s; /* Smooth transition for size change */
  border: 3px solid gold; /* Fancy border */
  position: relative;
}
.player-avatar.winner {
  transform: scale(1.1); /* Scale up the avatar instead of changing its size */
  box-shadow: 0 0 10px 5px var(--mainBlue); /* Glowing effect */
}

/* Adjust the winner container styles */
.player-avatar.winner .avatar-image {
  border: 3px solid gold; /* Outer ring for winner */
}

/* Container for individual player */
.player-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 25%; /* Each player occupies 25% of the container width */
  padding: 10px; /* Add padding for better spacing */
  box-sizing: border-box; /* Include padding and border in the element's total width and height */
}

.player-container p {
  font-size: 1.2rem; /* Increase font size */
  text-align: center;
}

/* Player's Avatar container */
.avatar-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%; /* Ensure it takes up the full width of the parent container */
  height: 125px;
  margin: 8px 0; /* Add margin above and below the avatar */
}

.player-container .roll-label {
  font-weight: bold;
}

.player-container .roll {
  margin-top: 5px; /* Adjust spacing above the roll */
}

.player-container .payout {
  margin-top: 6px;
  font-size: 0.8rem;
}

.payout.winner {
  font-size: 1.2rem !important;
  font-weight: bold;
}

/* Game Player Section - End */

/* Container for the game play */
.game-play {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.trimorra-details-section {
  display: flex;
  justify-content: flex-start; /* Align to the left */
  width: 100%;
  margin-bottom: 20px; /* Space between sections */
}

.players-container {
  display: flex;
  justify-content: space-evenly; /* Distribute space evenly around the players */
  align-items: center;
  width: 100%;
  margin-bottom: 20px; /* Space between players and buttons */
}

.buttons-container {
  display: flex;
  justify-content: space-between; /* Distribute buttons evenly */
  width: 100%;
  padding: 0 10%; /* Add padding to the sides */
}

/* Specific styles for pagination */
.paginations-container {
  width: 100%; /* Ensure pagination takes the full width */
  text-align: center; /* Center pagination conttierrols */
}

/* Content Container */
.trimorra-content-container {
  width: 100%;
  padding: 20px;
}

/* Game History */

.history-list-container {
  border: 2px solid var(--mainBlue);
  border-radius: 8px;
  background: transparent;
  margin: 12px 5px; /* Fix margin settings */
  overflow: hidden;
  width: 100%; /* Ensure the container takes up the full width */
}

/* Table Styling */
.history-list-table {
  width: 100%;
  border-collapse: collapse;
}

/* Table Header Styling */
.history-list-table {
  top: -2px;
  border-collapse: collapse; /* Ensure borders collapse into a single line */
  width: 100%;
}

.history-list-table tr {
  height: 45px
}

.history-list-table th {
  background: linear-gradient(to top, #0b0a32 0%, #7568c9 100%) !important;  
  color: #ffffff;
  text-transform: none; /* Override uppercase transformation */
}

.history-list-table th,
.history-list-table td {
  border: 1px solid #ddd; /* Set a consistent border style */
  padding: 8px; /* Ensure padding is consistent */
  vertical-align: middle; /* Ensure vertical alignment is centered */
  text-transform: none; /* Override uppercase transformation */
  font-size: 1.15rem;
}

.history-list-table th:nth-child(1),
.history-list-table td:nth-child(1) { 
  width: 12%; 
  text-align: center;
}

.history-list-table th:nth-child(2),
.history-list-table td:nth-child(2) { 
  width: 6%;  
  text-align: center;
}

.history-list-table th:nth-child(3) { 
  width: 10%; 
  text-align: center;
}

.history-list-table th:nth-child(4) {
  width: 10%; 
  text-align: right;  
}
.history-list-table td:nth-child(4) { 
  text-align: right;
  color:#582121;    
}

.history-list-table th:nth-child(5),
.history-list-table td:nth-child(5) { 
  width: 20%; 
  text-align: center;
}

.history-list-table th:nth-child(6),
.history-list-table td:nth-child(6) { 
  width: 26%; 
  text-align: left;
}

.history-list-table th:nth-child(7) { 
  width: 13%;
  text-align: right;
}

.history-list-table td:nth-child(7) { 
  width: 13%; 
  text-align: right;
  color:#1fa71f;  
  font-weight: 600 !important;
}

/* Left Align Player Name */
.history-list-table th.text-start,
.history-list-table td.text-start {
  text-align: left;
}

/* Table Row Styling */
.history-list-table td {
  padding: 10px;
  border-bottom: 1px solid #ccc;
  text-align: center;
}

/* Specific Styling for Player Name Column */
.history-list-table .player-info {
  text-align: left;
  display: flex;
  align-items: center;
}

.winner-container {
  margin-right: 5px;
  display: flex;
  align-items: center;  
}

/* Hover Effect for Rows */
.history-list-table tr:hover {
  background: transparent;
}

.history-tier-image {
  width: 30px; /* Adjust size as needed */
  height: 30px; /* Adjust size as needed */
  display: block; /* Ensure the image is treated as a block element */
  margin: 0; /* Ensure no extra margin affects the alignment */
  padding: 0; /* Ensure no extra padding affects the alignment */
}
