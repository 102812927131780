.tfa-auth {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: white !important; /* Set background to white */
  border: 2px solid rgb(212, 212, 212) !important;
  padding: 16px;
  border-radius: 10px;
  text-align: center;
}

.tfa-content {
  display: flex;
  align-items: center;
  margin: 10px;
}

.tfa-icon {
  width: 30px; /* Adjust icon size */
  margin-right: 10px; /* Space between icon and inputs */
}

.otp-input {
  width: 40px !important;
  border: 0px !important;
  background: rgb(200, 200, 200) !important;
  border-radius: 5px !important;
  display: inline-block !important;
  color: #000 !important;
  padding: 10px 10px !important;
  text-align: center !important;
  font-weight: 500 !important;
  font-size: 20px !important;
  line-height: 23.44px !important;
  outline: none !important;
  margin: 0 3px; /* Space between input fields */
}

.otp-input:active,
.otp-input:focus,
.otp-input:hover {
  border: 1px solid rgb(69, 69, 69) !important; /* Slightly darker border on focus */
  background: rgb(200, 200, 200);
  outline: none;
}

.tfa-text {
  color: black !important; /* Ensure the label text color is black */
}

/* Status classes */
.tfa-auth-in-prog {
  background-color: #e0f7fa;
}

.tfa-warning {
  background-color: #ffebee;
}

.tfa-success {
  background-color: #e8f5e9;
}
